const cards = {
  profissoes: [
    "alfaiate.jpg",
    "arauto.jpg",
    "besteiro.jpg",
    "boticario.jpg",
    "curtidor.jpg",
    "escriba.jpg",
    "estalajadeiro.jpg",
    "falcoeiro.jpg",
    "forjador.jpg",
    "jogral.jpg",
    "mocodegleba.jpg",
    "musico.jpg",
    "oleiro.jpg",
    "ourives.jpg",
    "pagem.jpg",
    "peregrino.jpg",
    "pescador.jpg",
    "saltimbanco.jpg",
    "soldado.jpg",
    "taberneiro.jpg",
  ],
  jogoPopular: [
    "StAnt264.jpg",
    "StAnt266.jpg",
    "StAnt267.jpg",
    "StAnt268.jpg",
    "StAnt270.jpg",
    "StAnt272.jpg",
    "StAnt275.jpg",
    "StAnt279.jpg",
    "StAnt280.jpg",
    "StAnt281.jpg",
    "StAnt283.jpg",
    "StAnt285.jpg",
    "StAnt295.jpg",
    "StAnt306.jpg",
    "StAnt307.jpg",
    "StAnt308.jpg",
  ],
  procissao: [
    "MLSA.CER.0453.001.JPG",
    "MLSA.CER.0453.012.JPG",
    "MLSA.CER.0453.036D.jpg",
    "MLSA.CER.0453.042.jpg",
    "MLSA.CER.0453.102.jpg",
    "MLSA.CER.0453.125.jpg",
    "MLSA.CER.0453.169.jpg",
    "MLSA.CER.0453.176.JPG",
    "MLSA.CER.0453.198.jpg",
    "MLSA.CER.0453.216.jpg",
    "MLSA.CER.0453.236D.jpg",
    "MLSA.CER.0453.257.jpg",
    "MLSA.CER.0453.268.jpg",
    "MLSA.CER.0453.284.jpg",
    "MLSA.CER.0453.309.jpg",
    "MLSA.CER.0453.328.jpg",
  ],
  valhaSantoAntonio: [
    "ana_franco.jpg",
    "carlos_dias.jpg",
    "celia_miguel_gomes.jpg",
    "delfina_cabral.jpg",
    "ercilio_natalio.jpg",
    "flor_fernandes.jpg",
    "guiomar_noronha.jpg",
    "irene_salgueiro.jpg",
    "jesus_pias.jpg",
    "joaquim_messias.jpg",
    "lena_ze_riscado.jpg",
    "paula_guedes.jpg",
    "prazeres_cota.jpg",
    "sacha_kuul.jpg",
    "sandra_duarte.jpg",
    "teresa_brito.jpg",
  ],
  profissoesQuinhentistas: [
    "aguadeira.jpg",
    "biscoteiro.jpg",
    "caiador.jpg",
    "cartografo.jpg",
    "cordoeiro.jpg",
    "costureira.jpg",
    "enxerqueira.jpg",
    "estivador.jpg",
    "guadamecileiro.jpg",
    "livreiro.jpg",
    "medico.jpg",
    "padeira.jpg",
    "pescadeira.jpg",
    "regateira.jpg",
    "tendeiro.jpg",
    "tintureiro.jpg",
  ]
};

export default cards;
