import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import Home from "../Containers/Home";
import Timeline from "../Containers/Timeline";
import DraggableWall from "../Containers/Wall";
import DraggableTronos from "../Containers/Tronos";
import MemoryGame from "../Containers/MemoryGame";

const Main = (props) => {
  const location = props.location;
  return (
    <div className="mainOuterWrapper">
      <Switch>
        <Route exact path="/" render={Home} />
        <Route exact path="/timeline-vida" render={Timeline} />
        <Route exact path="/timeline-culto" render={Timeline} />
        <Route exact path="/exposicao" render={DraggableWall} />
        <Route exact path="/exposicao-tronos" render={DraggableTronos} />
        <Route exact path="/memory-game" render={MemoryGame} />
      </Switch>
    </div>
  );
};

export default withRouter(Main);
