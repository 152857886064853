import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import deepcopy from "deepcopy";
import Card from "../Components/MemoryGameCard";
import cardImages from "../data/memoryGame.js";
import "./memoryGame.css";
import StopWatch from "../Components/StopWatch";

const CardGame = () => {
  const [gamePrep, setGamePrep] = React.useState(false);
  const [prevLevel, setPrevLevel] = React.useState();
  const [level, setLevel] = React.useState(Number(16));
  const [prevCollection, setPrevCollection] = React.useState();
  const [collection, setCollection] = React.useState("profissoes");
  const [cards, setCards] = React.useState(generateCards(level, collection));
  const [canFlip, setCanFlip] = React.useState(false);
  const [firstCard, setFirstCard] = React.useState(null);
  const [secondCard, setSecondCard] = React.useState(null);
  const [correctCards, setCorrectCards] = React.useState(Number());
  const [successInfoDisplay, setSuccessInfoDisplay] = React.useState(false);
  // StopWatch
  const [isRunning, setIsRunning] = React.useState(false);
  const [minutes, setMinutes] = React.useState(Number(0));
  const [seconds, setSeconds] = React.useState(Number(0));

  function shuffleArray(array) {
    return array.sort(() => 0.5 - Math.random());
  }

  function generateCards(level, collection) {
    if (level % 2 !== 0)
      throw "Count must be even: 2, 4, 6, etc. but it is " + level;

    let displayCollection;
    switch (collection) {
      case "profissoesQuinhentistas":
        displayCollection = cardImages.profissoesQuinhentistas;
        break;
      case "valhaSantoAntonio":
        displayCollection = cardImages.valhaSantoAntonio;
        break;
      case "procissao":
        displayCollection = cardImages.procissao;
        break;
      case "jogoPopular":
        displayCollection = cardImages.jogoPopular;
        break;
      default:
        displayCollection = cardImages.profissoes;
        break;
    }
    let cards = shuffleArray(displayCollection)
      .slice(0, level / 2)
      .map((imageURL) => ({
        id: uuidv4(),
        imageURL: "/assets/memoryGameImgs/" + collection + "/" + imageURL,
        isFlipped: false,
        canFlip: true,
      }))
      .flatMap((e) => [e, { ...deepcopy(e), id: uuidv4() }]);

    return shuffleArray(cards);
  }

  function setCardIsFlipped(cardID, isFlipped) {
    setCards((prev) =>
      prev.map((c) => {
        if (c.id !== cardID) return c;
        return { ...c, isFlipped };
      })
    );
  }
  function setCardCanFlip(cardID, canFlip) {
    setCards((prev) =>
      prev.map((c) => {
        if (c.id !== cardID) return c;
        return { ...c, canFlip };
      })
    );
  }

  function showcase() {
    setPrevLevel(level);
    setPrevCollection(collection);
    setTimeout(() => {
      let index = 0;
      for (const card of cards) {
        setTimeout(() => setCardIsFlipped(card.id, true), index++ * 100);
      }
      setTimeout(() => setCanFlip(true), cards.length * 100);
      setTimeout(() => {
        StopWatchToggle();
        console.log(cards.length);
      }, cards.length * 100);
    }, 1000);
    setSuccessInfoDisplay(true);
    setGamePrep(true);
  }

  // showcase
  React.useEffect(() => {
    if (!gamePrep) {
      showcase();
    }
  });

  function resetFirstAndSecondCards() {
    setFirstCard(null);
    setSecondCard(null);
  }

  function onSuccessGuess() {
    setCardCanFlip(firstCard.id, false);
    setCardCanFlip(secondCard.id, false);
    setCardIsFlipped(firstCard.id, false);
    setCardIsFlipped(secondCard.id, false);
    setCorrectCards(correctCards + 2);
    resetFirstAndSecondCards();
  }
  function onFailureGuess() {
    const firstCardID = firstCard.id;
    const secondCardID = secondCard.id;

    setTimeout(() => {
      setCardIsFlipped(firstCardID, true);
    }, 1000);
    setTimeout(() => {
      setCardIsFlipped(secondCardID, true);
    }, 1200);

    resetFirstAndSecondCards();
  }

  React.useEffect(() => {
    if (!firstCard || !secondCard) return;
    firstCard.imageURL === secondCard.imageURL
      ? onSuccessGuess()
      : onFailureGuess();
  }, [firstCard, secondCard]);

  function onCardClick(card) {
    if (!canFlip) return;
    if (!card.canFlip) return;

    if (
      (firstCard && card.id === firstCard.id) ||
      (secondCard && card.id === secondCard.id)
    )
      return;

    setCardIsFlipped(card.id, false);

    firstCard ? setSecondCard(card) : setFirstCard(card);
  }

  function onChange(e) {
    setLevel(e.target.value);
    setCards(generateCards(e.target.value, collection));
    StopWatchReset();
    setGamePrep(false);
  }

  function changeCollection(e) {
    setCollection(e.target.value);
    setCards(generateCards(level, e.target.value));
    StopWatchReset();
    setGamePrep(false);
  }

  function handleRestart() {
    setCorrectCards(null);
    setCards(generateCards(level, collection));
    setSuccessInfoDisplay(true);
    StopWatchReset();
    setGamePrep(false);
  }

  function handleCloseSuccess() {
    setCorrectCards(null);
    setSuccessInfoDisplay(!successInfoDisplay);
  }

  //StopWatch
  function StopWatchToggle() {
    setIsRunning(!isRunning);
  }

  function StopWatchStop() {
    setIsRunning(false);
  }

  function StopWatchReset() {
    setSeconds(0);
    setMinutes(0);
    setIsRunning(false);
  }

  React.useEffect(() => {
    let interval = null;
    if (correctCards != level) {
      if (isRunning) {
        interval = setInterval(() => {
          if (seconds >= 60) {
            setMinutes(minutes + 1);
            setSeconds(0);
          }
          setSeconds((seconds) => seconds + 1);
        }, 1000);
      } else if (!isRunning && seconds !== 0) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    } else {
      StopWatchStop();
      clearInterval(interval);
    }
  }, [isRunning, seconds]);

  return (
    <div className="gameWrapper">
      <div className="gameNav">
        <StopWatch minutes={minutes} seconds={seconds} />
        <button className="playAgain" onClick={handleRestart}>
          Recomeçar
        </button>
        <div className="selectors">
          <div className="gameSelect">
            <label htmlFor="collection">Temática: </label>
            <select
              name="collection"
              id="collection"
              value={collection}
              onChange={changeCollection}
            >
              <option value="profissoes">Profissões Medievais</option>
              <option value="profissoesQuinhentistas">Profissões Quinhentistas</option>
              <option value="procissao">Procissão de Sto António</option>
              <option value="jogoPopular">Santo António Popular</option>
              <option value="valhaSantoAntonio">Valha-nos Santo António!</option>
            </select>
          </div>
          <div className="gameSelect">
            <label htmlFor="level">Dificuldade: </label>
            <select name="level" id="level" value={level} onChange={onChange}>
              <option value="8">Fácil</option>
              <option value="16">Normal</option>
              <option value="24">Difícil</option>
              <option value="32">Muito Difícil</option>
            </select>
          </div>
        </div>
      </div>
      <div className={`cardsWrapper level-${level}`}>
        {cards.map((card) => (
          <Card onClick={() => onCardClick(card)} key={card.id} {...card} collection={collection} />
        ))}
      </div>
      <div
        className={
          correctCards == level && successInfoDisplay
            ? "successInfo"
            : "successInfo hidden"
        }
      >
        <h2>Parabéns!</h2>
        <h6>
          {minutes} minutos e {seconds} segundos para descobrires todos os
          pares.
        </h6>
        <button className="playAgain" onClick={handleRestart}>
          Jogar outra vez?
        </button>
        <button className="close" onClick={handleCloseSuccess}>
          X
        </button>
      </div>
    </div>
  );
};

export default withRouter(CardGame);
