import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import "./memoryGameCard.css";
import MSAlogoV from "../assets/logo/msa_v.js";

const Card = ({ imageURL, isFlipped, onClick, collection }) => {
  return (
    <div className="card-container" onClick={onClick}>
      <div className={"card" + (isFlipped ? " flipped" : "")}>
        <img className="side front" src={imageURL} />
        {collection === "valhaSantoAntonio" ?
          <div className="side back msaV_white valhaStoAntonio">
          </div>
          :
          <div className="side back msaV_white">
            <MSAlogoV />
          </div>
        }
      </div>
    </div>
  );
};

export default withRouter(Card);
