const tronosData = {
  participants: {
    name: "Participantes",
    items: [
      {
        inscription: "1",
        title: "Culto",
        author: "Vanessa Bastos",
        location: "Seixal",
        info:
          "Recortes, fita-cola, livros, jarro a fazer de suporte, uma cadeira de decoração, tela pintada, velas.",
        img: "/assets/tronosImgs/participantes/001.jpg",
      },
      {
        inscription: "2",
        title: "Santo António e as rãs - homenagem a Padre António Vieira",
        author: "Rita Barros",
        location: "Lisboa",
        info:
          "A inspiração foi o sermão de Santo Antonio aos peixes pelo Padre António Vieira. Os materiais são pedra e plástico colorido.",
        img: "/assets/tronosImgs/participantes/002.jpg",
      },
      {
        inscription: "3",
        title: "Santo António de Lisboa",
        author: "João Matos",
        location: "Lisboa",
        info:
          "Feito com papel, cartão e plástico reciclado, este trono inspira-se no Fado, nos azulejos e em Amália. ",
        img: "/assets/tronosImgs/participantes/003.jpg",
      },
      {
        inscription: "4",
        title: "Santo António, és o que nós quisermos!...",
        author: "António Casal (pai) e Manuel Casal (filho)",
        location: "Lisboa",
        info:
          "Papelão e flores do campo. A inspiração baseia-se em recortar um perfil de Santo António e colocá-lo onde nós quisermos, de forma que o perfil do Santo António se transforma no que fica lá por trás. O céu, as nuvens, o mar, o jardim, as flores, a cidade, os amigos.",
        img: "/assets/tronosImgs/participantes/004.jpg",
      },
      {
        inscription: "5",
        title: "LEGO Trono",
        author: "Leonardo Cintrão Machado (8 anos)",
        location: "Amadora",
        info: "Trono de Santo António feito com Lego.",
        img: "/assets/tronosImgs/participantes/005.jpg",
      },
      {
        inscription: "6",
        title:
          "Santo António em casa: um vislumbre da vida private de Santo António em casa",
        author: "MaryAnn Santos ",
        location: "Nova Iorque",
        info:
          "Toalha de chá; uma maquette de papelão de uma casa; crusts is; estátua de Santo António de ceramica, e vários outros objetos mágicos.",
        img: "/assets/tronosImgs/participantes/006.jpg",
      },
      {
        inscription: "7",
        title: "TRONO PARA O SÉCULO XXI",
        author:
          "João Ribeiro (conceito artístico/pintura); Diana Leite (video),  Ricardo Coxixo e Vicente Santos (estrutura)",
        location: "Lisboa",
        info:
          "Instalação, assemblage com madeiras tecidos e vídeo, Dimensões Variáveis (aproximadamente 130 cm de altura).",
        img: "/assets/tronosImgs/participantes/007.JPG",
      },
      {
        inscription: "8",
        title: "A Proteção dos Oceanos",
        author:
          "Equipa Pedagógica e Crianças do Infantário da Pena do Centro Social Paroquial da Pena",
        location: "Lisboa",
        info:
          "A construção do trono de Santo António tem vindo a ser uma tradição na nossa escola, sediados na emblemática calçada de Santana, fazia-nos todo o sentido mantermos a honra ao tão querido Santo dos Lisboetas. ",
        img: "/assets/tronosImgs/participantes/008.jpg",
      },
      {
        inscription: "9",
        title: "Os Milagres de Santo António",
        author: "Isabel Assis e Pedro Assis",
        location: "Algueirão",
        info:
          "Utilizámos papelão, papel, ramos de plantas secos, cápsulas de café em alumínio, porcelana fria, ervas secas, madeira e tintas aquosas/acrílicas. A nossa inspiração foram as imagens existentes no Museu de Lisboa, revistas no vídeo apresentado pelo Dr. Pedro Teotónio Pereira.",
        img: "/assets/tronosImgs/participantes/009.jpg",
      },
      {
        inscription: "10",
        title: "A Virtude da Remora",
        author: "Cátia Rodrigues e Vítor Costa",
        location: "Porto Cedofeita",
        info:
          'O Trono é inspirado nas virtudes de Santo António,  nas tradições de lisboa, nas esmolinhas para a reconstrução da igreja e na obra "Sermão de Santo António aos peixes", é um trono de oração. O trono é inspirado nas virtudes de Santo António,  é inspirado num lugar de oração, de fé, de festa popular, são utilizados diversos materiais reciclados assim como azulejo, madeira, cartão, plástico, conchas do mar, rendas, tintas e imaginação.',
        img: "/assets/tronosImgs/participantes/010.jpg",
      },
      {
        inscription: "11",
        title: "Santo António Milagreiro",
        author: "Isabel Machado e Ana Monteiro",
        location: "Vila Nova de Famalicão",
        info:
          "Trono de Santo António feito com caixas de fruta de madeira, cartão, cola, tintas e alguns acessórios em barro incluindo a figura do Santo António.",
        img: "/assets/tronosImgs/participantes/011.jpg",
      },
      {
        inscription: "12",
        title: "Festa em Lisboa",
        author: "João Paulo (15 anos)",
        location: "Loures",
        info:
          "Estrutura em Cartão, o trono foi inspirado nas Festas de Lisboa dos últimos anos (Casamentos, Marchas, Arraiais, etc..), sempre com a figura de Santo António.",
        img: "/assets/tronosImgs/participantes/012.jpg",
      },
      {
        inscription: "13",
        title: "TRONO EM CORTIÇA",
        author: "Sofia (9 anos), Cristina, Sara e Fernando",
        location: "Lisboa",
        info: "Rolhas de cortiça e cartão.",
        img: "/assets/tronosImgs/participantes/013.jpg",
      },
      {
        inscription: "14",
        title: "Santo António casamenteiro",
        author: "Monica Sofia Campos Pires Ferreira",
        location: "Lisboa",
        info: "Caixa de um perfume e papel EVA brilhante.",
        img: "/assets/tronosImgs/participantes/014.jpg",
      },
      {
        inscription: "15",
        title: "Tradição é Tradição",
        author:
          "Vários Elementos da Comunidade Paroquial Nossa Senhora da Pena",
        location: "Lisboa",
        info: "O Santo António da Carreirinha.",
        img: "/assets/tronosImgs/participantes/015.jpg",
      },
      {
        inscription: "16",
        title: "Primaveril",
        author: "Carolina Quinhones Ferreira (9 anos) e Sara Fernandes",
        location: "Lisboa",
        info:
          "É um trono colorido, e em 3D. Materiais: esferovite, papel de embrulho , palitos , papel branco , plasticina.",
        img: "/assets/tronosImgs/participantes/016.jpg",
      },
      {
        inscription: "17",
        title: "Um trono à portuguesa com certeza",
        author: "Américo Grova",
        location: "Lisboa",
        info:
          "Todo o lisboeta traz em sua história muitos costumes que vêm de longe e que constituem muito da identidade alfacinha. Um deles está ligado ao Santo mais popular da cidade, o nosso querido Santo António. O material de confeção das bandeirolas, por motivo dos tecidos inicialmente propostos não resultarem como esperado no conjunto, foi trocado e optei por reciclar restos de EVA para a confeção das mesmas.",
        img: "/assets/tronosImgs/participantes/017.JPG",
      },
      {
        inscription: "18",
        title: "Não há festas de Lisboa",
        author: "Henrique Ganho e Ana Luisa Gomes",
        location: "Lisboa",
        info:
          "Inspiração Santo António em época de Covid19. Materiais: Caixotes, desperdicios de jornais velhos, embalagens de cartão e materiais vários como programas das festas de Lisboa, pregos e barro.",
        img: "/assets/tronosImgs/participantes/018.jpg",
      },
      {
        inscription: "19",
        title: "Trono de Santo António de Lisboa",
        author: "Délio Lopes",
        location: "Tavira",
        info:
          "Fachada de uma Igreja Barroca lembrando a Real-Casa do Santo em Lisboa.",
        img: "/assets/tronosImgs/participantes/019.jpg",
      },
      {
        inscription: "20",
        title: "Santo António entre tachos e panelas",
        author: "Ana Carina Paiva Monteiro",
        location: "Lisboa",
        info:
          "Entre tachos e panelas, colheres e esfregões, subiu o Santo pelas asas, a pensar nas refeições. Materiais reutilizáveis, sem gastar um tostão, pus o menino no baloiço, ao decorar trono com festão. Quarentena na cozinha com Santo António Cozinheiro.",
        img: "/assets/tronosImgs/participantes/020.jpg",
      },
      {
        inscription: "21",
        title: "Trono Real de Santo António",
        author: "Júlia Larouca",
        location: "Gondomar",
        info:
          "O trono de Santo António tem como base o cartão sendo o 1º e 3º degraus forrados a grão de bico e o 2º e 4º forrados a massinhas estrelinha , no topo do trono para destacar a figura de Santo António que é em barro de cascata popular tem cabos de colher.",
        img: "/assets/tronosImgs/participantes/021.jpg",
      },
      {
        inscription: "22",
        title:
          "Santo António de Lisboa/ No Valsassina estará presente/ Ainda que a multidão ausente",
        author:
          "Colégio Valsassina – professores de Expressão Plástica do 1º ciclo;  alunos de 1º e 2 ciclo; colaboradores docentes e não-docentes",
        location: "Lisboa",
        info:
          "O trono foi montado pelos professores de Expressão Plástica do primeiro ciclo do Colégio Valsassina, com base num processo colaborativo, partindo da reciclagem integral – 100% - de diferentes materiais e ornamentos existentes no acervo do Colégio. ",
        img: "/assets/tronosImgs/participantes/022.JPG",
      },
      {
        inscription: "23",
        title: "Santo António salva-nos",
        author: "Mónica Binda",
        location: "Lisboa",
        info:
          "Representação dessa era de Pandemia de Coronavirus com a esperança de que o mundo melhore e que haja mais respeito pela natureza.",
        img: "/assets/tronosImgs/participantes/023.jpg",
      },
      {
        inscription: "24",
        title: "Santo António de Coimbra para o Mundo",
        author: "Junta de Freguesia de Santo António dos Olivais",
        location: "Coimbra",
        info:
          "Trono feito em Madeiras, como o motivo da Igreja de Santo António dos Olivais.",
        img: "/assets/tronosImgs/participantes/24.JPG",
        prize: "Menção Honrosa",
      },
      {
        inscription: "25",
        title: "Santo António cá de Casa",
        author: "Riaoquadrado",
        location: "Lisboa",
        info:
          'Em tempos de pandemia | Queremos participar | Todos os "Heróis da Frente" | Vamos homenagear! (os materiais utilizados são os que estavam disponíveis cá em casa).',
        img: "/assets/tronosImgs/participantes/025.jpg",
      },
      {
        inscription: "26",
        title: "Trono da Esperança",
        author: "Riaoquadrado",
        location: "Lisboa",
        info:
          'Inspirámo-nos na frase chave escrita pelas crianças de Itália, em tempos de COVID 19 ("Vamos Ficar Todos Bem"), como lema de esperança para o futuro, sob a protecção de Santo António.',
        img: "/assets/tronosImgs/participantes/026.jpg",
      },
      {
        inscription: "27",
        title: "Trono do Santo António Tropical",
        author: "Sofia Perpétua e Paulo Mumia",
        location: "Lisboa",
        info:
          "Tanto faz se é em Portugal com ó ou no Brasil com ô, o Santo António é o rei dos pedidos. Para namorar, para casar, para desencalhar, para sair do caritó. Pede-se com fé, se atendido paga-see promessa, se não castiga-se o pobre coitado. Aqui ou lá. ",
        img: "/assets/tronosImgs/participantes/027.jpg",
        prize: "Vencedor",
      },
      {
        inscription: "28",
        title: "Gavetas de Santo António",
        author: "Áurea Estela Paiva Monteiro",
        location: "Lisboa",
        info:
          "Fui às gavetas da avó e com elas fiz degraus, qual bisbilhoteira encontrei rendas lá dentro, pratas, velas e muito material. Santo António de alabastro deu inspiração, o menino dorme ao colo, junto ao coração.",
        img: "/assets/tronosImgs/participantes/028.JPG",
      },
      {
        inscription: "29",
        title: "Santo António protege Lisboa da pandemia",
        author: "Octávio Carmo de Oliveira Santos",
        location: "Lisboa",
        info:
          'A base do trono é formada por 4 degraus em forma de coração. Os espelhos nos degraus estão revestidos de imagens de azulejos, e o seu piso com imagens de diversos padrões de calçada portuguesa. Sobre o último dregau ergue-se uma pequena coluna dourada encimada por nuvens, sobre as nuvens está a figura de Santo António, o qual tirou o Menino do colo para poder desferir um ataque, com raios de fogo que descem das suas mãos, a dois coronavírus, um de vidro e gel e outro de um fruto seco e gel, que tentam subir, não passando do 1º degrau. No primeiro degrau, estão representados outros males anteriores à pandemia: o dinheiro e uma arma de fogo. Tem ainda dois vasos com manjericos para introduzir nota de tradição. No segundo degrau, está o menino, em pé, rodeado de corações, a dar-nos um sinal de esperança ao oferecer-nos um ramo de flores com a mão direita, tendo na esquerda um cetpro com uma estrela verde. No terceiro degrau, tem um azulejo onde se pode ser "LISBOA". A coluna tem, na frente, a imagem de um painel de azulejos representando Santo António com o Menino; de ambos os lados e na retaguarda estão representados 3 corações.',
        img: "/assets/tronosImgs/participantes/029.jpg",
      },
      {
        inscription: "30",
        title: "Trono Santo António'20 às Escadinhas",
        author: "Moradores da Rua Cidade de Manchester",
        location: "Lisboa",
        info:
          "Materiais em fim de ciclo de vida: trono de 2018 e 2019, madeiras de paletes encontradas na rua, tampas de plástico da vizinhança, decorações de natal em velcro, caixas plásticas reutilizadas para colocar plantas aromáticas, copos de plástico para colocação de vasos com plantas da rua, garrafa de óleo para moinho de vento, fio de eletricidade de suporte ao moinho de vento. Plantas aromáticas para dar às crianças pelo dia da criança (salsa, coentros e tomates). Inspiração: Com os votos de grandes festividades de Santos Populares em 2021, vamos celebrar este Santo António, como se estivéssemos em 2021 com tudo o que sobrou do fim da festa de Santo António de 2019. Como projeto dos Moradores da Rua Cidade de Manchester, desde 2018, que criamos um Trono de Santo António sempre com materiais em fim de ciclo de vida e este ano não foi exceção. Aproveitando a construção de um canteiro numa das proeminentes Tílias das Escadinhas da Rua Cidade de Manchester, fez-se, no mesmo dia, o Trono Santo António'20 às Escadinhas e uma surpresa pelo Dia da Crianças às Crianças do Bairro, com a oferta de pezinhos de tomateiros, salsa e coentros junto do Trono de Santo António para as crianças levarem consigo.",
        img: "/assets/tronosImgs/participantes/030.jpg",
      },
      {
        inscription: "31",
        title: "Com os meus botões",
        author: "Manuel Ferreira Machado",
        location: "Braga",
        info:
          "Feito com reciclagem de materiais. Madeiras, ferro, cobre, latão e botões. A base é uma rasa antiga e madeiras de caixas antigas. O arco é um apoio de uma sanita,decorado com ferragens antigas e botões, que simbolizam flores. O Santo António é feito de uma sachola.",
        img: "/assets/tronosImgs/participantes/031.jpg",
      },
      {
        inscription: "32",
        title: "ADECAM e a Tradição Lisboeta",
        author: "ADECAM",
        location: "Lisboa",
        info:
          "A ADECAM, associação cultural que promove as artes com a criatividade pela qual já é reconhecida, criou um trono de Santo António como forma de enaltecer as tradições Lisboetas. ",
        img: "/assets/tronosImgs/participantes/032.jpg",
      },
      {
        inscription: "33",
        title: "Santo Florido",
        author: "Carlota Sousa (9 anos) ",
        location: "Algés",
        info:
          "Trono executado por uma menina curiosa, com grande interesse pela natureza, que gosta de tarbalhar a arte ao ar livre. Inspiração: Natureza e reciclagem; Materiais: Cartão, papéis de várias texturas, tecidos de várias texturas, Plasticina, Flores Naturais.",
        img: "/assets/tronosImgs/participantes/033.jpg",
      },
      {
        inscription: "34",
        title: "800 anos de Santo António Franciscano",
        author: "Ruy Dinis",
        location: "Ericeira ",
        info:
          "Esferovite, tecidos diversos e papel. Inspirado nos tradicionais Tronos fazendo memória ao percurso de Fernando de Bolhões como Franciscano.",
        img: "/assets/tronosImgs/participantes/034.jpg",
      },
      {
        inscription: "35",
        title: "Santo António Espantado",
        author: "Carla Jorge Alves",
        location: "Alcochete",
        info:
          "A figura de Santo António, bem como o menino e os vasos, é feita em barro, por mim. O Santo está espantado com tudo o que se passa. A base é feita com caixas de sapatos. A decoração é feita com restos de fitas e tecidos das minhas costuras. ",
        img: "/assets/tronosImgs/participantes/035.jpg",
      },
      {
        inscription: "36",
        title: "Trono Solidário",
        author: "Ana Carina Paiva Monteiro",
        location: "Lisboa",
        info:
          "O pão alimenta o corpo, a fé e oração alimentam a alma. Fiz pão, criei degraus para chegar ao Santo, tal como tento chegar aqueles que precisam. O verde é homenagem a Lisboa capital verde europeia 2020. A vida deve ser um trono solidário.",
        img: "/assets/tronosImgs/participantes/036.jpg",
      },
      {
        inscription: "37",
        title: "Paixão de Lisboa",
        author: "Maria João Correia e Maria Paula Alves",
        location: "Lisboa",
        info:
          "Tecido e papel - Lisboa, os azulejos, a calçada portuguesa, o fado, os símbolos e a noite de Santo António.",
        img: "/assets/tronosImgs/participantes/037.jpg",
      },
      {
        inscription: "38",
        title: "Santo António em casa",
        author: "Vera Mascarenhas (8 anos)",
        location: "Lisboa",
        info:
          "Utilização de materiais existentes em casa como cápsulas de café, caixa velha, frasco de gel de banho, velha bola de ping-pong, restos de papel de cor, pacotes de papel de chás, guaches, crochet, a inspiração veio dos próprios materiais recicláveis.",
        img: "/assets/tronosImgs/participantes/038.JPG",
      },
      {
        inscription: "39",
        title: "Santo António e o Covid-19",
        author: "Helena Maria",
        location: "Lisboa",
        info:
          "Feito com caixas de cartão, forrado com um lençol, decorado com rendas antigas, cristais de um candeeiro, velas e castiçais de uso doméstico. Completado com arranjos florais feitos por mim e manjericos. Um pormenor é que o Santo António e menino estão com máscara.",
        img: "/assets/tronosImgs/participantes/039.jpg",
      },
      {
        inscription: "40",
        title: "Santo António e as festas Populares",
        author: "Bruno Santos",
        location: "Rio Tinto",
        info:
          "O trono é composto por uma escada de 6 níveis, com uma faixa vermelha a lembrar os tapetes centrais das igrejas. Conta com elementos alusivos às festas populares, como as sardinhas de pano, e o manjerico. Os três elementos são os santos populares, Santo António, São João e São Pedro. os materiais utilizados foram: Flores de Feltro, Restos de tecido de algodão para as vestimentas dos santos, papel e cartão para as escadas, lã cabelo dos santos, Barro e feltro para o manjerico. O trono foi inspirado nas tradições populares e no artesanato. ",
        img: "/assets/tronosImgs/participantes/040.JPG",
      },
      {
        inscription: "41",
        title: "Milagres de Santo António",
        author: "Hélder Santos",
        location: "Rio Tinto",
        info:
          "O trono representa os dois mais conhecidos milagres de santo António, Milagre dos peixes e Milagre dos pássaros. As escadas do trono foram feitas com livros, que representa a bíblia sagrada com o qual santo António pregava aos peixes e ao povo. Os materiais utilizados foram livros, flores, aquário com peixes verdadeiros, pássaros, e andorinha de barro. Inspiração nos milagres de Santo António.",
        img: "/assets/tronosImgs/participantes/041.jpg",
      },
      {
        inscription: "42",
        title: "Santo António",
        author: "Isabel Pichel",
        location: "Vila Nova de Gaia",
        info:
          "Cartão, pintura acrílica imitando granito, papel fantasia, pequenas flores secas, pequenos pom-pons, cápsulas Nespresso revestidas a pasta de modelar imitando barro, folhas do manjerico em pasta de modelar verde.Tapete das escadas em pasta modelar Darwin.",
        img: "/assets/tronosImgs/participantes/042.jpg",
      },
      {
        inscription: "43",
        title: "O sermão de Santo António às sardinhas",
        author: "Liliana C Santos",
        location: "São Domingos de Rana",
        info:
          "Trono construido em papelão dentro de uma antiga caixa de costura. Forrado com um mapa antigo de Lisboa e com EVA. Decorado com desperdício de renda, sardinhas feitas feitas em cartão e decoradas com brilhantes. Manjericos e guitarra feitos em cartão, utilizei como base uma caixa de costura antiga onde construí o trono com cartão, papel, caixas de ovos, embalagens, EVA, renda (desperdício), lã. Inspirei-me nos arraiais - sardinhas, mangericos, festa - e na origem dos tronos: angariação de fundos.",
        img: "/assets/tronosImgs/participantes/043.jpg",
      },
      {
        inscription: "44",
        title: "Santo António vai à bica",
        author: "Paula Baltazar Martins e Áurea Dantas",
        location: "Amadora",
        info:
          "A inspiração do nosso trono é a tradição tão lisboeta, que com toda a certeza o Santo António cumpria, de “tomar a bica”. Os materiais utilizados são uma reciclagem de cápsulas de café e respectivas caixas, rolha de cortiça, caixas de papel A4 e de cereais, folhetos de supermercado, panfleto de sensibilização sobre a Covid-19, restos de tecido e feltro, botão, flor seca, tampa de embalagem de fermento, pau de gelado, fósforos, guardanapo de papel, arame e cordel. ",
        img: "/assets/tronosImgs/participantes/044.jpg",
      },
      {
        inscription: "45",
        title: "O nosso primeiro trono",
        author:
          "Tiago Barbosa (10 anos) - Os filhos quiseram, os pais alinharam! ",
        location: "Porto Salvo",
        info:
          "Inspirámo-nos noutros modelos que pesquisámos na net. Materiais: reutilização de caixas de café, de ovos, de sapatos e outros cartões; tampas de plástico e outros materiais de desperdício.",
        img: "/assets/tronosImgs/participantes/045.jpg",
      },
      {
        inscription: "46",
        title: "Moedinha para o Santo António",
        author: "Célia Maria Gonçalves Gomes Ferraz",
        location: "Lisboa",
        info:
          "Material reciclado: Caixa cartão e de leite, restos de tecido, bordado inglês, flores secas pintadas, bolas para fazer colares e uma concha.",
        img: "/assets/tronosImgs/participantes/046.jpg",
      },
      {
        inscription: "47",
        title: "Trono de Santo António no Arraial de Lisboa",
        author: "Alexandre Correia",
        location: "Lisboa",
        info:
          "Barro, folha de ouro, prata, cartão, tecido, papel, plástico, arame, tinta acrílica.",
        img: "/assets/tronosImgs/participantes/047.jpg",
      },
      {
        inscription: "48",
        title: "Trono de Apartamento",
        author: "João Batel",
        location: "Lisboa",
        info: "Caixas e objetos criados pelo próprio e objetos não usados.",
        img: "/assets/tronosImgs/participantes/048.jpg",
      },
      {
        inscription: "49",
        title: "Santo António Casamenteiro",
        author: "Gezo Marques",
        location: "Lisboa",
        info:
          "Latas e caricas (de azeitonas, refrigerantes, cerveja, atum e fermento), os ingredientes para um bom copo de água.",
        img: "/assets/tronosImgs/participantes/049.jpg",
      },
      {
        inscription: "50",
        title: "Santo António Mar Português",
        author: "José Gonçalves",
        location: "Lisboa",
        info:
          "Latas de conserva de atum e sardinha, figuras alusivas ao universo da pesca em metal, cerâmica e madeira. A tradição de comer sardinhas nas festas do Santo fez-me pensar na bonita relação de amor que Lisboa tem com o mar, então fiz uma homenagem ao universo.",
        img: "/assets/tronosImgs/participantes/050.jpg",
      },
      {
        inscription: "51",
        title: "Santo António de Lisboa",
        author: "Agrupamento 48 - Santa Catarina",
        location: "Lisboa",
        info:
          "Feito com os restos de materias diversos que existem no agrupamento.",
        img: "/assets/tronosImgs/participantes/051.jpg",
        prize: "Vencedor",
      },
      {
        inscription: "52",
        title: "Santo António de Lisboa",
        author: "Fernando Castro",
        location: "Alcabideche",
        info:
          "Telha, madeira, papel, tinta acrílica, fita de decoração, gesso a inspiração vem do Trono de Santo António no Bairro da Bica em 1991.",
        img: "/assets/tronosImgs/participantes/052.jpg",
      },
      {
        inscription: "53",
        title: "T(Sa)into António",
        author: "Sara Vinagre",
        location: "Sintra",
        info:
          "Este trono foi inspirado pela tradicional festa popular dedicada ao Santo, pelos marcos estéticos da nossa cultura, e pelo vinho! Materiais: Madeira, Papel, Cortiça. Inspiração: Portugal.",
        img: "/assets/tronosImgs/participantes/053.jpg",
      },
      {
        inscription: "54",
        title: "Sorrisos para Santo António...",
        author:
          "Equipa do Pelouro da Educação da Junta de Freguesia de São Vicente, com a colaboração das crianças e famílias das CAF e AAAF de São Vicente",
        location: "Lisboa",
        info:
          "A construção deste trono, teve como principal objetivo aproximar-nos – equipa da educação e crianças - estando longe. Pedimos a participação de todas famílias e crianças das CAF e AAAF, na elaboração do Trono da Educação de São Vicente! ",
        img: "/assets/tronosImgs/participantes/054.JPG",
      },
      {
        inscription: "55",
        title: "Arraial de Santo António em tempos de Covid-19",
        author: "Pedro Maria Machado",
        location: "Palmela",
        info:
          "Para construir o trono utilizei, maioritariamente, material reciclado: caixas de sapatos e de shampô para a base e para o altar de Santo António, paus de espetadas para suportar o arraial, papel crepe para a passadeira vermelha...",
        img: "/assets/tronosImgs/participantes/055.jpg",
      },
      {
        inscription: "56",
        title: "Santo Antonio do Confinamento",
        author: "Ana Bárbara de Santo António",
        location: "S. Félix da Marinha",
        info:
          "Pão seco, hortelã, manjerico, Sto. António em feltro feito a mão.",
        img: "/assets/tronosImgs/participantes/056.jpg",
      },
      {
        inscription: "57",
        title: "DJ Santo António",
        author: "Peter Houle",
        location: "Lisboa",
        info:
          "Discos de vinil, vasos para flores e discos verdes para o manjericão, cartões de vinho, revistas, interiores de disquetes.",
        img: "/assets/tronosImgs/participantes/057.jpg",
      },
      {
        inscription: "58",
        title: "TRONO",
        author: "A.calpi",
        location: "Lisboa",
        info:
          "Livros, figura de cerâmica encontrada sem cabeça, com bola de ping pong, colagem fotográfica com prata de chocolate (de A.calpi), pele animal. O Menino Jesus, na figura, está sentado num livro. Os livros são os vários degraus.",
        img: "/assets/tronosImgs/participantes/058.jpg",
      },
      {
        inscription: "59",
        title: "Santo António é Meu Amigo",
        author: "Teresa Duarte (9 anos)",
        location: "Odivelas",
        info:
          "Inspiração obtida numa pintura. Composto de caixas de sapatos reutilizadas, restos de folhas de papel coloridas, garrafas de plástico, restos de tecido e pompons.",
        img: "/assets/tronosImgs/participantes/059.jpg",
      },
      {
        inscription: "60",
        title: "Trono de Santo António Tipicamente Madeirense",
        author: "Ricardo Ferraz",
        location: "Estreito de Câmara de Lobos",
        info:
          "O trono reflete a Região Autónoma da Madeira, através de elementos locais presentes nos arraiais e no quotidiano dos madeirenses. Os materiais utilizados foram: aproveitamento de madeira de paletes, tecido, esferovite, papel metalizado, papel de veludo vermelho, recolha de alguns elementos naturais e objetos. A sua inspiração advém da Região Autónoma da Madeira e das suas tradições.",
        img: "/assets/tronosImgs/participantes/060.jpg",
        prize: "Menção Honrosa",
      },
      {
        inscription: "61",
        title: "Altar de Santo António",
        author: "Alfredo Meireles",
        location: "Guimarães",
        info:
          "Inspiração nos retábulos tradicionais das Igrejas portuguesas. Imagem de Santo António em Terracota de Helena Lourenço; reutilização de papel, tecidos, galões, elementos vegetais.",
        img: "/assets/tronosImgs/participantes/061.jpg",
      },
      {
        inscription: "62",
        title: "Santo do Amor, Fé e Paz",
        author:
          "Ana Bernardino Vieira (Mãe e autora) e Clara Bernardino Vieira (filha e assistente)",
        location: "Portimão",
        info:
          "Materiais: tudo o que tinha cá por casa, caixas de cartão, de encomendas do tempo de confinamento, para estrutura e base do trono, forradas com restos papel crepe azul já usados em festas de aniversários, caixas de ovos, recortadas e pintadas a pincel. Inspiração: É um Santo que tenho devoção, aprendi a gostar deste Santo com a minha Avó paterna Laurinda, que este ano, se fosse viva, faria 100 anos.",
        img: "/assets/tronosImgs/participantes/062.jpg",
      },
      {
        inscription: "63",
        title: "Santo António e a Pandemia",
        author: "Conceição e Vasco Apolinário",
        location: "Queijas",
        info:
          "Estrutura feita com aproveitamento de madeiras, forrada com papel crepe de diferentes cores. Os manjericos são naturais. O Santo António e o Menino, atendendo ao momento que vivemos, estão de máscara. A decoração foi inspirada nas Festas dos Santos Populares. Trono composto por quatro degraus ladeados por manjericos naturais, com o Santo António no topo, cobertura decorada com inspiração nas Festas dos Santos Populares.",
        img: "/assets/tronosImgs/participantes/063.JPG",
      },
      {
        inscription: "64",
        title: "Trono do Museu de Lisboa- Teatro Romano",
        author: "Equipa do Museu de Lisboa - Teatro Romano",
        location: "Lisboa",
        info:
          "A nossa inspiração é uma peça de teatro realizada no Teatro Romano , em que o principal espectador é o Santo António. O material utilizado foi: Folheto , Papel de Seda, Papel de embrulho , Restos de tinta acrílica, tampas, cápsulas de café, palitos.",
        img: "/assets/tronosImgs/participantes/064.jpg",
      },
      {
        inscription: "65",
        title: "Tronos de Santo António",
        author: "Carmen Moço",
        location: "Setúbal",
        info: " Rolhas de cortiça. Materiais reciclados.",
        img: "/assets/tronosImgs/participantes/065.jpg",
      },
      {
        inscription: "66",
        title: "Santo António",
        author: "Maria Leonor da Silva Raposo Sanches Pereira",
        location: "Lisboa",
        info:
          "Troncos, caixas de papel, caixas de madeira, objetos domésticos, flores secas.",
        img: "/assets/tronosImgs/participantes/066.jpg",
      },
      {
        inscription: "67",
        title: "Santo António nos valha!",
        author: "Domingas CRV",
        location: "Porto",
        info:
          "O Trono é constituído por uma estrutura cilíndrica de vidro com 3 degraus no interior e por figurado de barro policromado, de Barcelos. A inspiração partiu da atual situação de pandemia que limita os tradicionais festejos dos Santos Populares. Na base do Trono, está S. Pedro, no nível seguinte S. João e no superior Santo António. Estão confinados por causa da pandemia.",
        img: "/assets/tronosImgs/participantes/067.jpg",
      },
      {
        inscription: "68",
        title: "Os Três Santos",
        author: "João Matos",
        location: "Lisboa",
        info:
          "Cartão reciclado, caixa de madeira reciclada, cápsulas de café, corda e papel. Inspirado na calçada portuguesa, nas igrejas, nos manjericos e nos casamentos de Santo António.",
        img: "/assets/tronosImgs/participantes/068.jpg",
      },
      {
        inscription: "69",
        title: "Campestre",
        author: "Mariana Quinhones Ferreira (6 anos)",
        location: "Lisboa",
        info: "Materiais recicláveis e flores naturais.",
        img: "/assets/tronosImgs/participantes/069.jpg",
      },
      {
        inscription: "70",
        title: "Costureiro do Amor",
        author: "Margarida Viana",
        location: "Lisboa",
        info:
          "Amor, costura. Materiais: madeira prensada, feltro, caixas metal e cartão, flores artificiais e naturais, tecido, fita, máquinas de costura feitas com diversos materiais reciclados, linha crochet, fitas métricas. ",
        img: "/assets/tronosImgs/participantes/070.jpg",
        prize: "Menção Honrosa",
      },
      {
        inscription: "71",
        title: "Santo António do antigamente no presente",
        author: "Maria Madalena Alvarenga (9 anos) e José Alvarenga (6 anos)",
        location: "Lisboa",
        info:
          "É um Santo António muito antigo, de casa da bisavó emoldurado por materais reciclados (tampas, caixas de encomenda online, restos de tecidos e fitas, luz de bicicleta estragada e outras embalagens). Representa o antigo no presente e tem uma particularidade que é o arco-iris como pano de fundo no trono para simbolizar os tempos de confinamento por causa do corona vírus.",
        img: "/assets/tronosImgs/participantes/071.jpg",
      },
      {
        inscription: "72",
        title: "Santo Literário",
        author: "Cristina Velozo",
        location: "Lisboa",
        info:
          "Livros sobre santos e vida do Santo António existentes na Loja Social Dona Ajuda.",
        img: "/assets/tronosImgs/participantes/072.jpg",
      },
      {
        inscription: "73",
        title: "Santo António e o Tejo aos pés",
        author: "Idosos do Centro de Dia da APRIM",
        location: "Lisboa",
        info:
          "Materiais recicláveis como sacos de lixo, jornais, pacotes de leite, couvetes plásticas, sacas de batatas.",
        img: "/assets/tronosImgs/participantes/073.jpg",
      },
      {
        inscription: "74",
        title: "Trono de Santo António",
        author: "Mafalda Veiga",
        location: "Viseu",
        info:
          'O trono que realizei pretende remeter para a figura de Santo António, fazendo referência não só às festas populares, como também aos casamentos de Santo António e ao "Sermão de Santo António aos Peixes". Utilizou-se materiais reutilizados como papel e cartão.',
        img: "/assets/tronosImgs/participantes/074.png",
      },
      {
        inscription: "75",
        title: "Santo António dos Ofícios",
        author:
          "Equipa do Museu de Artes Decorativas Portuguesas com a colaboração das Oficinas da Fundação Ricardo do Espírito Santo Silva",
        location: "Lisboa",
        info:
          "Figura de Santo António: Santo António / Escultura em madeira pertencente ao acervo do Museu de Artes Decorativas Portuguesas / Século XVI / Dim: A 67 cm / Inv. 556 / Fotografia: Patrícia Blazquez. Utilização de materiais reciclados (madeira, papel, cartão, tecidos).",
        img: "/assets/tronosImgs/participantes/075.jpg",
      },
      {
        inscription: "76",
        title: "Trono de Santo António dos Netos e dos Avós",
        author: "Neto Artur Rebelo (8 anos) e avós Lena e Luís Rebelo",
        location: "Lisboa",
        info:
          "Como passei a quarentena com os meus avós, na quinta deles em Belmonte, inspirei-me na natureza que tinha à minha volta. Os materiais que usei foram: uma base de tronco de plátano, a seguir uma base de tronco de cerejeira e dois pequenos troncos de Faia e as pedrinhas que apanhei no rio.",
        img: "/assets/tronosImgs/participantes/076.jpg",
        prize: "Vencedor",
      },
      {
        inscription: "77",
        title: "Trono Santo António",
        author:
          "Centro Social e Paroquial Nossa Senhora do Carmo do Alto do Lumiar",
        location: "Lisboa",
        info:
          "Caixas de Cartão, rendas tradicionais, papel colorido, papel cenário, feltro, garrafa de plástico (corpo Santo António).",
        img: "/assets/tronosImgs/participantes/077.jpg",
      },
      {
        inscription: "78",
        title: "Um trono para o Santo António da Fábrica de Loiça de Sacavém",
        author: "Museu de Cerâmica de Sacavém. Câmara Municipal de Loures",
        location: "Lisboa",
        info:
          "Com recurso a caixas de cartão e vários tipos de papel, criou-se um trono enquadrado num espaço revestido (simulado) a azulejos  produzidos na Fábrica de Loiça de Sacavém.",
        img: "/assets/tronosImgs/participantes/078.JPG",
      },
      {
        inscription: "79",
        title: "Recordando",
        author: "Maria Antónia Braga",
        location: "Lisboa",
        info:
          "Reutilizar materiais já existentes em casa: caixas de madeira e uma caixa de vinho do porto.",
        img: "/assets/tronosImgs/participantes/079.jpg",
      },
      {
        inscription: "80",
        title: "Hó meu rico Stº António",
        author: "Belaideias- Anabela Teixeira",
        location: "Pinheiro de Loures",
        info:
          "Nos anos de 2015/2016 e 2017 eu tinha uma loja na rua S.João da Praça e aderi ao desafio do Museu de Sto António e fiz o trono mas tive de me vir embora de Lisboa e abri uma loja em Pinheiro de Loures. Os materiais utilizados foram Papel, Algodão e cápsulas da nespresso nos vasos.",
        img: "/assets/tronosImgs/participantes/080.jpg",
      },
      {
        inscription: "81",
        title: "Santo Antônio lá fora",
        author: "Mônica Silva",
        location: "Bahia",
        info:
          "O Trono teve inspiração nesse momento de pandemia que estamos vivendo. Onde o isolamento social faz necessário. Porém, a ideia foi deixar o santo fora do oratório rogando a Ele a proteção e auxilio, ainda assim representando também os individuos dentro de casa (representado pela moldura de um porta retrato)...",
        img: "/assets/tronosImgs/participantes/081.jpg",
        prize: "Menção Honrosa",
      },
      {
        inscription: "82",
        title: "Meu Rico Santo António",
        author: "João Batel",
        location: "Lisboa",
        info: "Bonecos, colares, jóias, feitos com materiais reciclados.",
        img: "/assets/tronosImgs/participantes/082.jpg",
      },
      {
        inscription: "83",
        title: "Santo António e o Livro",
        author: "Maria Adelina de Figueiredo Batista Amorim",
        location: "Algés",
        info:
          "Um trono só de Santo Antónios. Tema principal: o Livro. Foram usados livros para fazer os degraus e a decoração; diversas imagens de Santo António da colecção da Autora;  flores (a maioria retirada de laços de embalagens; outras de feltro recortado), etc.",
        img: "/assets/tronosImgs/participantes/083.jpg",
      },
      {
        inscription: "84",
        title: "Enquanto houver Santo António",
        author: "Sónia Neves e Tiago Ventura (6 anos)",
        location: "Parede",
        info:
          'Na escola do Tiago não sabiam o que era um trono de Santo António e, com materiais que tínhamos em casa conseguimos fazer uma réplica de um trono simpático e "adaptado" às crianças. ',
        img: "/assets/tronosImgs/participantes/084.jpg",
      },
      {
        inscription: "85",
        title: "Santo António com lata",
        author: "Alexandra Pires",
        location: "Loulé",
        info:
          "Trono em 3 andares de latas variadas e santo António em papel. Vasos feitos com reutilização de latas de patê. Inspirado nos Tronos mais humildes, de quem colocava uma imagem em cartão do Santo, uma vez que não tinha poder económico para comprar. Utilização de latas variadas, terra, plantas suculentas, papel. As latas são objetos bonitos só por si, por isso nunca acabam no lixo, são usadas para guardar os mais variados materiais, desde botões, fitas, alfinetes, ganchos, jóias e por vezes, até servem de vasos.",
        img: "/assets/tronosImgs/participantes/085.jpg",
      },
      {
        inscription: "86",
        title: "O.M.D.T",
        author: "Verónica Pinto (13 anos)",
        location: "S. Domingos de Rana",
        info:
          "Trono com Santo António e adereços dos santos populares, com os seguintes materiais: PVC, papel, cartão, espuma EVA e a inspiração foi a catequese e aquilo que lá aprendemos.",
        img: "/assets/tronosImgs/participantes/086.jpg",
      },
      {
        inscription: "87",
        title: "Santo António na calçada",
        author: "Maria Rebocho",
        location: "Massamá",
        info:
          "Na maioria papel, quer em simples, quer em canudo e trabalhado para a concepção do Santo António, manjericos e outros adereços. Plástico e outros como cola e tintas.",
        img: "/assets/tronosImgs/participantes/087.jpg",
      },
      {
        inscription: "88",
        title: "Restos de Papel",
        author:
          "Maria Luísa Castanheira /Celeste Castanheira / António Castanheira",
        location: "Lisboa",
        info:
          "Restos de papel autocolante com padrões de Chita de Alcobaça com que decoramos os tronos de 2017/18/19.",
        img: "/assets/tronosImgs/participantes/088.jpg",
      },
      {
        inscription: "89",
        title: "Santo Antonio da Cripta",
        author: "Ana Barbara Santo Antonio",
        location: "S. Félix da Marinha",
        info:
          "Aparas e toros de madeira, pompons e Santo António de miolo de pão.",
        img: "/assets/tronosImgs/participantes/089.jpg",
      },
      {
        inscription: "90",
        title: "Olhai por nós meu Sto. Antoninho",
        author: "Paula Loureiro",
        location: "Lisboa",
        info:
          'Escadaria com Santo António no topo ladeado por manjericos e prato de peditório "Oh Senhor/a dê uma moedinha para o Santo António!". Inspirado no que está para vir. Os materiais utilizados para a escada foram peças Mahjongg e tecido e para o Santo António e o Menino, papel e berlinde no interior e tecidos no exterior. Para os manjericos, papel no interior e tecido para forrar e para o prato de peditório, uma tampa de frasco forrado a tecido.',
        img: "/assets/tronosImgs/participantes/090.jpg",
      },
      {
        inscription: "91",
        title: "Trono de Santo António",
        author: "Susana Atalaia e Mariana Atalaia",
        location: "Rio de Mouro",
        info:
          "Escadas feitas com pacotes de leite pintados de cor castanha, depois os manjericos feitos em feltro e com tampas de garrafa, as sardinhas foram recortadas do pacote de sumo e coladas, o Santo António feito com uma garrafa de iogurte e o vestido com feltro. Materiais: Pacotes de leite, rolhas de garrafas, feltro, tinta guache, garrafa de iogurte, lã.",
        img: "/assets/tronosImgs/participantes/091.jpg",
      },
      {
        inscription: "92",
        title: "Santo António em palmeira, Braga",
        author: "José Lopes",
        location: "Braga",
        info:
          "Trono de 3 módulos, composto por Caixas de cartão, papel embrulho, cápsulas de café e fores artificiais e a minha imagem de Santo António com mais de 50 anos. ",
        img: "/assets/tronosImgs/participantes/092.jpg",
      },
      {
        inscription: "93",
        title: "Trono de St. António em Oeiras",
        author: "Família Fėo e Torres",
        location: "Oeiras",
        info: "Material reciclável manufacturado por escuteiros.",
        img: "/assets/tronosImgs/participantes/093.jpg",
      },
      {
        inscription: "94",
        title: "Santo António a chegar põe as viroses a andar",
        author: "Gonçalo Soares e Matilde Barata (7 anos).jpg",
        location: "Lisboa",
        info:
          "Caixas de bolachas e cereais, cartolinas e folha de alumínio. Inspirado nos símbolos das festas populares.",
        img: "/assets/tronosImgs/participantes/094.jpg",
      },
      {
        inscription: "95",
        title:
          "Santo António está a chegar/ Com Festa vamos comemorar/ Por esta noite fora/ manda este vírus embora",
        author: "Sónia Barata e Maria Inês Barata (10 anos)",
        location: "Lisboa",
        info:
          "Para retratar os principais símbolos das festas de Lisboa: manjerico, sardinhas, cântaro de vinho e a imagem de Santo António, utilizamos cartão, cartolina, revistas e papel de aluminio. ",
        img: "/assets/tronosImgs/participantes/095.jpg",
      },
      {
        inscription: "96",
        title: "Sto António e os famosos rolos de papel higiénico!",
        author: "Ana Soeiro e Maria Oliveira Soeiro (6 anos)",
        location: "Lisboa",
        info:
          "Cartão, Cartolina, rolos de papel higiénico e guaches. A inspiração veio mesmo da necessidade de dar uso aos imensos rolos de papel higiénico vazios que acumulámos durante este confinamento.",
        img: "/assets/tronosImgs/participantes/096.jpg",
      },
      {
        inscription: "97",
        title: "S. António em tempo de Covid",
        author: "Sílvia Jardim e Henrique Jardim Rodrigues",
        location: "Moscavide",
        info:
          "Santo António e Menino,  feitos em pano, vestimenta de máscaras. Tapete do trono feito de guardanapos com sardinhas. Sardinhas grandes feiras em pasta de papel. Adornos na escadaria: objeto existentes em casa e os pãezinhos de Santo António.",
        img: "/assets/tronosImgs/participantes/097.jpg",
      },
      {
        inscription: "98",
        title: "Sto António no Mar Lisboa",
        author: "Margarida Marques da Costa",
        location: "Lisboa",
        info:
          "O Mar inspirou a construção do trono de Sto António. Utilizei elementos da naturaza, conchas, pedras, búzios. Utilizei também materiais reciclados cx de cartão. Por fim utlizei panos e tule. ",
        img: "/assets/tronosImgs/participantes/098.jpg",
      },
      {
        inscription: "99",
        title: "Homenagear Santo António e as Festas de Lisboa",
        author: "Miguel Rui Infante",
        location: "Paço de Arcos",
        info:
          "Uma homenagem aos tronos de Santo António, aos milagres do Santo e à sua procissão. Trono feito com a reciclagem de caixas forradas a papel crepe.",
        img: "/assets/tronosImgs/participantes/099.JPG",
      },
      {
        inscription: "100",
        title: "Hosc ANTÓNIO Rasc",
        author: "Hugo Orlando E Rui Alberto Coelho",
        location: "Lisboa",
        info: "Gesso e adereços. Inspiração: família.",
        img: "/assets/tronosImgs/participantes/100.jpg",
      },
      {
        inscription: "101",
        title: "SANTO ANTÓNIO NOS CONSERVE",
        author: "Maria João Canellas",
        location: "Lisboa",
        info:
          "Reciclando cartão de embalagens e as abençoadas latas de conserva, que nos permitiram ir menos vezes às compras durante o confinamento, e “reciclando” também alguns dos elementos que criei para a sardinha com que participei no Concurso Sardinhas Festa de Lisboa’14, inspirei-me nos Santos Populares e na cidade de Lisboa.",
        img: "/assets/tronosImgs/participantes/101.JPG",
      },
      {
        inscription: "102",
        title: "Trono do Xico",
        author: "Ana Margarida Rodrigues",
        location: "Lisboa",
        info:
          "Dedicado a um amigo/colega que nos enche de saudades. Material: papel.",
        img: "/assets/tronosImgs/participantes/102.jpg",
      },
      {
        inscription: "103",
        title: "Sermão aos Peixes",
        author: "Luís Manuel Andrez Duarte",
        location: "Monchique",
        info:
          "Trono celebrando o milagre do Sermão de Santo António aos Peixes. O trono foi inspirado na cultura portuguesa, azulejaria, folclore e a ligação do povo português só mar. Foram utilizados cordéis de atar, revistas, papel de embrulho, garrafa de água em plástico, papel higiênico, pilhas sem carga, caixas de vinho...",
        img: "/assets/tronosImgs/participantes/103.jpg",
      },
      {
        inscription: "104",
        title: "Multicolorido_Multi/Divertido!",
        author: "Brígida Isabel Gonçalves Ribeiros",
        location: "Castelo Branco",
        info:
          "O trono é feito usando embalagens de plástico de produtos de higiene e detergentes reutilizadas. A inspiração provém das cores das embalagens que transmitem a alegria e diversão das festas de Lisboa. A técnica usada é o upcycling.",
        img: "/assets/tronosImgs/participantes/104.jpg",
      },
      {
        inscription: "105",
        title: "Aurora da Esperança",
        author: "Margarida Viana",
        location: "Caxias",
        info:
          "Materiais: caixas de botas e sapatos, pacote de leite, caixa madeira, feltro, rolhas, bonecos madeira, serapilheira, croché, papel, cartão, contas e cruz metal. ",
        img: "/assets/tronosImgs/participantes/105.jpg",
      },
      {
        inscription: "106",
        title: "Santo António dos amores",
        author:
          "Maria Odete Delgado e família (Maria Odete, Adelino, Daniela, Gabriel)",
        location: "Moita",
        info:
          "O trono foi feito com materiais existentes em casa e flores do jardim. A imagem de Santo António foi pintada por Adelino Lopes. O trono é forrado com uma toalha bordada em linho, e a inspiração veio do jardim, das flores da época.",
        img: "/assets/tronosImgs/participantes/106.jpg",
      },
      {
        inscription: "107",
        title: "MITRA - Hoje e Amanhã (SCML)",
        author: "Residentes da MITRA",
        location: "Lisboa",
        info:
          "Reprodução da Requalificação dos Pavilhões e Espaços envolventes (o antes e o depois).",
        img: "/assets/tronosImgs/participantes/107.jpg",
        prize: "Menção Honrosa",
      },
      {
        inscription: "108",
        title: "Trono de Santo António",
        author: "Maria Inês Dias Pinto (10 anos)",
        location: "Lisboa",
        info:
          "Madeira para o trono, tecido, esferovite, cartão, tintas, brilhantes.",
        img: "/assets/tronosImgs/participantes/108.jpg",
      },
      {
        inscription: "109",
        title: "Mudam-se os tempos, mantêm-se tradições",
        author: "Cantinho dos Anjos",
        location: "Lisboa",
        info:
          "Os materiais do trono foram reaproveitados de outras atividades de forma a que fosse totalmente reciclado. A inspiração tem a ver com os bairros da cidade de Lisboa e os tons coloridos pretendem trasmitir as boas energias e o espirito de festa dos Santos. Materiais: madeira, reaproveitamento de papel, mola da roupa, tintas, cordão, cola, aproveitamentos de feltro.",
        img: "/assets/tronosImgs/participantes/109.jpg",
      },
      {
        inscription: "110",
        title: "Trono trauma Covid",
        author: "Ana Bernardo",
        location: "S. Félix da Marinha",
        info:
          "Kit de emergência Covid, papel higiénico, máscara, luvas látex, álcool gel fita decorativa pompons.",
        img: "/assets/tronosImgs/participantes/110.jpg",
      },
      {
        inscription: "111",
        title: "Trono Conimbricense",
        author:
          "Centro Social Partilha e Saber Dr Fausto Correia - responsável, Teresa Margarida dos Santos Martins",
        location: "Coimbra",
        info:
          "A inspiração são os bailes populares de Coimbra e as histórias de enamoramento entre os estudantes e as tricanas e a presença de Santo António na nossa cidade. Os materiais utilizados foram: papel, cartão, feltro, barro, fitas de algodão e madeira.",
        img: "/assets/tronosImgs/participantes/111.jpg",
      },
      {
        inscription: "112",
        title: "O barroquismo popular de António",
        author: "Bruno Sampaio Lobo",
        location: "Figueira da Foz",
        info:
          "Inspirado nos altares barrocos foram utilizadas miniaturas, tecidos e brocados e madeira.",
        img: "/assets/tronosImgs/participantes/112.jpg",
      },
      {
        inscription: "113",
        title: "Sto. António protege Lisboa da pandemia",
        author: "Octávio Carmo de Oliveira Santos",
        location: "Lisboa",
        info:
          'A base do trono é formada por 4 degraus em forma de coração. Os espelhos nos degraus estao revestidos de imagens de azulejos, e o seu piso com imagens de diversos padrões de calçada portuguesa. Sobre o último dregau está a figura de Santo António, o qual tirou o Menino do colo para poder desferir um ataque, com raios de luz que descem das suas mãos, a uma vaga de coronavírus, de vidro e gel, que tentam subir, não passando do segundo degrau. no primeiro degrau, que tem um azulejo onde se pode ser "LISBOA", estão representados outros males anteriores à pandemia: o dinheiro e a arma de fogo. Tem ainda três vasos com manjericos e quadras para introduzir uma nota de tradição. No terceiro degrau, está sentado o menino, rodeado de corações, a dar-nos um sinal de esperança ao oferecer-nos um ramo de flores do campo.',
        img: "/assets/tronosImgs/participantes/113.jpg",
      },
      {
        inscription: "114",
        title: "Santo Covid",
        author: "Ana Velozo",
        location: "Lisboa",
        info:
          "Santo e Menino em Quarentena. Utilização de materiais reciclados.",
        img: "/assets/tronosImgs/participantes/114.jpg",
      },
      {
        inscription: "115",
        title: "Santo Festivo",
        author: "Ana Rodrigues",
        location: "Lisboa",
        info: "Materiais reciclados.",
        img: "/assets/tronosImgs/participantes/115.JPG",
      },
      {
        inscription: "116",
        title: "Santo António Dona Ajuda",
        author: "Ana Maria Conceição",
        location: "Lisboa",
        info:
          "Santo António e os manjericos.Utilização de materiais reciclados como caixas de cereais, de cartão e tampas.",
        img: "/assets/tronosImgs/participantes/116.JPG",
      },
      {
        inscription: "117",
        title: "Santo António Casamenteiro",
        author: "Leonor Santos",
        location: "Lisboa",
        info:
          "Com o casario como fundo, celebra a tradição de Santo António Casamenteiro. Na calçada lisboeta, os manjericos dao cor com os seus cravos encarnados. Utilização de materiais reciclados como papel de parede, revistas, lã.",
        img: "/assets/tronosImgs/participantes/117.jpg",
      },
      {
        inscription: "118",
        title: "O meu Santo António",
        author: "Beth Carlao",
        location: "Lisboa",
        info: "Materiais reciclados.",
        img: "/assets/tronosImgs/participantes/118.jpg",
      },
      {
        inscription: "119",
        title: "Santo António do trono que ia para o lixo",
        author: "Dona Ajuda",
        location: "Lisboa",
        info:
          "Apanhado a tempo! Utilizou-se materiais como puxadores e madeira.",
        img: "/assets/tronosImgs/participantes/119.jpg",
      },
      {
        inscription: "120",
        title: "Santo António voando no manjerico",
        author: "Paula Naia",
        location: "Lisboa",
        info:
          "Santo António voando no manjerico. Materiais reciclados: cabeleira postiça e pedras decorativas.",
        img: "/assets/tronosImgs/participantes/120.jpg",
      },
      {
        inscription: "121",
        title: "Santo António divulga regras sanitárias",
        author: "Manuela Neto",
        location: "Lisboa",
        info:
          "Santo António divulga regras sanitárias. Utilização de materiais reciclados.",
        img: "/assets/tronosImgs/participantes/121.jpg",
      },
      {
        inscription: "122",
        title: "Santo António de las Flores",
        author: "Odile de Pins Bervialle",
        location: "Lisboa",
        info:
          "Santo António e as Flores. Materiais: caixas de jogos, flores artificiais e materiais reciclados.",
        img: "/assets/tronosImgs/participantes/122.jpg",
      },
      {
        inscription: "123",
        title: "Santo António, Sardinhas e Fado",
        author: '"Costureirinhas Dona Ajuda"',
        location: "Lisboa",
        info:
          "Santo António, Sardinhas e Fado. Materiais: reciclagem de tecidos, embalagens.",
        img: "/assets/tronosImgs/participantes/123.jpg",
      },
      {
        inscription: "124",
        title: "Santo António em roxo",
        author: "Cecília Moreira",
        location: "Lisboa",
        info: "Utilização de materiais reciclados.",
        img: "/assets/tronosImgs/participantes/124.JPG",
      },
      {
        inscription: "125",
        title: "O meu primeiro trono de Santo António",
        author:
          "André Marques Ferreira e Pedro Manuel Machado Carlos Ferreira (9 anos)",
        location: "Alfragide",
        info:
          "Foram utilizadas cápsulas de café usadas para os 12 vasos (o 13º é o manjerico); corpo do Santo António feito do interior de um rolo de papel higiénico, e a cabeça feita com a casca de um ovo. o cinto foi feito com um pedaço de atacador velho. Inspirado nas festas de Santo António de Lisboa, no fado, nos manjericos, nas alcachofras. ",
        img: "/assets/tronosImgs/participantes/125.jpeg",
      },
      {
        inscription: "126",
        title: "Trono da Gratidão",
        author: "Maria Luisa Werle Wolter",
        location: "Cascais",
        info:
          "Inspirado em Portugal e na quarentena representando a fé portuguesa. Feito com cortiça, madeira reciclada, azulejo e os pães de Santo António.",
        img: "/assets/tronosImgs/participantes/126.jpg",
      },
      {
        inscription: "127",
        title: "Santo António é Rei",
        author: "Inês Feliciano",
        location: "Lisboa",
        info:
          "Materiais: Tecido de aproveitamentos; Rolhas de Cortiça; Caricas; Brindes do Bolo Rei com cerca de 40 anos; Restos de rede metálica tirados do lixo; Elementos plásticos reciclados (separadores de Sushi). A Inspiração foi a tradição portuguesa e a reciclagem.",
        img: "/assets/tronosImgs/participantes/127.JPG",
      },
      {
        inscription: "128",
        title: "Um Trono Permanente",
        author: "David Rebelo Vieira",
        location: "Colares",
        info:
          "Base sobre um pequeno armário com os noivos e o santo sobre pequenos cartões formados a cartolina verdes. ",
        img: "/assets/tronosImgs/participantes/128.jpg",
      },
      {
        inscription: "129",
        title: "Santo António, meu padroeiro",
        author: "Carlos Dias Tavares",
        location: "Viana do Castelo",
        info:
          "É um trono simbólico com o Santo feito em terracota. Utilizei caixas e objetos reciclados tal como a caixa antiga de ferramentas. Juntei um vaso com um manjerico e umas velas acesas, bem como umas rosinhas para valorizarem a ornamentação. Materiais: Terracota, rendas, caixa antiga de ferramentas, velas, caso com manjerico, rosas naturais.",
        img: "/assets/tronosImgs/participantes/129.jpg",
      },
      {
        inscription: "130",
        title: "O Que S. António É Para Ti",
        author: "Maria do Carmo Guedes e Sebastião Veloso",
        location: "Lisboa",
        info:
          "Para o primeiro andar, utilizamos sacos azuis do IKEA para fazer do mar no qual S. Antonio dava o seu sermão aos peixes. No segundo andar, celebrámos o S. António casamenteiro, e fomos buscar brinquedos antigos, que decorámos com papel de cozinha (véus). No início colocámos a nós mesmos o desafio de não comprar nada e apenas usar objetos que tivéssemos em casa. Materiais: Malas antigas, sacos do IKEA, Livro, Chapéus-Sardinhas, Bonecos e bonecas antigas, cordel preto, papel de cozinha, óculos, relógios...",
        img: "/assets/tronosImgs/participantes/130.JPG",
      },
      {
        inscription: "131",
        title: "O meu Santo António",
        author: "Maria Cândida Pacheco",
        location: "Coimbra",
        info:
          "Trono feito com caixa de sapatos, filtros de café, cápsulas de café, esponjas da loiça e a imagem de santo António em cerâmica feita pelo autor do trono.",
        img: "/assets/tronosImgs/participantes/131.jpg",
      },
      {
        inscription: "132",
        title: "Santo António Enlatado",
        author: "Sandrina Tomás",
        location: "Caldas da Rainha",
        info:
          "Latas de conserva, rolos de cartão de impressão, restos de EVA e porcelana fria.",
        img: "/assets/tronosImgs/participantes/132.jpg",
      },
      {
        inscription: "133",
        title: "Santo António aos olhos dos jovens alentejanos",
        author: "Grupo de Jovens Paróquia Nossa Senhora da Graça",
        location: "Mora",
        info:
          "O altar é composto por cartão e forrado com papel de cozinha. Os pilares são feitos com papel de jornal, apoiados por cartão das caixas de ovos. As bandeiras são de plástico e coladas na fita dentária. Materiais: Cartão, papel de jornal, cartão tetrapak, fita dentária, sacos de plástico, rolhas de cortiça, tapete de plástico, papel de cozinha, cápsulas café, caixas de ovos, tampa de garrafa.",
        img: "/assets/tronosImgs/participantes/133.jpg",
      },
      {
        inscription: "134",
        title: "Santo António na Sardinha",
        author: "Alexandre Gaudêncio",
        location: "Monte Abrãao",
        info:
          "Cidade de lisboa, simbolos da cidade, sardinhas. Materiais: Ilustração, desenhado em papel reciclado e colado num trono de madeira. A sardinha com o Santo António foi desenhada e feita manualmente.",
        img: "/assets/tronosImgs/participantes/134.jpg",
      },
      {
        inscription: "135",
        title: "Tradições",
        author: "Associação São João de Deus de Lisboa",
        location: "Lisboa",
        info: "Lisboa, azulejos, música e sardinhas.",
        img: "/assets/tronosImgs/participantes/135.jpg",
      },
      {
        inscription: "136",
        title: "Santo Antonio no Alentejo",
        author:
          "Guerreiro e Silveira, Lda. - Alexandra Guerreiro, Patricia Guerreiro e José Silveira",
        location: "Lisboa",
        info:
          "Trono elaborado em diversos materiais e inspirado em diversas representações  de Santo Antonio executados por artesãos nacionais. Materiais utilizados: madeira, tecido impresso manualmente, peças em cerâmica, burel, massas polímeras.",
        img: "/assets/tronosImgs/participantes/136.jpg",
      },
      {
        inscription: "137",
        title: "Trono de Santo António",
        author: "Lucinda Silva",
        location: "Lisboa",
        info: "Reaproveitamento de materiais existentes em casa.",
        img: "/assets/tronosImgs/participantes/137.jpg",
      },
      {
        inscription: "138",
        title: "Trono da Tabernita",
        author: "Tabernita",
        location: "Lisboa",
        info:
          "Inspirado na travessia maritima de Santo António de Marrocos para a Sicilia. Materiais: Cortiça, plástico, papel, casca de árvore, madeira, tecido, metal, água.",
        img: "/assets/tronosImgs/participantes/138.jpg",
      },
      {
        inscription: "139",
        title: "Viva Santo António!",
        author: "Madalena Reis (9 anos)",
        location: "Lisboa",
        info: "Papel crepe, cartão, algodão, tecido e copo reciclado.",
        img: "/assets/tronosImgs/participantes/139.jpg",
      },
      {
        inscription: "140",
        title:
          "Santo António de Lisboa _ Uma viagem de Lisboa para o mundo pelo mar",
        author: "Sara Cavaco, Ani Rodrigues",
        location: "Lisboa",
        info:
          "Materiais: Panos/Tecidos excedentes de máscaras comunitárias, flores de quintal, conchas de mar, papel e caixas de sapatos recicladas, papel prata, pequenas velas pré-existentes no lar e tablet com imagem de Sto António sobre a cidade entre céu e mar.",
        img: "/assets/tronosImgs/participantes/140.jpg",
      },
      {
        inscription: "141",
        title: "Santo António de Lisboa e Portugal",
        author: "Elsa Alípio",
        location: "Lisboa",
        info:
          "Inspiração: pequeno Santo António há muito existente cá em casa; caixas de cartão; rolhas de cortiça; restos de feltro; restos de papel de vários tipos; paus de espetadas; palitos; corda; cola.",
        img: "/assets/tronosImgs/participantes/141.JPG",
      },
      {
        inscription: "142",
        title: "Santas Cores",
        author: "Leonor Gaivoto",
        location: "Lisboa",
        info:
          "É um trono feito com caixas de sapatos usadas, papel de seda, rolos de papel higienico utilizados e plasticina. Inspirada nas cores de varias quadros antigos de Santo António encontrados na internet. ",
        img: "/assets/tronosImgs/participantes/142.jpg",
      },
      {
        inscription: "143",
        title: "Santo António em Flor",
        author: "Marcela Lirio Campo",
        location: "Funchal",
        info:
          "Para a criação deste trono procurei unir duas referências: o meu trabalho com papel e o facto de morar na ilha da Madeira, mais específicamente na freguesia de Santo António. Sendo um Santo tão querido e popular, a sua influência ultrapassa fronteiras. Materiais: Esferovite, papel, tinta acrílica e tecido.",
        img: "/assets/tronosImgs/participantes/143.jpg",
      },
      {
        inscription: "144",
        title: "Querido Santo Antoninho, dá-me lá um manjeriquinho",
        author: "Francisca Nascimento Pires (8 anos)",
        location: "Lisboa",
        info:
          "O manjerico é uma espécie de coroa do S. António. Cartão prensado, k-line, papel, tampas de plástico e tintas de água",
        img: "/assets/tronosImgs/participantes/144.jpg",
      },
      {
        inscription: "145",
        title: "Santo António à Portuguesa",
        author: "Marcela Lirio Campo",
        location: "Funchal",
        info:
          "Para esse trono me inspirei em elementos da cultura Portuguesa como os seus artistas, a gastronomia, o seu património. Materiais: Livros, papel, recortes.",
        img: "/assets/tronosImgs/participantes/145.jpg",
      },
      {
        inscription: "146",
        title: "Sto. António e suas cidades",
        author: "Vitor Simões e Pilar Brásio",
        location: "Penela",
        info:
          "Garrafas de Plástico, papel de seda, cartolinas, caixas de cartão e tecido. Inspirado nas 3 cidades relacionadas com Santo António e as suas tradições",
        img: "/assets/tronosImgs/participantes/146.jpg",
      },
      {
        inscription: "147",
        title: "Pela Janela - À Janela",
        author: "Joana d'Arc Chouriço",
        location: "Lisboa",
        info:
          "Este ano vamos festejar às janelas com o Santo António a olhar por nós lá do seu altar. Dado o momento em que nos encontramos, este ano celebramos as marchas populares à janela e o Santo António não é exceção. Este encontra-se também no seu altar a partilhar: paixão, força, coragem e esperança. ",
        img: "/assets/tronosImgs/participantes/147.png",
      },
      {
        inscription: "148",
        title: "Trono de Santo António",
        author: "Joana d'Arc Chouriço",
        info:
          "Trono Tradicional feito pela autora, com utilização de quadro de Santo António e uma toalha de renda.",
        img: "/assets/tronosImgs/participantes/148.png",
      },
      {
        inscription: "149",
        title: "Celebrando o Santo António sem distanciamento social",
        author: "Leonor de Jesus Gomes (12 anos)",
        location: "Lisboa",
        info:
          "Num tempo de distanciamento social recordamos festas anteriores, em que não havia limite à proximidade. Os materiais usados foram uma caixa de cartão e a revista festaslisboa'19.",
        img: "/assets/tronosImgs/participantes/149.JPG",
      },
      {
        inscription: "150",
        title: "Tronos de Santo António",
        author: "Carmen Moço",
        location: "Setúbal",
        info: "Pedra. Materiais reciclados.",
        img: "/assets/tronosImgs/participantes/150.jpg",
      },
      {
        inscription: "151",
        title: "Tronos de Santo António",
        author: "Carmen Moço",
        location: "Setúbal",
        info: "Cortiça. Materiais reciclados.",
        img: "/assets/tronosImgs/participantes/151.jpg",
      },
      {
        inscription: "152",
        title: "Tronos Sigea",
        author: "Alunos da turma do 4º ano / Tamar Zolondez (10 anos)",
        location: "Estoril",
        info:
          "Para os tronos, os alunos utilizaram materiais recicláveis, como caixas de cartão, caixas de pizza, caixa de sapatos, rolos de papel higiénico, cartolinas, folhas, plantas... Em tempos nunca antes vividos, a realização dos tronos foi sem dúvida um desafio. No entanto, mal a proposta foi lançada, os alunos reagiram com bastante entusiasmo. Assim, os alunos abraçaram o desafio e fizeram os seus tronos completamente sozinhos.",
        img: "/assets/tronosImgs/participantes/152.jpg",
      },
      {
        inscription: "153",
        title: "Tronos Sigea",
        author: "Alunos da turma do 4º ano /Matilde Gaspar (10 anos)",
        location: "Estoril",
        info:
          "Para os tronos, os alunos utilizaram materiais recicláveis, como caixas de cartão, caixas de pizza, caixa de sapatos, rolos de papel higiénico, cartolinas, folhas, plantas... Em tempos nunca antes vividos, a realização dos tronos foi sem dúvida um desafio. No entanto, mal a proposta foi lançada, os alunos reagiram com bastante entusiasmo. Assim, os alunos abraçaram o desafio e fizeram os seus tronos completamente sozinhos.",
        img: "/assets/tronosImgs/participantes/153.jpg",
      },
      {
        inscription: "154",
        title: "Tronos Sigea",
        author: "Alunos da turma do 4º ano / Laura Pinto (10 anos)",
        location: "Estoril",
        info:
          "Para os tronos, os alunos utilizaram materiais recicláveis, como caixas de cartão, caixas de pizza, caixa de sapatos, rolos de papel higiénico, cartolinas, folhas, plantas... Em tempos nunca antes vividos, a realização dos tronos foi sem dúvida um desafio. No entanto, mal a proposta foi lançada, os alunos reagiram com bastante entusiasmo. Assim, os alunos abraçaram o desafio e fizeram os seus tronos completamente sozinhos.",
        img: "/assets/tronosImgs/participantes/154.jpg",
      },
      {
        inscription: "155",
        title: "Santo António e as sardinhas",
        author: "Mário Lopes",
        location: "Arruda dos Vinhos",
        info: "Madeira, papel, cola e manjericos.",
        img: "/assets/tronosImgs/participantes/155.JPG",
      },
      {
        inscription: "156",
        title: "Trono marchante",
        author: "Crianças Da CA A Nossa Casa",
        location: "Lisboa",
        info:
          "Inspiração Marchas Populares de Lisboa e Arraiais. Materiais caixa de ovos e de fraldas, cartão, festão, eva brilhante, palitos de espetadas, papel autocolante.",
        img: "/assets/tronosImgs/participantes/156.jpg",
      },
      {
        inscription: "157",
        title: "O que nos lembra o Santo António",
        author: "Encarnação Jesus",
        location: "Lisboa",
        info:
          "O objectivo era fazer um Trono de Santo António reciclando e reutilizando os materiais que tínhamos em casa. E após um longo período de confinamento, pretendíamos algo simples, depurado do acessório e limitado ao essencial daquilo que para nós seriam as Festas de Lisboa. Os materiais usados foram caixas de cartão, palavras recortadas de uma revista e alguns adereços comprados no Museu de Santo António no Verão de 2019.",
        img: "/assets/tronosImgs/participantes/157.jpg",
      },
      {
        inscription: "158",
        title: "Arraial em confinamento",
        author: "Marisa Ramalhete,  Cátia  Ramalhete e Ana Ramalhete",
        location: "Lisboa",
        info:
          "Cartão, arame, rolhas e tecido servem de suporte a um arraial em confinamento.",
        img: "/assets/tronosImgs/participantes/158.jpg",
      },
      {
        inscription: "160",
        title: "Escadas da fé",
        author: "Tinno Filho",
        location: "Lisboa",
        info:
          "Não importa a dificuldade, há sempre uma escada que nos conduzirá a fé! Sem perder as esperanças. Material: Papel Cartão; Madeira; Plástico; ráfia, cabos informático, Tinta em Spray. Inspiração: Fé, Religião e África.",
        img: "/assets/tronosImgs/participantes/160.jpg",
      },
      {
        inscription: "161",
        title: "Trono da Luz de Santo António",
        author:
          "Olimpia Couto, Anabela Silva, Tatiana Couto (13 anos) e Diana Pinto (13 anos)",
        location: "Trajouce",
        info: "Madeira, cartão, papel, tecido, arame",
        img: "/assets/tronosImgs/participantes/161.jpg",
      },
      {
        inscription: "163",
        title: "Mosteiro de São Vicente de Fora",
        author: "Nazaré Feliciano, Paulo Santos",
        location: "Lisboa",
        info:
          "Santo António ladeado de São João e São Pedro. Os materiais foram os seguintes: Fitas de embrulho, fitas de natal, bilha de barro (a mesma dos anos anteriores) e caixas dos artigos da loja",
        img: "/assets/tronosImgs/participantes/163.jpg",
      },
      {
        inscription: "164",
        title: "Tronos Sigea",
        author: "Alunos da turma do 4º ano /Julia Silveira (10 anos)",
        location: "Estoril",
        info:
          "Para os tronos, os alunos utilizaram materiais recicláveis, como caixas de cartão, caixas de pizza, caixa de sapatos, rolos de papel higiénico, cartolinas, folhas, plantas... Em tempos nunca antes vividos, a realização dos tronos foi sem dúvida um desafio. No entanto, mal a proposta foi lançada, os alunos reagiram com bastante entusiasmo. Assim, os alunos abraçaram o desafio e fizeram os seus tronos completamente sozinhos.",
        img: "/assets/tronosImgs/participantes/164.png",
      },
      {
        inscription: "165",
        title: "Tronos Sigea",
        author: "Alunos da turma do 4º ano /Maria Gomes (10 anos)",
        location: "Estoril",
        info:
          "Para os tronos, os alunos utilizaram materiais recicláveis, como caixas de cartão, caixas de pizza, caixa de sapatos, rolos de papel higiénico, cartolinas, folhas, plantas... Em tempos nunca antes vividos, a realização dos tronos foi sem dúvida um desafio. No entanto, mal a proposta foi lançada, os alunos reagiram com bastante entusiasmo. Assim, os alunos abraçaram o desafio e fizeram os seus tronos completamente sozinhos.",
        img: "/assets/tronosImgs/participantes/165.jpg",
      },
      {
        inscription: "166",
        title: "Tronos Sigea",
        author: "Alunos da turma do 4º ano /Gabriela Ribeiro (10 anos)",
        location: "Estoril",
        info:
          "Para os tronos, os alunos utilizaram materiais recicláveis, como caixas de cartão, caixas de pizza, caixa de sapatos, rolos de papel higiénico, cartolinas, folhas, plantas... Em tempos nunca antes vividos, a realização dos tronos foi sem dúvida um desafio. No entanto, mal a proposta foi lançada, os alunos reagiram com bastante entusiasmo. Assim, os alunos abraçaram o desafio e fizeram os seus tronos completamente sozinhos.",
        img: "/assets/tronosImgs/participantes/166.jpg",
      },
      {
        inscription: "167",
        title: "Tronos Sigea",
        author: "Alunos da turma do 4º ano / Sancha Nunes (10 anos)",
        location: "Estoril",
        info:
          "Para os tronos, os alunos utilizaram materiais recicláveis, como caixas de cartão, caixas de pizza, caixa de sapatos, rolos de papel higiénico, cartolinas, folhas, plantas... Em tempos nunca antes vividos, a realização dos tronos foi sem dúvida um desafio. No entanto, mal a proposta foi lançada, os alunos reagiram com bastante entusiasmo. Assim, os alunos abraçaram o desafio e fizeram os seus tronos completamente sozinhos.",
        img: "/assets/tronosImgs/participantes/167.jpg",
      },
      {
        inscription: "168",
        title: "Trono",
        author: "Família Simões: Hélder, Mónica, Vasco e José",
        location: "Amadora",
        info:
          "O nosso trono foi feito com caixas de cartao e rolos de papel higiénico. Também usamos tintas, cordão e missangas para o terço.",
        img: "/assets/tronosImgs/participantes/168.jpg",
      },
      {
        inscription: "169",
        title: "Mosteiro de São Vicente de Fora",
        author: "Nazaré Feliciano, Paulo Santos e restante equipa",
        location: "Lisboa",
        info:
          "Trono alusivo à passagem de Fernando de Bulhões pelo Mosteiro de São Vicente de Fora. Materiais utilizados: esferovite, papel, colagens e papel de embrulho.",
        img: "/assets/tronosImgs/participantes/169.jpg",
      },
      {
        inscription: "170",
        title: "Trono na Holanda",
        author: "Lúcia ",
        location: "Amsterdão",
        info:
          "Materiais reutilizáveis: azulejo, barros, papel de prata, flores artificiais, conchas, entre outros.",
        img: "/assets/tronosImgs/participantes/170.jpg",
      },
    ],
  },
};

export default tronosData;
