import React from "react";
import { ChevronsLeft, ChevronsRight } from "react-feather";

const TimelineNav = ({ activeItem, prevItems, nextItems }) => {
  return (
    <div className="timelineNavWrapper">
      <div className="timelineNavInnerWrapper">
        <div className="prevItemsWrapper">
          {prevItems
            ? prevItems.map((val, i) => (
                <div key={i} className="prevItem">
                  {val !== undefined ? <h6>{val.year}</h6> : null}
                </div>
              ))
            : null}
        </div>
        <div className="activeItemWrapper">
          <h5>{activeItem.year}</h5>
        </div>
        <div className="nextItemsWrapper">
          {nextItems
            ? nextItems.map((val, i) => (
                <div key={i} className="nextItem">
                  {val !== undefined ? <h6>{val.year}</h6> : null}
                </div>
              ))
            : null}
        </div>
      </div>
      <ChevronsLeft className="leftIcon" />
      <ChevronsRight className="rightIcon" />
    </div>
  );
};

export default TimelineNav;
