const wallData = {
  sacra: {
    name: "Arte Sacra",
    items: [
      {
        name: "Santo António de Roca",
        date: "Séc. XVIII",
        material: "Madeira, tecido",
        dimension: "40 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Peça oferecida por uma pessoa muito especial para mim. Uma das imagens de culto e de prece da minha casa.",
        img: "/assets/wallImgs/artesacra/004.21.jpg",
      },
      {
        name: "Milagre da aparição do Menino Jesus a Santo António de Lisboa",
        date: "Séc. XIX (?)",
        material: "Placa de porcelana jasperware verde e branca",
        dimension: "20 x 14,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/artesacra/010.1.jpg",
      },
      {
        name: "Santo António de Lisboa na sua Real Casa",
        date: "Séc. XX",
        material: "Placa de porcelana",
        dimension: "23 x 12 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/artesacra/010.2.jpg",
      },
      {
        name: "Santo António de Lisboa",
        date: "Séc. XIX",
        material: "Óleo sobre tela",
        dimension: "83 x 63 cm",
        owner: "Coleção Délio Lopes",
        info:
          "Proveniente de Espanha, com a particularidade do Menino Jesus aqui estar representado por Deus.",
        img: "/assets/wallImgs/artesacra/010.06.jpg",
      },
      {
        name: "Santo António da Sé",
        date: "Séc. XIX (?)",
        material: "Barro",
        dimension: "27 x 8,5 cm",
        owner: "Coleção Délio Lopes",
        info:
          "Com algumas faltas, defeitos e repintes, tem necessidade de restauro. Santo trajado com chapéu cardinalício.",
        img: "/assets/wallImgs/artesacra/010.15.jpg",
      },
      {
        name: "Relíquia de Santo António de Lisboa",
        date: "1725",
        material:
          "Documento impresso e manuscrito com tinta ferrogálica e selo branco, datação manuscrita",
        dimension: "34 x 22 cm",
        owner: "Coleção Délio Lopes",
        info:
          "Moldura posterior. Quadro com comprovativo que dá a veracidade à relíquia de Santo António de Lisboa. Contém também outra relíquia de outro Santo ou Santa; S. Joanne Francisco de Chantal.",
        img: "/assets/wallImgs/artesacra/010.31.1.jpg",
      },
      {
        name: "Relíquia de Santo António de Lisboa",
        date: "1725",
        material:
          "Documento impresso e manuscrito com tinta ferrogálica e selo branco, datação manuscrita",
        dimension: "34 x 22 cm",
        owner: "Coleção Délio Lopes",
        info:
          "Moldura posterior. Quadro com comprovativo que dá a veracidade à relíquia de Santo António de Lisboa. Contém também outra relíquia de outro Santo ou Santa; S. Joanne Francisco de Chantal.",
        img: "/assets/wallImgs/artesacra/010.31.2.jpg",
      },
      {
        name: "Ex-voto de Santo António de Lisboa",
        date: "1858",
        material: "Óleo sobre madeira",
        dimension: "18 x 23 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/artesacra/010.33.jpg",
      },
      {
        name: "Ex-voto de Santo António de Lisboa",
        date: "1855",
        material: "Óleo sobre madeira",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/artesacra/010.34.jpg",
      },
      {
        name: "Trono de Santo António",
        date: "Séc. XX",
        material: "Madeira e papel",
        owner: "Coleção Natália Correia Guedes",
        img: "/assets/wallImgs/artesacra/011.1.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "1936",
        material: "Barro",
        dimension: "12 cm",
        owner: "Coleção Manuel Ribeiro de Faria",
        info:
          "Pertenceu a Beatriz Cremilde de Faria; veio de Lourenço Marques, Moçambique, onde parece ter existido um grupo de senhoras, de carácter cultural, que se designava de Laurentinas.",
        img: "/assets/wallImgs/artesacra/012.01.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Ano de integração: 2008",
        material: "Fibra de vidro",
        dimension: "60 cm",
        owner: "Coleção Manuel Ribeiro de Faria",
        img: "/assets/wallImgs/artesacra/012.2.jpg",
      },
      {
        name: "Santo António com o Menino",
        material: "Madeira Estampada",
        dimension: "30 cm",
        owner: "Coleção Manuel Ribeiro de Faria",
        img: "/assets/wallImgs/artesacra/012.03.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVIII",
        material: "Madeira com as vestes bordadas a ouro e uma palma de prata",
        dimension: "22 cm",
        owner:
          "Pertence à Senhora D. Cristina Pereira de Lima, em cuja família a imagem está há mais de 200 anos.",
        img: "/assets/wallImgs/artesacra/012.8.jpg",
      },
      {
        name: "Medalha Santo António",
        material: "Pequeno envelope de napa fina e metal prateado",
        dimension: "4 x 3 cm",
        owner:
          "Pertencia a Beatriz Cremilde de Faria, que nunca se separava dela.",
        img: "/assets/wallImgs/artesacra/012.09.jpg",
      },
      {
        name: "Santo António de Lisboa",
        date: "Séc. XVII",
        material: "Marfim, madeira de ébano e pratas",
        dimension: "30 cm",
        owner: "Coleção Rita Gonçalves",
        img: "/assets/wallImgs/artesacra/014.1.jpg",
      },
      {
        name: "Aparição do Menino Jesus a Santo António de Lisboa",
        date: "Séc. XVII",
        material: "Óleo sobre cobre",
        dimension: "45 x 40 cm",
        owner: "Coleção Rita Gonçalves",
        img: "/assets/wallImgs/artesacra/014.3.jpg",
      },
      {
        name: "Ex-voto de Santo António de Lisboa",
        material: "Pintura sobre madeira",
        dimension: "35 x 27 cm",
        owner: "Coleção Rita Gonçalves",
        info:
          "A obra apresenta a seguinte inscrição; M. que fes Santo Antonio a Isabel Reis Livrando seu Irmão do Naufragar a embarcação n Ano 1870.",
        img: "/assets/wallImgs/artesacra/014.4.jpg",
      },
      {
        name: "Aparição do Menino Jesus a Santo António de Lisboa",
        material: "Barro",
        dimension: "50 x 40 cm",
        owner: "Coleção Rita Gonçalves",
        img: "/assets/wallImgs/artesacra/014.5.jpg",
      },
      {
        name: "Santo António de Lisboa",
        date: "Séc. XVII",
        material: "Madeira",
        dimension: "65 cm",
        owner: "Coleção Rita Gonçalves",
        img: "/assets/wallImgs/artesacra/014.6.jpg",
      },
      {
        name: "Santo António de Lisboa",
        material: "Madeira e feltro",
        dimension: "36 cm",
        owner: "Coleção Rita Gonçalves",
        img: "/assets/wallImgs/artesacra/014.8.jpg",
      },
      {
        name: "Santo António",
        material: "Madeira policromada, cabeça e pés em Marfim.",
        dimension: "29 cm",
        owner: "Coleção Maria Antónia Braga",
        info:
          "Nota: As mãos foram feitas e postas posteriormente. Está há várias gerações na família da avó paterna da colecionadora. Pertencia a um oratório cujas imagens, por vicissitudes de partilhas, foram dispersas.",
        img: "/assets/wallImgs/artesacra/016.01.jpg",
      },
      {
        name: "Santo António",
        material: "Marfim.",
        dimension: "14 cm",
        owner: "Coleção Maria Antónia Braga",
        img: "/assets/wallImgs/artesacra/016.06.jpg",
      },
      {
        name: "Trono de Santo António",
        material: "Madeira.",
        dimension: "98 cm",
        owner: "Coleção Maria Antónia Braga",
        img: "/assets/wallImgs/artesacra/016.07.jpg",
      },
      {
        name: "Trono de Santo António",
        date: "Início do Séc. XX",
        material: "Marfim e prata.",
        dimension: "30 cm",
        owner: "Coleção Maria Antónia Braga",
        img: "/assets/wallImgs/artesacra/016.08.jpg",
      },
      {
        name: "Relíquia de Santo António Com certificado de autenticidade.",
        material: "Vidro, Lacre, Metal, Tecido",
        dimension: "2,5 cm",
        owner: "Coleção João Durão",
        info: "Relíquia de 1º grau com certificado de autenticidade.",
        img: "/assets/wallImgs/artesacra/017.08.1.jpg",
      },
      {
        name: "Relíquia de Santo António Com certificado de autenticidade.",
        material: "Vidro, Lacre, Metal, Tecido",
        dimension: "2,5 cm",
        owner: "Coleção João Durão",
        info: "Relíquia de 1º grau com certificado de autenticidade.",
        img: "/assets/wallImgs/artesacra/017.08.2.jpg",
      },
      {
        name: "Santo António de Lisboa",
        material: "Madeira",
        dimension: "23 cm",
        owner: "Coleção Jaime Manuel Silva",
        img: "/assets/wallImgs/artesacra/018.3.jpg",
      },
      {
        name: "Aparição do Menino a Santo António de Lisboa",
        material: "Terracota",
        dimension: "25 cm",
        owner: "Coleção Jaime Manuel Silva",
        img: "/assets/wallImgs/artesacra/018.4.jpg",
      },
      {
        name: "Santo António de Lisboa",
        material: "Roca de madeira",
        dimension: "40 cm",
        owner: "Coleção Jaime Manuel Silva",
        img: "/assets/wallImgs/artesacra/018.5.jpg",
      },
      {
        name: "Santo António de Lisboa",
        material: "Madeira",
        dimension: "24 cm",
        owner: "Coleção Jaime Manuel Silva",
        img: "/assets/wallImgs/artesacra/018.6.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVIII",
        material: "Óleo sobre tela",
        dimension: "64 x 52 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 114 | Lote 888",
        img: "/assets/wallImgs/artesacra/019.001.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVIII (2ª metade)",
        material:
          "Escultura em madeira policromada. Base em madeira entalhada e dourada.",
        dimension: "23,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 116 | Lote 131",
        img: "/assets/wallImgs/artesacra/019.002.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVIII",
        material:
          "Concha em madrepérola gravada e fundos da gravação escurecidos",
        dimension: "11,5 x 11,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 118 | Lote 301",
        img: "/assets/wallImgs/artesacra/019.004.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVIII",
        material: "Escultura em marfim",
        dimension: "10 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 118 | Lote 322",
        img: "/assets/wallImgs/artesacra/019.005.jpg",
      },
      {
        name: "Santo António com saco de pão",
        date: "Séc. XVII/XVIII",
        material: "Escultura em pedra calcária policromada",
        dimension: "70 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 118 | Lote 342",
        img: "/assets/wallImgs/artesacra/019.006.jpg",
      },
      {
        name: "Santo António",
        date: "Séc. XVII/XVIII",
        material: "Óleo sobre tela",
        dimension: "128 x 92 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 118 | Lote 357a",
        img: "/assets/wallImgs/artesacra/019.009.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVIII/XIX",
        material: "Óleo sobre tela",
        dimension: "77 x 55 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 119 | Lote 783",
        img: "/assets/wallImgs/artesacra/019.010.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVIII",
        material:
          "Escultura em marfim policromada, maquineta de suspensão em prata",
        dimension: "3,5 (escultura) cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 120 | Lote 412",
        info:
          "Um exemplar semelhante integrou a exposição A Expansão Portuguesa e a Arte do Marfim, na Fundação Calouste Gulbenkian, Lisboa, em 1991, encontrando-se representado no respetivo catálogo, p. 171, nº 501.",
        img: "/assets/wallImgs/artesacra/019.011.jpg",
      },
      {
        name:
          "Santo António Militar, Santana ensinando Nossa Senhora a ler e São José",
        date: "Séc. XVIII",
        material: "Três pinturas sobre pergaminho recortado e rendilhado",
        dimension: "11,65 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 123 | Lote 470b",
        img: "/assets/wallImgs/artesacra/019.014.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVII (1ª metade)",
        material: "Escultura em marfim com pinturas, cruz em prata",
        dimension: "20 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 125 | Lote 394",
        info:
          "Integrou a Exposição Antoniana conforme etiqueta colada no fundo. Exemplar semelhante integrou a exposição A Expansão Portuguesa e a Arte do Marfim, na Fundação Calouste Gulbenkian, Lisboa, em 1991, encontrando-se representado no respetivo catálogo, p. 165, nº 476.",
        img: "/assets/wallImgs/artesacra/019.017.jpg",
      },
      {
        name: "Santo António e Santo Bispo",
        date: "Séc. XIX (1ª metade)",
        material: "Par de bandeiras de procissão sobre tela",
        dimension: "86 x 67 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 126 | Lote 853",
        img: "/assets/wallImgs/artesacra/019.022.jpg",
      },
      {
        name: "Visão de Santo António",
        date: "Séc. XVIII",
        material: "Óleo sobre porta de oratório",
        dimension: "62 x 35,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 134 | Lote 493",
        img: "/assets/wallImgs/artesacra/019.029.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVI",
        material: "Escultura em pedra de Ançã",
        dimension: "78 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 139 | Lote 557",
        img: "/assets/wallImgs/artesacra/019.035.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII",
        material: "Óleo sobre cobre",
        dimension: "18,5 x 15,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 139 | Lote 578",
        img: "/assets/wallImgs/artesacra/019.037.jpg",
      },
      {
        name: "Sermão de Santo António aos peixes",
        date: "Séc. XVIII/XIX",
        material: "Óleo sobre tela",
        dimension: "67,5 x 50,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 140 | Lote 577",
        img: "/assets/wallImgs/artesacra/019.040.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII",
        material: "Escultura em teca e marfim. Resplendor em prata.",
        dimension: "21 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 141| Lote 415",
        info:
          "Exemplares semelhantes integraram a exposição A Expansão Portuguesa e a Arte do Marfim, na Fundação Calouste Gulbenkian, Lisboa, em 1991, encontrando-se representado no respetivo catálogo, pp. 168-170.",
        img: "/assets/wallImgs/artesacra/019.041.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVII",
        material: "Escultura em madeira policromada",
        dimension: "60 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 143 | Lote 688",
        img: "/assets/wallImgs/artesacra/019.046.jpg",
      },
      {
        name: "Caixa de esmolas Oferta a Santo António",
        date: "Séc. XIX",
        material: "Madeira pintada",
        dimension: "42 x 28 x 26 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 147 | Lote 628",
        img: "/assets/wallImgs/artesacra/019.048.jpg",
      },
      {
        name: "Oratório",
        date: "Séc. XVIII",
        material:
          "Madeira entalhada e policromada, portas com interior pintado",
        dimension: "95 x 70 x 29 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 149 | Lote 599",
        img: "/assets/wallImgs/artesacra/019.052.jpg",
      },
      {
        name: "Santo António",
        date: "Séc. XX",
        material: "Escultura em granito",
        dimension: "50 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 150 | Lote 1041",
        img: "/assets/wallImgs/artesacra/019.054.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVII",
        material: "Escultura em marfim base com cenas da vida de Santo António",
        dimension: "9 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 151 | Lote 814",
        info:
          "Exemplar semelhante integrou a exposição A Expansão Portuguesa e a Arte do Marfim, na Fundação Calouste Gulbenkian, Lisboa, em 1991, encontrando-se representado no respetivo catálogo, p. 165, nº 477.",
        img: "/assets/wallImgs/artesacra/019.055.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVII",
        material:
          "Escultura em marfim policromado, três resplendores em ouro, um cravejado com prata com diamantes e rubis, vestes de tecido, cinto decorado com pérolas naturais, base em prata vazada, marca de ourives VILLAÇA (1861-1881)",
        dimension: "14 cm (Santo António)",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 151 | Lote 823",
        info:
          "vd. ALMEIDA, Fernando Moitinho de - Inventário de Marcas de Pratas Portuguesas e Brasileiras (Século XV e 1887), IN-CM, 1995, P538.",
        img: "/assets/wallImgs/artesacra/019.057.jpg",
      },
      {
        name: "Santo António com Menino e Nossa Senhora com o Menino",
        date: "Séc. XVII",
        material:
          "Medalhão em alabastro esculpido, policromado e dourado em ambas as faces. Moldura em prata relevada de baixo teor",
        dimension: "3,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 151 | Lote 841",
        img: "/assets/wallImgs/artesacra/019.059.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVII",
        material:
          "Escultura em madeira, cabeça, mãos, livro e Menino Jesus em marfim esculpido",
        dimension: "33 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 151 | Lote 852",
        img: "/assets/wallImgs/artesacra/019.060.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII/ XIX",
        material:
          "Escultura em madeira policromada e dourada. Resplendores e cruz em prata, peanha em madeira entalhada e dourada.",
        dimension: "42 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 156 | Lote 129",
        img: "/assets/wallImgs/artesacra/019.073.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII (3º/4º quartel)",
        material:
          "Painel de vinte e três azulejos parcialmente recortados, decoração a azul",
        dimension: "60 x 100 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 157 | Lote 143",
        img: "/assets/wallImgs/artesacra/019.074.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII",
        material: "Óleo sobre cobre",
        dimension: "22 x 17 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 159 | Lote 743",
        img: "/assets/wallImgs/artesacra/019.077.jpg",
      },
      {
        name: "São Paulo e Santo António com o Menino Jesus",
        material: "Óleo sobre madeira",
        dimension: "64 x 87 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 161 | Lote 312",
        img: "/assets/wallImgs/artesacra/019.080.jpg",
      },
      {
        name: "Pia de água benta de suspensão",
        date: "Séc. XVIII (1ª metade)",
        dimension: "37 x 18 x 11,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 166 | Lote 157",
        info:
          "Decoração a azul e vinoso, espaldar relevado Santo António entre arco com colunas encimado por Querubim, caldeira com inscrição S. ANto. Exemplar semelhante encontra-se representado em MONCADA, Miguel Cabral de - Faiança Portuguesa, sécs. XVI a XVIII. Lisboa: SCRIBE, 2008, p. 137, nº 154.",
        img: "/assets/wallImgs/artesacra/019.091.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVIII",
        material: "Escultura em madeira policromada e dourada",
        dimension: "32,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 169 | Lote 874",
        img: "/assets/wallImgs/artesacra/019.096.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVI",
        material: "Óleo sobre madeira",
        dimension: "32,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 169 | Lote 954",
        img: "/assets/wallImgs/artesacra/019.102.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVII/XVIII",
        material: "Óleo sobre tela",
        dimension: "107 x 85 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 169 | Lote 974",
        img: "/assets/wallImgs/artesacra/019.104.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII",
        material:
          "Escultura em madeira policromada e dourada. Resplendor e cruz em prata",
        dimension: "23 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 171 | Lote 566",
        img: "/assets/wallImgs/artesacra/019.109.jpg",
      },
      {
        name: "Ex-voto Mercê que Fez Santo António",
        date: "Séc. XVIII",
        material: "Óleo sobre madeira",
        dimension: "15 x 24 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 174 | Lote 546",
        info:
          "Inscrição: Merce q fes Sto Antº a Caerª de S Ieronimo por rogaruias de Seu Pay q fazia ao dº de a livrar de hum mal deconvolçoes y de q esteve em perigo de vida desconfiada dos Medicos e por entreceção do dº Sto e rogariuas de seu pay ficou livre do dº mal anno de 1766",
        img: "/assets/wallImgs/artesacra/019.110.jpg",
      },
      {
        name: "Par de portas de oratório",
        date: "Séc. XVII/XVIII",
        material: "Madeira pintada, decoração policromada",
        dimension: "161 x 79 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 174 | Lote 555",
        info:
          "Representação de São Pedro, São Paulo, São Francisco de Assis, Santo António, São Miguel e São Gabriel",
        img: "/assets/wallImgs/artesacra/019.111.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII (3º quartel)",
        material:
          "Escultura em ébano. Base esculpida, cabeça, pés, Menino sobre livro e aplicações da base em marfim esculpido",
        dimension: "26 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 174 | Lote 717",
        info:
          "São Francisco de Borja e Santa Rita de Cássia semelhantes integraram a exposição A Expansão Portuguesa e a Arte do Marfim, organizada pela Fundação Calouste Gulbenkian, Lisboa, 1991, encontrando-se reproduzidos no respetivo catálogo. Lisboa: Fundação Calouste Gulbenkian, 1991, pp. 183 e 186, nºs 545 e 554.",
        img: "/assets/wallImgs/artesacra/019.112.jpg",
      },
      {
        name: "Aparição do Menino Jesus a Santo António",
        date: "Séc. XVII",
        material: "Retábulo em madeira entalhada, pintada e dourada",
        dimension: "52,2 x 65 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 179 | Lote 289",
        img: "/assets/wallImgs/artesacra/019.117.jpg",
      },
      {
        name: "São Francisco de Assis e Santo António",
        date: "Séc. XVII",
        material:
          "Par de retábulos em madeira entalhada, policromada e dourada",
        dimension: "53,5 x 24,5 x 11 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 184 | Lote 505",
        img: "/assets/wallImgs/artesacra/019.130.jpg",
      },
      {
        name: "Ubiquidade de Santo António",
        date: "Séc. XVII (2ª metade)",
        material: "Óleo sobre cobre",
        dimension: "57 x 68,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 184 | Lote 514",
        info:
          "Realizada a partir de uma gravura reproduzindo a pintura original de Willem Van Herp (c.1614-1677).",
        img: "/assets/wallImgs/artesacra/019.131.jpg",
      },
      {
        name: "Crucifixo",
        date: "Séc. XIX",
        material: "Madeira de oliveira",
        dimension: "65,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 188 | Lote 334",
        info:
          "Frente revestida a placas de madrepérola relevada e gravada com fundos escurecidos Cristo Crucificado, Nossa Senhora das Dores, Quatro Evangelistas, São João Baptista, Santo António com o Menino Jesus e símbolos da Ordem de São Francisco",
        img: "/assets/wallImgs/artesacra/019.135.jpg",
      },
      {
        name: "Santo António",
        date: "Séc. XVIII",
        material: "Óleo sobre tela",
        dimension: "39 x 34 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 188 | Lote 646",
        img: "/assets/wallImgs/artesacra/019.139.jpg",
      },
      {
        name: "Relicário Santo António com o Menino",
        date: "Séc. XVIII/XIX",
        material:
          "Escultura em osso. Decoração policromada, caixa em folha de metal e vidro.",
        dimension: "5 x 2,5 x 1,5 cm (total)",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 188 | Lote 773",
        img: "/assets/wallImgs/artesacra/019.140.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII (2ª metade)",
        material: "Óleo sobre tela",
        dimension: "22 x 18,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 194 | Lote 736",
        img: "/assets/wallImgs/artesacra/019.151.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII",
        material:
          "Escultura em alabastro parcialmente dourado. Base envolta em terracota relevada, resplendor em prata",
        dimension: "(total) 60 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 194 | Lote 749",
        img: "/assets/wallImgs/artesacra/019.152.jpg",
      },
      {
        name: "Santo António com o Menino Jesus e saco de pão",
        date: "Séc. XVIII (2ª metade)",
        material: "Escultura em madeira policromada, olhos de vidro.",
        dimension: "70 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 195 | Lote 243",
        img: "/assets/wallImgs/artesacra/019.155.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII/XIX",
        material:
          "Óleo sobre madeira recortada. Bordo simulando moldura em madeira entalhada",
        dimension: "75 x 34,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 195 | Lote 251",
        info:
          "Verso puncionado APL e com inscrição KUNSTMAUER VON LONEA VARENA (?) e datado de 1804",
        img: "/assets/wallImgs/artesacra/019.156.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVII",
        material: "Óleo sobre madeira",
        dimension: "43,5 x 25,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 195 | Lote 260",
        img: "/assets/wallImgs/artesacra/019.157.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII (2ª metade)",
        material: "Óleo sobre tela",
        dimension: "42 x 34 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 195 | Lote 262",
        img: "/assets/wallImgs/artesacra/019.158.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVII",
        material: "Escultura em marfim",
        dimension: "21 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 195 | Lote 556",
        img: "/assets/wallImgs/artesacra/019.161.jpg",
      },
      {
        name:
          "Nossa Senhora e Menino Jesus coroando Santo António com uma coroa de flores",
        date: "Séc. XVIII",
        material: "Óleo sobre tela",
        dimension: "60 x 45 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 197 | Lote 622",
        img: "/assets/wallImgs/artesacra/019.172.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XX (meados)",
        material: "Escultura em ouro, decoração cinzelada",
        dimension: "5 cm; Peso:48,8 g.",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 198 | Lote 688",
        img: "/assets/wallImgs/artesacra/019.177.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII",
        material: "Escultura em madeira, decoração policromada e dourada",
        dimension: "Escultura 96,5 cm; total 130 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 200 | Lote 100",
        img: "/assets/wallImgs/artesacra/019.178.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        date: "Séc. XVIII/XIX",
        material: "Óleo sobre cobre",
        dimension: "23 x 18 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 200 | Lote 264",
        img: "/assets/wallImgs/artesacra/019.179.jpg",
      },
      {
        name: "São Francisco de Assis e Santo António de Lisboa",
        date: "Séc. XVII/XVIII",
        material:
          "Par de esculturas em teca policromada, dourada e prateada. Cabeças em marfim esculpido.",
        dimension: "31 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 200 | Lote 511",
        img: "/assets/wallImgs/artesacra/019.180.jpg",
      },
      {
        name: "Relíquia Santo António",
        material: "Prata, tecido, linhas, papel, cera.",
        dimension: "3 x 2 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesacra/021.13.jpg",
      },
      {
        name: "Santo António",
        material: "Plástico duro",
        dimension: "7 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesacra/021.36.jpg",
      },
      {
        name: "Santo António do Espírito Santo",
        date: "Fins do Séc. XVIII / início do Séc. XIX",
        material: "Madeira estofada e policromada, resplendores em prata",
        dimension: "39 x 17 x 11cm",
        owner: "Coleção particular",
        info:
          "Está na mesma família desde fins do século XVIII/princípios de XIX. Resistiu à explosão do castelo de Almeida que ocorreu a 26 de agosto de 1810, mantendo-se sempre na família.",
        img: "/assets/wallImgs/artesacra/027.01.jpg",
      },
      {
        name: "Santo António de Pádua ou de Lisboa",
        date: "Séc. XVIII (c.1770-1780)",
        material: "Aguarela sobre seda (aplicado sobre tela)",
        dimension: "84,3 x 53,3 cm",
        owner: "Coleção São Roque Antiguidades",
        img: "/assets/wallImgs/artesacra/029.01.jpg",
      },
      {
        name: "Toni Malau - Santo António do Congo",
        date: "Séc. XVIII",
        material: "Marfim",
        dimension: "7,8 cm",
        owner: "Coleção São Roque Antiguidades",
        img: "/assets/wallImgs/artesacra/029.02.jpg",
      },
      {
        name: "Santo António com o Menino",
        date: "Séc. XVII",
        material: "Marfim",
        dimension: "Altura 17 cm",
        owner: "Coleção São Roque Antiguidades",
        img: "/assets/wallImgs/artesacra/029.03.jpg",
      },
    ],
  },
  decorativas: {
    name: "Artes Decorativas",
    items: [
      {
        name: "Prato com milagre de Santo António aos Peixes",
        date: "1895",
        author: "Fábrica do Desterro, Lisboa",
        material: "Porcelana vidrada e estampada a negro",
        dimension: "22,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/decorativascontemp/010.12.jpg",
      },
      {
        name: "Prato de Santo António com o Menino",
        date: "1895",
        author: "Loiça de Coimbra",
        material: "Porcelana vidrada e pintada à mão",
        dimension: "21 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/decorativascontemp/010.13.jpg",
      },
      {
        name: "Santo António de Lisboa",
        author: "Autor Desconhecido",
        material: "Plástico",
        dimension: "10 cm",
        owner: "Coleção Délio Lopes",
        info:
          "Esta figura de Santo António está sobre o Mundo, que não se vê, e Portugal encontra se nas traseiras. Provém da minha bisavó, sendo mais uma peça afetiva do que valiosa em termos monetários.",
        img: "/assets/wallImgs/decorativascontemp/010.23.jpg",
      },
      {
        name: "Sermão de Santo António aos Peixes",
        date: "Séc. XIX - XX (?)",
        author: "Marca A. Industrial Decorativa de Coimbra, nº 302",
        material: "Barro",
        dimension: "31 x 33 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/decorativascontemp/010.27.jpg",
      },
      {
        name: "Jarro com Santo António de Lisboa e São Vicente",
        author: "Autor Desconhecido",
        material: "Faiança",
        dimension: "38 cm",
        owner: "Coleção Rita Gonçalves",
        info:
          "A obra apresenta a seguinte inscrição: Oh! Que dois Santos Rapazes; Se com um se zanga a gente Vem o outro e faz as pazes (alusivo às festas de Lisboa, Quadra de Frederico de Brito, 1934)",
        img: "/assets/wallImgs/decorativascontemp/014.7.jpg",
      },
      {
        name: "Copo das Festividades de Santo António",
        date: "Ano de integração: 2019",
        author: "Belém, Pará",
        material: "Faiança",
        owner: "Coleção Maria Adelina Amorim",
        info:
          "Copo das Festividades de Santo António em Belém do Pará, oferecido em 2019 durante o arraial de Santo António, paróquia de Santo António de Lisboa, Belém do Pará, Brasil.",
        img: "/assets/wallImgs/decorativascontemp/015.3.jpg",
      },
      {
        name: "Pequena Pia de Água Benta",
        author: "Origem Alemã",
        material: "Metal",
        owner: "Coleção Maria Antónia Braga",
        img: "/assets/wallImgs/decorativascontemp/016.11.jpg",
      },
      {
        name: "Prato Sétimo Centenário de Santo António",
        author: "Loiça de Coimbra",
        material: "Faiança",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/decorativascontemp/017.01.jpg",
      },
      {
        name: "Santo António",
        date: "1933",
        author: "Carlos Pinto Ramos (1905-19??)",
        material: "Aguarela sobre papel",
        dimension: "32 x 24,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 122 | Lote 290",
        info:
          "Inscrição manuscrita estudo para o Santo António quadro em azulejo feito na Fábrica de Loiça de Sacavém",
        img: "/assets/wallImgs/decorativascontemp/019.012.jpg",
      },
      {
        name: "Figura masculina com imagem de Santo António",
        date: "Séc. XIX",
        author: "Escola Portuguesa",
        material: "Aguarela sobre papel",
        dimension: "16,5 x 11,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 128 | Lote 625",
        img: "/assets/wallImgs/decorativascontemp/019.025.jpg",
      },
      {
        name: "Santo António",
        date: "1964",
        author: "António Lino (1914-1996)",
        material: "Técnica mista sobre papel",
        dimension: "50 x 33,5 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 133 | Lote 376",
        img: "/assets/wallImgs/decorativascontemp/019.027.jpg",
      },
      {
        name: "Santo António",
        author: "Francesco Bartolozzi (1728-1815)",
        material:
          "Gravura a sanguínea sobre papel segundo original de Annibale Carracci",
        dimension: "43 x 32 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 153 | Lote 441",
        img: "/assets/wallImgs/decorativascontemp/019.066.jpg",
      },
      {
        name: "Par de camas",
        author: "D. José (1750-1777)",
        material: "Madeira pintada e dourada",
        dimension: "143 x 198 x 107 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 179 | Lote 292",
        img: "/assets/wallImgs/decorativascontemp/019.118.jpg",
      },
      {
        name: "Santo António",
        author: "PAULO FERREIRA (1911-1999)",
        material: "técnica mista sobre papel",
        dimension: "47 x 31 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 179 | Lote 325",
        img: "/assets/wallImgs/decorativascontemp/019.119.jpg",
      },
      {
        name: "Santo António com o Menino Jesus",
        author: "Rafael Bordalo Pinheiro (1846-1905)",
        material:
          "Escultura em terracota das Caldas. Decoração policromada, resplendor em prata relevada",
        dimension: "49 cm",
        owner: "Cabral Moncada Leilões | Luís Sousa",
        auction: "Leilão 197 | Lote 210",
        info:
          "Assinada das Caldas e datada de 1901, marcada da Fábrica de Faianças de Rafael Bordalo Pinheiro, resplendor sem marcas ao abrigo do Decreto-Lei n.º 120/2017, de 15 de Setembro - art. 2º, nº 2, alínea c); vd. ISIDRO, Sónia - Dicionário de Marcas de Faiança e Porcelana Portuguesas. Lisboa: Estar Editora, 1996, p. 66, marca nº 276.",
        img: "/assets/wallImgs/decorativascontemp/019.168.jpg",
      },
      {
        name: "Caneca Santo António de Lisboa Fábrica de Sacavém",
        material: "Faiança estampada",
        dimension: "6 x 7 cm de diâmetro",
        owner: "Coleção Diácono António Machado",
        info:
          "Esta peça foi deixada por uma senhora que já faleceu, pertencia à sua mãe. Deverá ter mais de 100 anos.",
        img: "/assets/wallImgs/decorativascontemp/020.1.jpg",
      },
      {
        name: "Prato Sermão de Santo António aos Peixes",
        author: "Fábrica de Loiça de Sacavém",
        material: "Faiança",
        dimension: "6 x 7 cm de diâmetro",
        owner: "Coleção Anita Russo",
        img: "/assets/wallImgs/decorativascontemp/022.01.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Bronze",
        dimension: "20 x 9 cm",
        owner: "Coleção Jorge Rocha",
        img: "/assets/wallImgs/decorativascontemp/026.03.jpg",
      },
    ],
  },
  pagelas_registos: {
    name: "Pagelas e Registos",
    items: [
      {
        name: "Registo com Imagem de Santo António de Lisboa e o Menino",
        author: "Autor Desconhecido",
        material: "Papel, cartão e tecido",
        dimension: "17 x 11 cm",
        owner: "Coleção Délio Lopes",
        info:
          "Informação sobre a impressão em papel de Santo António de Lisboa, com a seguinte informação abaixo da Pagela: Na loja de Mathias Ribr.º Rua da Padaria. Este registo foi intervencionado pela Eva Armindo, entendida em restauros de tecidos no Seixal, nas traseiras foi encontrado um papel com inscrições.",
        img: "/assets/wallImgs/pagelasregistos/010.3.1.jpg",
      },
      {
        name: "Registo com Imagem de Santo António de Lisboa e o Menino",
        author: "Autor Desconhecido",
        material: "Papel, cartão e tecido",
        dimension: "17 x 11 cm",
        owner: "Coleção Délio Lopes",
        info:
          "Informação sobre a impressão em papel de Santo António de Lisboa, com a seguinte informação abaixo da Pagela: Na loja de Mathias Ribr.º Rua da Padaria. Este registo foi intervencionado pela Eva Armindo, entendida em restauros de tecidos no Seixal, nas traseiras foi encontrado um papel com inscrições.",
        img: "/assets/wallImgs/pagelasregistos/010.3.2.jpg",
      },
      {
        name:
          "Bentinho de lapela em forma de coração com inscrição de Santo António dos Arcos",
        author: "Autor Desconhecido",
        material:
          "Cartão, tecido, papel, lantejoulas, canutilho e plástico que serve como proteção da estampa",
        dimension: "7 x 6,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.07.jpg",
      },
      {
        name: "Bentinho de lapela com a inscrição de Santo António dos Arcos",
        author: "Autor Desconhecido",
        material:
          "Cartão, papel, flores de platina tipo metálica, lantejoulas, canutilho e plástico que serve como proteção da estampa",
        dimension: "8,5 x 6 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.08.jpg",
      },
      {
        name: "Pagela S. Antoine de Pad. le 13",
        author: "Autor Desconhecido",
        material: "Pergaminho",
        dimension: "8,5 x 5,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.53.jpg",
      },
      {
        name: "Pagela S. Antoine de Pad. le 13",
        author: "Autor Desconhecido",
        material: "Pergaminho",
        dimension: "8 x 4,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.54.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Ed. Bouasse Lebel Paris",
        material: "Plástico Forte",
        dimension: "11 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.55.jpg",
      },
      {
        name: "Pagela S. Antonio de Padua",
        author: "Bouasse Lebel - Edit Imp. 5500 Paris 29 Rue St Sulpice",
        material: "Plástico Forte",
        dimension: "11 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.56.jpg",
      },
      {
        name:
          "Pagela Saint Antoine, enfant de choeur, à la Cathedrale de Lisbonne, chasse le démon, en traçant une croix qui reste gravée dans le marbre",
        author: "Société de St Augustín",
        material: "Papel",
        dimension: "11,5 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.57.jpg",
      },
      {
        name:
          "Pagela Baptême de Ferdinand de Bouillon qui será plus tard Saint Antoine de Padoue",
        author: "Ed. Notre - Dame D´Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,5 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.58.jpg",
      },
      {
        name:
          "Pagela Ferdinand de Bouillon, chanoine de S. Augustin reçoit l´habit de frère Mineur, et le nom d´Antoine",
        author: "Ed. Notre - Dame D´Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,5 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.59.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Ed. Boumard et Fils, Paris",
        material: "Papel",
        dimension: "12,5 x 8 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.60.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Ed. Bonamy, Poitiers",
        material: "Papel",
        dimension: "12,5 x 8,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.61.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Ed. L. Turgis et Fils, Paris",
        material: "Papel, canivet dentelle",
        dimension: "10 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.62.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Autor Desconhecido",
        material: "Papel, canivet dentelle",
        dimension: "11 x 7,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.63.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Autor Desconhecido",
        material: "Papel, canivet dentelle",
        dimension: "11 x 7,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.64.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Ed. Lamarche, Pavia",
        material: "Papel, canivet dentelle",
        dimension: "11 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.65.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Ed. L. Turgis et Fils, Paris",
        material: "Papel, canivet dentelle",
        dimension: "11,5 x 7,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.66.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Autor Desconhecido",
        material: "Papel, canivet dentelle",
        dimension: "8 x 5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.67.jpg",
      },
      {
        name: "Pagela St. Antoine de Padoue",
        author: "Autor Desconhecido",
        material: "Papel pintado à mão",
        dimension: "9,5 x 6,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.68.jpg",
      },
      {
        name: "Pagela St. Antoine",
        author: "Autor Desconhecido",
        material:
          "Papel, canivet dentelle desdobrável descobrindo no interior a imagem de St. António.",
        dimension: "9 x 6 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.69.jpg",
      },
      {
        name: "Pagela St. António de Lisboa",
        author: "Autor Desconhecido",
        material:
          "Papel, canivet dentelle desdobrável descobrindo no interior a imagem de St. António.",
        dimension: "11 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.70.jpg",
      },
      {
        name: "Pagela St. António de Lisboa",
        author: "Autor Desconhecido",
        material: "Papel Colorido.",
        dimension: "12 x 4,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.71.jpg",
      },
      {
        name: "Pagela St. Antonius von Padua",
        author: "Ed. M. K, Alemanha",
        material: "Postal cartonado.",
        dimension: "14 x 9 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.72.jpg",
      },
      {
        name: "Pagela Os Cinco mártires de Marrocos e Santo António de Lisboa",
        author: "Autor Desconhecido",
        material: "Postal",
        dimension: "14 x 9 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.73.jpg",
      },
      {
        name: "Pagela S. Antonius Lusitanus",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "12 x 8 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.75.jpg",
      },
      {
        name: "Pagela Santo António de Lisboa",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "12 x 6,8 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.76.jpg",
      },
      {
        name: "Pagela San Antonio de Padua",
        author: "Autor Desconhecido",
        material: "Postal cartonado",
        dimension: "12 x 8 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.77.jpg",
      },
      {
        name: "Pagela Santo António Militar",
        author: "Autor Desconhecido",
        material: "Pergaminho",
        dimension: "8,7 x 5,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.81.jpg",
      },
      {
        name: "Pagela Aparição do Menino Jesus a Santo António de Lisboa",
        author: "Autor Desconhecido",
        material: "Pergaminho",
        dimension: "11,5 x 6,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.83.jpg",
      },
      {
        name: "Pagela Der Heilige Antonius von Padua",
        author: "Autor Desconhecido",
        material: "Pergaminho",
        dimension: "13 x 8 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.84.jpg",
      },
      {
        name:
          "Pagela S. António apresenta a Nossa Senhora a sua Língua incorrupta",
        author: "S. Mauber (?)",
        material: "Pergaminho",
        dimension: "15 x 10 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.85.jpg",
      },
      {
        name: "Pagela St. Antonius v. Padua",
        author: "Carl Mayer, Nuremberga",
        material: "Pergaminho",
        dimension: "14,2 x 9,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.86.jpg",
      },
      {
        name: "Pagela St. Antonius de Padua",
        author: "Hofmann",
        material: "Papel",
        dimension: "15 x 7,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.87.jpg",
      },
      {
        name: "Pagela St. Antonius",
        author: "Autor Desconhecido",
        material: "Papel, canivet dentelle",
        dimension: "8,5 x 6 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.88.jpg",
      },
      {
        name: "Pagela San Antonio",
        author: "Ed. J. Pena y Sacanell, Barcelona",
        material: "Papel",
        dimension: "11 x 5,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.89.jpg",
      },
      {
        name: "Gozos Alabanza del Glorioso Milagrero San António de Padua",
        author: "Ed. J. B. Batle, Barcelona",
        material: "Papel",
        dimension: "34 x 22,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.90.jpg",
      },
      {
        name: "Pagela S. António de Lisboa",
        author: "Autor Desconhecido",
        material: "Papel, dourados, esta da imagem colada, canivet dentelle",
        dimension: "11 x 8 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.91.jpg",
      },
      {
        name: "Pagela S. Antonius von Padua",
        author: "Ed.  Verlag von Serz & C.º, Nuremberga",
        material: "Canivet dentelle",
        dimension: "10 x 6,5 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.92.jpg",
      },
      {
        name: "Pagela S. Antonius de Padua",
        author: "Autor Desconhecido",
        material: "Pintura sobre papel de seda",
        dimension: "12 x 8 cm",
        owner: "Coleção Délio Lopes",
        info:
          "Pintura sobre papel de seda muito fino, teve de ser colado num cartão para não se rasgar. O Santo é vestido de tecido assim como o Menino, ambos têm resplendores finamente recortados em papel dourado, fazendo resplendor. À volta do desenho, apesar de não se avistar, as partes castanhadas são picotadas formando finos desenhos.",
        img: "/assets/wallImgs/pagelasregistos/010.93.jpg",
      },
      {
        name: "Pagela Andenken",
        author: "Autor Desconhecido",
        material: "Pintura sobre papel",
        dimension: "10,5 x 7 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/pagelasregistos/010.94.jpg",
      },
      {
        name: "Registo de Santo António de Lisboa",
        author: "Autor Desconhecido",
        material: "Papel recortado",
        dimension: "18,5 x 15,5cm",
        owner: "Coleção Rui Patrício Guerreiro",
        img: "/assets/wallImgs/pagelasregistos/013.1.jpg",
      },
      {
        name: "Registos Santo António",
        material: "Tecido e Papel",
        owner: "Coleção Maria Antónia Braga",
        img: "/assets/wallImgs/pagelasregistos/016.10.1.jpg",
      },
      {
        name: "Registos Santo António",
        material: "Tecido e Papel",
        owner: "Coleção Maria Antónia Braga",
        img: "/assets/wallImgs/pagelasregistos/016.10.2.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.10.1.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.10.2.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.10.3.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.10.4.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.10.5.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.10.6.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.10.7.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.1.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.2.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.3.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.4.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.5.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.6.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.7.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.8.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.9.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.11.10.jpg",
      },
      {
        name: "Pagela Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "27 x 17 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.12.jpg",
      },
      {
        name: "Pagela Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "11 x 6,9 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.13.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.14.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.1.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.2.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.3.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.4.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.5.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.6.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.7.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.8.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.9.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.15.10.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.1.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.2.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.3.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.4.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.5.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.6.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.7.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.8.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.9.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.10.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.11.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.16.12.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.1.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.2.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.3.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.4.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.5.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.6.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.7.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.8.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.9.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.10.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.11.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.12.jpg",
      },
      {
        name: "Postais Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,5 x 8,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.17.13.jpg",
      },
      {
        name: "Postal Orfanotrofio Maschile Antoniano",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,8 cm x 9,2 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.18.jpg",
      },
      {
        name: "Pagelas Relíquia de Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "14,5 x 10,4 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.19.1.jpg",
      },
      {
        name: "Pagelas Relíquia de Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "14,5 x 10,4 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.19.2.jpg",
      },
      {
        name: "Pagelas Relíquia de Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "11,2 x 7 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.19.3.jpg",
      },
      {
        name: "Postal duplo Boas Festas de Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "18,2 x 14 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.20.1.jpg",
      },
      {
        name: "Postal duplo Boas Festas de Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "18,2 x 14 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.20.2.jpg",
      },
      {
        name: "Pagela Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,3 x 16,2 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.23.1.jpg",
      },
      {
        name: "Pagela Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "13,3 x 16,2 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.23.2.jpg",
      },
      {
        name: "Pagela Cruz de Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "15 x 17,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.24.1.jpg",
      },
      {
        name: "Pagela Cruz de Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "15 x 17,8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.24.2.jpg",
      },
      {
        name: "Pagela com Relíquia Santo António ora pro nobis",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "11 x 6 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.27.jpg",
      },
      {
        name: "Pagela com Relíquia Santo António",
        author: "Autor Desconhecido",
        material: "Papel",
        dimension: "10,5 x 6,3 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.28.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "12,5 x 8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.30.1.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "12,5 x 8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.30.1.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "12,5 x 8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.30.2.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "12,5 x 8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.30.3.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "12,5 x 8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.30.4.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "12,5 x 8 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.30.5.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,4 x 7 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.31.1.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,4 x 7 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.31.2.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,4 x 7 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.31.3.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,4 x 7 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.31.4.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,4 x 7 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.31.5.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,4 x 7 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.31.6.jpg",
      },
      {
        name: "Pagelas Santo António",
        author: "Ed. La chocolaterie D' Aiguebelle, Drôme",
        material: "Papel",
        dimension: "11,4 x 6,5 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/pagelasregistos/017.32.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Portuguesas",
        material: "Vinte e duas gravuras diversas sobre papel",
        dimension: "(a maior) 13,5 x 9 cm",
        owner: "Coleção Cabral Moncada Leilões | Luís Sousa",
        img: "/assets/wallImgs/pagelasregistos/019.107.jpg",
      },
      {
        name:
          "Registo oitavado Santo António com o Menino Jesus e Adoração dos trez Santos Reis Magos",
        author: "Português",
        material:
          "Gravuras sobre papel. Passe-partout em papel «filigranado» e dourado",
        dimension: "26 x 20 cm",
        owner: "Coleção Cabral Moncada Leilões | Luís Sousa",
        img: "/assets/wallImgs/pagelasregistos/019.160.jpg",
      },
    ],
  },
  artesanato: {
    name: "Artesanato",
    items: [
      {
        name: "Santo António com o Menino",
        author: "Quirina",
        material: "Barro",
        dimension: "17 x 5,8 cm",
        owner: "Coleção Miguel Infante",
        info: "Adquirido à própria artesã em Estremoz.",
        img: "/assets/wallImgs/artesanato/001.01.jpg",
      },
      {
        name: "Bilha de Santo António",
        author: "Maria Sidónio",
        material: "Barro",
        dimension: "14,2 x 10,2 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido à própria artesã que vendia na Feira Popular de Lisboa.",
        img: "/assets/wallImgs/artesanato/001.02.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Rosa Ramalho",
        material: "Barro",
        owner: "Coleção Miguel Infante",
        img: "/assets/wallImgs/artesanato/001.03.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Júlia Ramalho",
        material: "Barro",
        dimension: "28,3 x 11,3 cm ",
        owner: "Coleção Miguel Infante",
        img: "/assets/wallImgs/artesanato/001.04.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "António Ramalho",
        material: "Barro",
        dimension: "25 x 11 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido ao próprio artesão na FIARTIL, completando a representação do Santo por uma tríade familiar.",
        img: "/assets/wallImgs/artesanato/001.05.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Irmãos Ginja",
        material: "Barro",
        dimension: "39 x 14,4 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido aos próprios artesãos quando trabalhavam no Museu Municipal de Estremoz.",
        img: "/assets/wallImgs/artesanato/001.06.jpg",
      },
      {
        name: "Santo António Alfacinha",
        author: "Irmãs Flores",
        material: "Barro",
        dimension: "22,7 x 11,1 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido às próprias artesãs, no seu atelier. Tinham acabado de conceber esta peça e telefonaram-me a informar.",
        img: "/assets/wallImgs/artesanato/001.07.jpg",
      },
      {
        name: "Bilha de Santo António",
        author: "José Luís Pires",
        material: "Barro",
        dimension: "44, 4 x 26 cm (a bilha)",
        owner: "Coleção Miguel Infante",
        info: "Adquirido ao próprio artesão na FIARTIL.",
        img: "/assets/wallImgs/artesanato/001.08.jpg",
      },
      {
        name: "Santo António Militar",
        author: "José Luís Pires",
        material: "Barro",
        dimension: "40 x 14 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido ao próprio artesão na Feira de Artesanato de Vila Franca de Xira. Era a peça representativa de uma faceta da vida de Santo António que me faltava e representa uma excelente Feira de Artesanato que não falho.",
        img: "/assets/wallImgs/artesanato/001.09.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Ercília Sousa",
        material: "Vidro",
        dimension: "13,3 x 7,6 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Oferta da esposa feito por uma colega. É uma peça de um conjunto de quatro.",
        img: "/assets/wallImgs/artesanato/001.10.jpg",
      },
      {
        name: "Santo António das Migas",
        author: "Célia Freitas & Miguel Gomes",
        material: "Barro",
        dimension: "25 x 11,4 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Numa deslocação a Estremoz, vi uma representação destas à venda numa loja. Quando a apanhei aberta, a peça já tinha sido vendida. Anos mais tarde, falando com os artesãos, vi que foram eles os autores da peça e encomendei esta. Não é igual mas ficou muito parecida.",
        img: "/assets/wallImgs/artesanato/001.11.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "José Tanganho",
        material: "Barro",
        dimension: "46,8 x 12 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Peça que descobriu escondida numa loja em Lisboa e que foi paixão à primeira vista. Tinha de ficar com ela e lá convenci o proprietário a vendê-la.",
        img: "/assets/wallImgs/artesanato/001.12.jpg",
      },
      {
        name: "Santo António Careto",
        author: "Ercílio Natálio",
        material: "Pasta de papel",
        dimension: "39,02 x 20 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido ao próprio autor na Feira Crafts e Design, no Jardim da Estrela, numa homenagem às tradições populares portuguesas. Foi através deste artesão que entrei em contacto com um grupo de criadores fantástico e que me mostraram o talento incrível que existe em Portugal.",
        img: "/assets/wallImgs/artesanato/001.13.jpg",
      },
      {
        name: "Nicho de Santo António",
        author: "Autor Desconhecido",
        material: "Pasta de papel",
        owner: "Coleção Miguel Infante",
        info:
          "Representação rústica de um nicho de Santo António que me apaixonou. É uma das peças que mais gosto.",
        img: "/assets/wallImgs/artesanato/001.14.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Família Mistério",
        material: "Barro",
        dimension: "38 x 19,3 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido ao próprio Mistério numa viagem que fiz às aldeias dos artesãos do Figurado de Barcelos. Poucos anos depois o artesão faleceu.",
        img: "/assets/wallImgs/artesanato/001.15.jpg",
      },
      {
        name: "Rosário de Santo António",
        author: "Raquel Ramos Catharino Costa (Cerâmica Monte Cera Cruz)",
        material: "Barro",
        dimension: "38 cm (rosário)",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido à própria artesã. Representação original de Santo António e do Sermão ao Peixes.",
        img: "/assets/wallImgs/artesanato/001.16.jpg",
      },

      {
        name: "Santo António Doutor da Igreja",
        author: "Autor Desconhecido",
        material: "Barro",
        owner: "Coleção Miguel Infante",
        info: "Umas das ofertas mais bonitas da minha mãe.",
        img: "/assets/wallImgs/artesanato/001.17.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Adelino",
        material: "Barro",
        owner: "Coleção Miguel Infante",
        info:
          "Adquirido numa loja no Mercado de Vila Real de Santo António. Apreciei o aspeto rústico dado ao Santo.",
        img: "/assets/wallImgs/artesanato/001.18.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Delfim Manuel",
        material: "Barro",
        dimension: "11,8 x 3 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Tem a característica de me acompanhar sempre que vou em viagens, para proteção.",
        img: "/assets/wallImgs/artesanato/001.19.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Mariano",
        material: "Carvão",
        dimension: "10,5 x 3,5 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Esta peça foi adquirida na Festa da Vinha e do Vinho de Borba tendo-me chamado a atenção por ser feita de um bloco de carvão. É a única que tenho deste material e nunca mais vi à venda peças destas.",
        img: "/assets/wallImgs/artesanato/001.20.jpg",
      },
      {
        name: "Santo António do Natal",
        author: "João Ferreira",
        material: "Barro",
        dimension: "33,5 x 8,5 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Esta peça representa duas celebrações católicas que adoro: Santo António e o Natal. Na quadra natalícia sai do armário e vem para a sala acompanhar a árvore de Natal.",
        img: "/assets/wallImgs/artesanato/001.22.jpg",
      },
      {
        name: "Santo António Menino do Coro",
        author: "Autor Desconhecido",
        material: "Barro",
        dimension: "28,7 x 8,8 cm",
        owner: "Coleção Miguel Infante",
        info:
          "Oferta de uma amiga da minha madrinha, que foi quem me introduziu na paixão por Santo António. Primeira peça que tive de Santo António sem as características vestes de franciscano. Sei que é muito antiga pois quando me foi dado a proprietária disse-me que a peça já era de seus avós.",
        img: "/assets/wallImgs/artesanato/001.23.jpg",
      },
      {
        name: "Santo António do mundo",
        author: "Helena Mesquita",
        material: "Barro vermelho e madeira",
        dimension: "25 cm",
        owner: "Coleção Alexandre Correia",
        info:
          "Esta peça nasceu na FIA de 2019 aquando da entrega da Menção Honrosa ganha pela Helena Mesquita. Nesse momento de felicidade partilhada nasceu a encomenda deste Santo António: o Santo António do Mundo. O Santo que protege e cuida do mundo.",
        img: "/assets/wallImgs/artesanato/004.01.jpg",
      },
      {
        name:
          "Santo António inspirado na imagem do Santuário da Nossa Senhora da Nazaré",
        author: "Mestre Jorge da Conceição",
        material: "Barro vermelho com resplendor em prata",
        dimension: "38 cm",
        owner: "Coleção Alexandre Correia",
        info:
          "Numa primeira fase ao visitar o Santuário da Nossa Senhora da Nazaré, vi uma invulgar representação barroca do Santo António representando o domínio sobre os infiéis (cabeça do mouro). Fotografei a peça e falei com o Mestre Jorge da Conceição. Após isto, fomos em conjunto ao Mosteiro recolher mais informações sobre a referida peça junto do responsável do património religioso da diocese de Leiria. Com mais fotografias e com maior conhecimento cultural e artístico da peça desafiei o Mestre Jorge da Conceição a fazer um Santo António inspirado na figura e toda esta vivência. Realço também que a Imagem tem representado os peixes, referência ao sermão de Santo António aos peixes e o martelo conhecido como o Martelo dos hereges. Na peanha, encontra-se no medalhão central, em baixo relevo, dois dos atributos do Santo (lírio e a cruz), numa representação inspirada no fresco existente no teto da sacristia da Igreja de Santo António de Lisboa. De salientar que se trata de uma peça única feita exclusivamente para a coleção de Alexandre Correia.",
        img: "/assets/wallImgs/artesanato/004.02.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Madeira",
        dimension: "90 cm",
        owner: "Coleção Alexandre Correia",
        img: "/assets/wallImgs/artesanato/004.04.jpg",
      },
      {
        name: "Santo António Moçambique",
        author: "Mestra Reinata Sadimba",
        material: "Barro",
        dimension: "30 cm",
        owner: "Coleção Alexandre Correia",
        info:
          "Conheci pessoalmente a Reinata Sadimba numa iniciativa integrada na candidatura de Leiria à Rede de Cidades Criativas da Unesco, convidado pela Dr.ª Celeste Afonso e com o apoio da fundação Calouste Gulbenkian. Nesse âmbito, pedi à querida e talentosa Reinata Sadimba que me fizesse um Santo António. A Reinata não sabia quem era Santo António, nunca o tinha feito. Falei à Reinata de Santo António, mostrei várias imagens. Daquelas maravilhosas mãos saíram este Santo António. Sempre que nos voltámos a encontrar chamava-me António e assim fiquei António para a Reinata. É o único Santo António feito pela Reinata.",
        img: "/assets/wallImgs/artesanato/004.05.jpg",
      },
      {
        name: "Santo António",
        author: "Mestre José Franco",
        material: "Barro",
        dimension: "38 cm",
        owner: "Coleção Alexandre Correia",
        info:
          "Peça dos anos 60 do século passado. Enquanto colecionador, ambicionava uma peça do Mestre José Franco.. Esta maravilhosa peça que achei que tinha de ser minha desde o primeiro momento, assim são os verdadeiros amores, foi uma autêntica loucura que cometi tendo terminado numa vitória. Peça comprada num leilão.",
        img: "/assets/wallImgs/artesanato/004.07.jpg",
      },
      // {
      //   name: "Santo António de Estremoz",
      //   author: "Olaria Alfacinha / Mestra Sabina da Conceição Santos",
      //   material: "Barro",
      //   dimension: "30 cm",
      //   owner: "Coleção Alexandre Correia",
      //   info:
      //     "Peça dos Anos 70 do século passado. Trata-se de um dos dois modelos mais representativos da barrista de Estremoz que foi recuperado nos anos 30 do século passado e que a Mestra Sabina Santos produziu ao longo da sua carreira.",
      //   img: "/assets/wallImgs/artesanato/004.08.jpg",
      // },
      {
        name: "Santo António",
        author: "Vista Alegre",
        material: "Porcelana Portuguesa",
        dimension: "21 cm",
        owner: "Coleção Alexandre Correia",
        info:
          "Peça Rara da Vista Alegre marcada na base (Verde grande fogo, a carimbo).",
        img: "/assets/wallImgs/artesanato/004.09.jpg",
      },
      {
        name: "Santo António Lisboa para Solteiras",
        author: "Faianças Bordalo Pinheiro",
        material: "Barro",
        dimension: "24 cm",
        owner: "Coleção Alexandre Correia",
        info:
          "Tem na peanha inscrito Lisboa para Solteiras, fazendo referência à fama de Santo Casamenteiro. Este modelo foi fabricado entre 2000 e 2009. A pintura é mista, a óleo (menino, mãos e cabeça do santo) e vidrado. Não se fizeram muitos exemplares nesta decoração.",
        img: "/assets/wallImgs/artesanato/004.10.jpg",
      },
      {
        name: "Santo António no Arco do Toino",
        author: "Teresa Brito",
        material: "Barro",
        dimension: "24 cm",
        owner: "Coleção Alexandre Correia",
        info:
          "Peça feita exclusivamente para a minha coleção. A peça apresenta esta denominação por ser alusivo ao nome da minha coleção. O Santo protege a cidade de Lisboa em pleno arraial de Santos Populares com manjericos, balões, fados e guitarras.",
        img: "/assets/wallImgs/artesanato/004.11.jpg",
      },
      {
        name: "Santo António Brincando",
        author: "Mestre Alberto Miguel",
        material: "Barro vermelho",
        dimension: "26 cm",
        owner: "Coleção Alexandre Correia",
        info:
          "Esta peça do Mestre Alberto Miguel, uma das referências maiores da cerâmica das Caldas da Rainha, foi a única peça que vi à venda do Mestre Alberto Miguel desde que faço coleção. Foi adquirida num antiquário.",
        img: "/assets/wallImgs/artesanato/004.12.jpg",
      },
      {
        name: "Santo António",
        author: "Mistério Domingos Gonçalves Lima",
        material: "Barro vermelho",
        dimension: "29 cm",
        owner: "Coleção Alexandre Correia",
        img: "/assets/wallImgs/artesanato/004.13.jpg",
      },
      {
        name: "Santo António Saloio",
        author: "António Sampaio",
        material: "Barro vermelho",
        dimension: "54 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Conheci o Sr. António Sampaio em 2015, já o Sr. António Sampaio não trabalhava por doença. No dia que conheci o Sr. Sampaio em sua casa após muito conversar com ele, perguntei-lhe pela oficina. Respondeu-me: está fechada há muitos anos. Forcei e, generosamente, para me fazer a vontade, abriu-me as já perras portas da oficina. Foi mágico. Passado umas semanas recebo um telefonema do Sr. Sampaio a convidar-me para ir almoçar a sua casa que me queria agradecer. À mesa desse almoço agradeceu-me por ter voltado a trabalhar o barro por meu incentivo após aquela primeira visita. Disse-me: passo lá 10 horas por dia, é o meu melhor remédio. Fiquei feliz, realizado. Visitei mais vezes o Sr. Sampaio a trabalhar na oficina. Após o seu falecimento liga-me a sua esposa a dar a triste notícia e a dizer que tinha uma peça para me oferecer que o Sr. Sampaio fazia questão que ficasse para mim. Foi esta peça. O Santo António mais premiado do Sr. Sampaio que em toda a sua vida se recusou vender.",
        img: "/assets/wallImgs/artesanato/004.15.jpg",
      },
      {
        name: "Santo António Alminha",
        author: "Júlia Ramalho",
        material: "Barro vidrado",
        dimension: "42 cm de Altura",
        owner: "Coleção Alexandre Correia",
        img: "/assets/wallImgs/artesanato/004.16.jpg",
      },
      {
        name: "Santo António",
        author: "Fernando Jorge",
        material: "Barro branco",
        dimension: "33 cm de Altura",
        owner: "Coleção Alexandre Correia",
        img: "/assets/wallImgs/artesanato/004.18.jpg",
      },
      {
        name: "Santo António barro negro",
        author: "Mestre Joaquim Alvelos",
        material: "Barro",
        dimension: "30 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Uma peça muita rara adquirida num antiquário. Mais uma paixão, pelo nome do artista e pela técnica ancestral de cozedura: A soenga, arcaico processo tecnológico de cozedura herdado das mais longínquas tradições neolíticas. Consiste num fosso aberto no solo com cerca de 4 metros de diâmetro e 1 metro de profundidade onde se coziam as peças.",
        img: "/assets/wallImgs/artesanato/004.19.jpg",
      },
      {
        name: "Santo António",
        author: "Mestra Maria Helena Lourenço",
        material: "Barro vermelho",
        dimension: "33 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Imagem representando o Milagre da aparição do Menino Jesus a Santo António. Encomenda feita por mim à Mestra Maria Helena Lourenço. Uma peça de culto Antoniano que tenho em destaque na minha casa.",
        img: "/assets/wallImgs/artesanato/004.20.jpg",
      },
      {
        name: "Santo António do Pão",
        author: "Eduardo e Jesus Pias",
        material: "Grés",
        dimension: "43 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Peça adquirida aos próprios em visita ao seu atelier em Barcelos.",
        img: "/assets/wallImgs/artesanato/004.22.jpg",
      },
      {
        name: "Santo António Fernando Pessoa",
        author: "Mário Reis",
        material: "Barro vermelho",
        dimension: "30 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Original peça do escultor Mário Reis fazendo uma ligação entre Lisboa e a Nazaré. Na peça está inscrita a seguinte quadra: As gaivotas, tantas, tantas / Voam no Rio pró Mar / Também sem querer me encantas / Nem é preciso Voar!",
        img: "/assets/wallImgs/artesanato/004.24.jpg",
      },
      {
        name: "Santo António dos Afetos",
        author: "Cristina Santos",
        material: "Barro vermelho, tecido, metal",
        dimension: "43 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Peça desenvolvida pela artista após grande conversa entre os dois sobre a história que me liga a Santo António. Os 7 corações existentes na peça são para mim uma representação muito simbólica deste amor que tenho a Santo António.",
        img: "/assets/wallImgs/artesanato/004.25.jpg",
      },
      {
        name: "Santo António Pintura Barroca",
        author: "Mestre Delfim Manuel",
        material: "Barro vermelho",
        dimension: "35 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Pintura inspiradas nas pinturas religiosas de Santo António barroco dos finais do século XVII/XVIII. Peça única.",
        img: "/assets/wallImgs/artesanato/004.26.jpg",
      },
      {
        name: "Santo António Pintura Barroca",
        author: "Mestre Delfim Manuel",
        material: "Barro vermelho",
        dimension: "15 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info: "Primeiro Santo António feito pelo Mestre Delfim Manuel.",
        img: "/assets/wallImgs/artesanato/004.27.jpg",
      },
      {
        name: "Santo António em Olaria Enfeitada de Estremoz",
        author: "Ricardo Fonseca",
        material: "Barro vermelho",
        dimension: "35 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Peça executada após um desafio lançado ao artista, tendo como objetivo a integração de um Santo António de Figurado de Estremoz numa peça de Olaria Enfeitada de Estremoz (pucarinho).",
        img: "/assets/wallImgs/artesanato/004.28.jpg",
      },
      {
        name: "Santo António Avintes",
        author: "Mestre Tuta",
        material: "Barro",
        dimension: "13 cm de Altura",
        owner: "Coleção Alexandre Correia",
        info:
          "Hoje em dia é muito invulgar encontrar peças deste Mestre Cascateiro de Avintes. Peça de grande valor sentimental para mim.",
        img: "/assets/wallImgs/artesanato/004.29.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "José Franco",
        material: "Barro",
        dimension: "13 cm de Altura",
        owner: "Coleção Maria Lucília Mata (propriedade dos filhos e netos)",
        img: "/assets/wallImgs/artesanato/005.1.jpg",
      },
      {
        name: "Santo António de Estremoz",
        author: "A.G. (irmãos Ginja)",
        material: "Barro",
        dimension: "35x10,5x9,5 cm",
        owner: "Coleção de Maria Lucília Mata (propriedade dos filhos e netos)",
        img: "/assets/wallImgs/artesanato/005.2.jpg",
      },
      {
        author: "Autor Desconhecido",
        material: "Renda de Bilros",
        dimension: "aprox 14x20 cm",
        owner: "Coleção de Maria Lucília Mata (propriedade dos filhos e netos)",
        img: "/assets/wallImgs/artesanato/005.3.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Mané do Café",
        material: "Pintado a café",
        dimension: "14,8x21 cm (A5)",
        owner: "Coleção de Maria Lucília Mata (propriedade dos filhos e netos)",
        img: "/assets/wallImgs/artesanato/005.4.jpg",
      },
      {
        name: "Santo António Geringonça",
        author: "João Gomes",
        material: "Grês pintado",
        dimension: "48,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.1.jpg",
      },
      {
        name: "Cante Alentejano",
        author: "Célia Gomes",
        material: "Terracota pintada",
        dimension: "48,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.2.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Ana Sobral",
        material: "Grês",
        dimension: "20 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.3.jpg",
      },
      {
        name: "Passagem de Santo António",
        author: "Delfim Manuel",
        material: "Terracota pintada",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.4.jpg",
      },
      {
        name: "As férias de Santo António e o Menino",
        author: "Irmãos Baraça",
        dimension: "9,8 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.5.jpg",
      },
      {
        name: "Ceia de Santo António",
        author: "Pedro Riobom",
        material: "Cerâmica",
        dimension: "21,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.06.jpg",
      },
      {
        name: "Os três Santos Populares e o Menino",
        author: "Carolina André",
        material: "Terracota",
        dimension: "20 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.07.jpg",
      },
      {
        name: "Santo António",
        author: "Alice Diniz",
        material: "Terracota",
        dimension: "43,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.08.jpg",
      },
      {
        name: "Santo António",
        author: "Tiago Cabeça",
        material: "Terracota",
        dimension: "35 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.09.jpg",
      },
      {
        name: "Santo António",
        author: "Olga Lopes",
        material: "Cerâmica",
        dimension: "51 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.10.jpg",
      },
      {
        name: "Santo António",
        author: "Maria Sidónio",
        material: "Terracota",
        dimension: "33 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.11.jpg",
      },
      {
        name: "Santo António ensina os anjinhos a ler",
        author: "Zabel Moita",
        material: "Terracota",
        dimension: "31 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.12.jpg",
      },
      {
        name: "Santo António",
        author: "Isabel Lacerda",
        material: "Terracota",
        dimension: "30 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.13.jpg",
      },
      {
        name: "Santo António",
        author: "Maria Bernardete Gomes",
        material: "Terracota",
        dimension: "40 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.14.jpg",
      },
      {
        name: "Santo António",
        author: "Júlia Ramalho",
        material: "Terracota",
        dimension: "46 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.15.jpg",
      },
      {
        name: "Santo António",
        author: "Isabel Machado",
        material: "Terracota",
        dimension: "6 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.16.jpg",
      },
      {
        name: "Santo António",
        author: "Miguel Lemos",
        material: "Terracota",
        dimension: "10 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.17.jpg",
      },
      {
        name: "A Brincadeira de Santo António e o Menino",
        author: "António Sampaio",
        material: "Terracota",
        dimension: "24 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.18.jpg",
      },
      {
        name: "Santo António Cónego Regrante",
        author: "Maria Luísa Conceição",
        material: "Terracota",
        dimension: "30 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.19.jpg",
      },
      {
        name: "Santo António",
        author: "Raquel Costa",
        material: "Terracota",
        dimension: "22,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.20.jpg",
      },
      {
        name: "Santo António",
        author: "Júlia Ramalho",
        material: "Terracota",
        dimension: "15 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.21.jpg",
      },
      {
        name: "Santo António",
        author: "Mina Gallo",
        material: "Terracota",
        dimension: "37 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.22.jpg",
      },
      {
        name: "Santo António",
        author: "Isabel Pires",
        material: "Terracota",
        dimension: "19 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.23.jpg",
      },
      {
        name: "Santo António",
        author: "Júlia Ramalho",
        material: "Terracota",
        dimension: "38 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.24.jpg",
      },
      {
        name: "Santo António",
        author: "Luís Rosado",
        material: "Terracota",
        dimension: "49 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.25.jpg",
      },
      {
        name: "Santo António",
        author: "Conceição Sapateiro",
        material: "Terracota",
        dimension: "40 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.26.jpg",
      },
      {
        name: "Santo António",
        author: "Mário Reis",
        material: "Grês",
        dimension: "40 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.27.jpg",
      },
      {
        name: "Alminha",
        author: "Joaquim Siquenique",
        material: "Cortiça",
        dimension: "28,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.28.jpg",
      },
      {
        name: "Santo António",
        author: "Arlindo Afonso Ginja",
        material: "Terracota",
        dimension: "46 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.29.jpg",
      },
      {
        name: "Milagre da Mula",
        author: "Jorge Cardoso",
        material: "Grãos de arroz",
        dimension: "14,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.30.jpg",
      },
      {
        name: "Santo António",
        author: "Mistério",
        material: "Terracota",
        dimension: "31 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.31.jpg",
      },
      {
        name: "Fado",
        author: "José Luís Pires",
        material: "Terracota",
        dimension: "23 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.32.jpg",
      },
      {
        name: "Santo António",
        author: "Vitor Baraça",
        material: "Terracota",
        dimension: "29 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.33.jpg",
      },
      {
        name: "Santo António",
        author: "Eduarda Pias",
        material: "Terracota",
        dimension: "39 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.34.jpg",
      },
      {
        name: "Santo António Militar",
        author: "Luís Santos",
        material: "Terracota",
        dimension: "33 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.35.jpg",
      },
      {
        name: "Novos Mundos ao Mundo",
        author: "Ana Franco",
        material: "Terracota",
        dimension: "30 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.36.jpg",
      },
      {
        name: "Alminha",
        author: "Irene Salgueiro",
        material: "Terracota",
        dimension: "30 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.37.jpg",
      },
      {
        name: "Elétrico 28",
        author: "Ana Franco",
        material: "Terracota",
        dimension: "21,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.38.jpg",
      },
      {
        name: "Milagre da Bilha",
        author: "Fernando Miguel",
        material: "Terracota",
        dimension: "28 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.39.jpg",
      },
      {
        name: "Milagre dos peixes",
        author: "Rita Macedo",
        material: "Terracota e Pedra",
        dimension: "19,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.40.jpg",
      },
      {
        name: "Santo António",
        author: "Manuel Floriano",
        material: "Cortiça e vagens",
        dimension: "10 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.41.jpg",
      },
      {
        name: "Santo António",
        author: "Manuel Pinha",
        material: "Ferro e barro",
        dimension: "32,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.42.jpg",
      },
      {
        name: "Santo António",
        author: "Edmundo Sousa",
        material: "Barro",
        dimension: "25 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.45.jpg",
      },
      {
        name: "Santo António",
        author: "António Firmino",
        material: "Barro",
        dimension: "28 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.47.jpg",
      },
      {
        name: "Santo António Careto",
        author: "Ercílio Natálio",
        material: "Barro",
        dimension: "34 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.49.jpg",
      },
      {
        name: "Santo António Militar",
        author: "Beatriz Sendim",
        material: "Pano e madeira",
        dimension: "25 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.51.jpg",
      },
      {
        name: "Santo António",
        author: "Manuel Macedo",
        material: "Barro",
        dimension: "25 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.52.jpg",
      },
      {
        name: "Santo António",
        author: "Laurinda Pias",
        material: "Barro",
        dimension: "15 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.55.jpg",
      },
      {
        name: "Santo António",
        author: "Ana Dias",
        material: "Barro",
        dimension: "27 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.57.jpg",
      },
      {
        name: "Os Santos Populares",
        author: "Nelson Oliveira",
        material: "Barro",
        dimension: "17,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.59.jpg",
      },
      {
        name: "Santo António",
        author: "Victor Mota",
        material: "Grés",
        dimension: "19 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.61.jpg",
      },
      {
        author: "João Ferreira",
        material: "Barro",
        dimension: "25,4 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.63.jpg",
      },
      {
        author: "Franquelim Caetano",
        material: "Barro",
        dimension: "27,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.65.jpg",
      },
      {
        author: "Ana Rodrigues",
        material: "Barro",
        dimension: "10,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.66.jpg",
      },
      {
        author: "Fernando Jorge",
        material: "Barro",
        dimension: "18,5 cm",
        owner: "Coleção Mário Coelho",
        img: "/assets/wallImgs/artesanato/006.67.jpg",
      },
      {
        name: "Santo António Menino de Coro",
        author: "Maria Helena Lourenço",
        material: "Barro cozido",
        dimension: "27 x 12 x 15 cm",
        owner: "Coleção Délio Lopes",
        img: "/assets/wallImgs/artesanato/010.28.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Autor Desconhecido",
        material: "Pau-preto",
        dimension: "28 cm",
        owner: "Coleção Manuel Ribeiro de Faria",
        img: "/assets/wallImgs/artesanato/012.4.jpg",
      },
      {
        name: "Santo António",
        author: "Amon Gila",
        material: "Missangas sobre armação de arame",
        dimension: "18 cm",
        owner: "Coleção Manuel Ribeiro de Faria",
        img: "/assets/wallImgs/artesanato/012.5.jpg",
      },
      {
        name: "Santo António",
        author: "Amon Gila",
        material:
          "Tiras de latas de refrigerantes recicladas sobre armação de arame",
        dimension: "20 cm",
        owner: "Coleção Manuel Ribeiro de Faria",
        img: "/assets/wallImgs/artesanato/012.6.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Pasta de papel",
        dimension: "23 cm",
        owner: "Coleção Manuel Ribeiro de Faria",
        img: "/assets/wallImgs/artesanato/012.7.jpg",
      },
      {
        name: "Santo António de Lisboa",
        author: "Armindo Bod.",
        material: "Barro maciço",
        dimension: "20 x 10 cm",
        owner: "Coleção Rui Patrício Guerreiro",
        img: "/assets/wallImgs/artesanato/013.2.jpg",
      },
      {
        name: "Santo António de Lisboa",
        author: "Toniska",
        material: "Barro oco",
        dimension: "20 x 8 cm",
        owner: "Coleção Rui Patrício Guerreiro",
        img: "/assets/wallImgs/artesanato/013.3.jpg",
      },
      {
        name: "Santo António de Lisboa",
        material: "Barro oco",
        dimension: "30 x 7 cm",
        owner: "Coleção Rui Patrício Guerreiro",
        img: "/assets/wallImgs/artesanato/013.4.jpg",
      },
      {
        name: "Santo António",
        author: "Mistério Filha",
        material: "Barro Branco",
        dimension: "18 x 8 cm",
        owner: "Coleção Rui Patrício Guerreiro",
        img: "/assets/wallImgs/artesanato/013.6.jpg",
      },
      {
        name: "Santo António de Lisboa",
        author: "Rosa Ramalho",
        material: "Barro de Barcelos",
        dimension: "50 cm",
        owner: "Coleção Rita Gonçalves",
        img: "/assets/wallImgs/artesanato/014.10.jpg",
      },
      {
        name: "Santo António",
        author: "Olaria Alfacinha",
        material: "Barro de Estremoz",
        dimension: "33 cm",
        owner: "Coleção Rita Gonçalves",
        img: "/assets/wallImgs/artesanato/014.11.jpg",
      },
      {
        name: "Santo António",
        material: "Pedra",
        owner: "Coleção Maria Adelina Amorim",
        info:
          "Oferecido à colecionadora em Mosqueiro uma das bocas do rio Guamá, (Pará).",
        img: "/assets/wallImgs/artesanato/015.1.jpg",
      },
      {
        name: "Santo António",
        material: "Arame e Pedra",
        owner: "Coleção Maria Adelina Amorim",
        img: "/assets/wallImgs/artesanato/015.2.jpg",
      },
      {
        name: "Santo António",
        material: "Madeira branca",
        dimension: "19 cm",
        owner: "Coleção Maria Antónia Braga",
        img: "/assets/wallImgs/artesanato/016.02.jpg",
      },
      {
        name: "Santo António",
        author: "Maria Antónia Paramos",
        material: "Barro vidrado",
        dimension: "53 cm",
        owner: "Coleção Maria Antónia Braga",
        img: "/assets/wallImgs/artesanato/016.03.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Madeira",
        owner: "Coleção Maria Antónia Braga",
        info:
          "Comprado em Oberammergau, cidade do sul da Alemanha, conhecida pelo facto de muitas casas estarem decoradas com frescos de motivos religiosos. Em 1633, durante uma epidemia de peste, os habitantes prometeram realizar uma encenação da Paixão de Cristo, de dez em dez anos, se fossem protegidos da doença. Tradição que se mantém até aos nossos dias. Na cidade há muitas lojas e oficinas de artigos religiosos em madeira. ",
        img: "/assets/wallImgs/artesanato/016.05.jpg",
      },
      {
        name: "Santo António de Estremoz",
        author: "José Moreira",
        material: "Barro",
        dimension: "28,5 cm",
        owner: "Coleção João Durão",
        img: "/assets/wallImgs/artesanato/017.02.jpg",
      },
      {
        name: "Santo António - Vencer",
        author: "Sacha Kuul",
        material: "Papel",
        dimension: "38 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.12.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Pasta com areia",
        dimension: "11 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.15.jpg",
      },
      {
        name: "Santo António",
        author: "Paulo Martins",
        material: "Barro não cozido",
        dimension: "27 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.19.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Pedra, ferro, arame, chapa",
        dimension: "44 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.30.jpg",
      },
      {
        name: "Santo António",
        author: "Sasha Kuul",
        material: "Papel",
        dimension: "21 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.38.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Madeira, prata, cetim, escamas de peixe",
        dimension: "40 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.43.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Barro cozido oco, prata, latão, flor de papel",
        dimension: "34 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.44.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Prata",
        dimension: "28,5 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.47.jpg",
      },
      {
        name: "Santo António",
        author: "Vista Alegre",
        material: "Porcelana",
        dimension: "28 cm",
        owner: "Coleção Paulo Martins",
        info:
          "Reprodução original pertencente à coleção Mottahedeh fabricado na China no Séc. XVII, 8º centenário do nascimento de Sto António 1195-1995.",
        img: "/assets/wallImgs/artesanato/021.48.jpg",
      },
      {
        name: "Santo António",
        author: "Autor Desconhecido",
        material: "Barro cozido oco, vidrado, arame",
        dimension: "13,5 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.55.jpg",
      },
      {
        name: "Santo António",
        material: "Estuque, madeira, latão, olhos vidro",
        dimension: "39,5 cm",
        owner: "Coleção Paulo Martins",
        img: "/assets/wallImgs/artesanato/021.56.jpg",
      },
      {
        name: "Santo António do Pão",
        author: "Francisco Araújo",
        material: "Madeira",
        dimension: "49 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.02.jpg",
      },
      {
        name: "Santo António Português",
        author: "Pedro Riobom",
        material: "Cerâmica",
        dimension: "28 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.03.jpg",
      },
      {
        name: "Aparição do Menino a Santo António",
        author: "Victor Mota",
        material: "Cerâmica",
        dimension: "35 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.04.jpg",
      },
      {
        name: "Santo António e o Menino: o encontro",
        material: "Xisto e madeira",
        dimension: "22 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.05.jpg",
      },
      {
        name: "Santo António no estudo das Escrituras",
        author: "José Luís",
        material: "Barro",
        dimension: "20 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.06.jpg",
      },
      {
        name: "Santo António",
        author: "Rita Matias",
        material: "Barro",
        dimension: "20 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.07.jpg",
      },
      {
        name: "Santo António",
        author: "Ilda Lacerda",
        material: "Cerâmica",
        dimension: "40 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.08.jpg",
      },
      {
        name: "Santo António do Douro",
        material: "Barro",
        dimension: "28 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.09.jpg",
      },
      {
        name: "Santo António",
        author: "Olga Marques",
        material: "Barro",
        dimension: "17 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.11.jpg",
      },
      {
        name: "Santo António dá banho ao Menino",
        author: "Milena Miguel",
        material: "Barro",
        dimension: "16 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.12.jpg",
      },
      {
        name: "Santo António",
        author: "Rui Fernandes",
        material: "Caroço de manga e outros",
        dimension: "16 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.13.jpg",
      },
      {
        name: "Santo António",
        author: "Russo",
        material: "Barro",
        dimension: "40 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.14.jpg",
      },
      {
        name: "Santo António",
        author: "José da Rosa",
        material: "Pele e madeira",
        dimension: "25 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.15.jpg",
      },
      {
        name: "Santo António distribui pão",
        author: "Isabel Machado",
        material: "Barro",
        dimension: "30 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.16.jpg",
      },
      {
        name: "Santo António da ternura",
        author: "Mª João Manjerona",
        material: "Cerâmica",
        dimension: "28 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.17.jpg",
      },
      {
        name: "Santo António da barca",
        author: "Lourdes Ferreira",
        material: "Barro",
        dimension: "25 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.19.jpg",
      },
      {
        name: "Santo António anda de bicicleta",
        author: "Vítor Baraça",
        material: "Barro",
        dimension: "28 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.20.jpg",
      },
      {
        name: "Santo António",
        author: "Rui Fernandes",
        material: "Tela e corda de sisal",
        dimension: "90 cm",
        owner: "Coleção Rui Fernandes",
        info: "Réplica em tela e corda de sisal de Santo António de Pousada",
        img: "/assets/wallImgs/artesanato/024.22.jpg",
      },
      {
        name: "Santo António da Música",
        author: "Isabel Silva Melo",
        material: "Barro",
        dimension: "30 cm",
        owner: "Coleção Rui Fernandes",
        img: "/assets/wallImgs/artesanato/024.26.jpg",
      },
      {
        name: "Santo António",
        author: "Luís Santos",
        material: "Barro",
        dimension: "28 x 21 cm",
        owner: "Coleção Jorge Rocha",
        img: "/assets/wallImgs/artesanato/026.01.jpg",
      },
      {
        name: "Santo António",
        author: "Pinho Dinis",
        material: "Barro vidrado",
        dimension: "45 x 9,5 cm",
        owner: "Coleção Jorge Rocha",
        img: "/assets/wallImgs/artesanato/026.04.jpg",
      },
      {
        name: "Santo António",
        author: "Sandra Duarte",
        material: "Vidro",
        owner: "Coleção Pedro Ferreira",
        img: "/assets/wallImgs/artesanato/028.02.jpg",
      },
      {
        name: "Santo António com o Menino",
        owner: "Coleção Pedro Ferreira",
        img: "/assets/wallImgs/artesanato/028.03.jpg",
      },
      {
        name: "Santo António com o Menino",
        author: "Sr. Florentino",
        material: "Madeira",
        owner: "Coleção Pedro Ferreira",
        img: "/assets/wallImgs/artesanato/028.04.jpg",
      },
      {
        name: "Santo António",
        author: "Artesanato local",
        owner: "Coleção Pedro Ferreira",
        img: "/assets/wallImgs/artesanato/028.05.jpg",
      },
      {
        name: "Santo António",
        author: "Xica",
        owner: "Coleção A Arte da Terra",
        info:
          "Peça única concebida com materiais oriundos das praias do Sudoeste Alentejano, da autoria de Xica, pioneira em Portugal da Encenação de Pedra, falecida em 2019. A Arte da Terra foi um dos raros espaços onde ao longo de mais de 2 décadas, Xica apresentou o seu trabalho.",
        img: "/assets/wallImgs/artesanato/030.01.jpg",
      },
      {
        name: "Santo António a entrar para o elétrico 28",
        author: "Mistério",
        material: "Terracota",
        owner: "Coleção A Arte da Terra",
        info:
          "Peça originalmente concebida a convite de A Arte da Terra a um dos maiores nomes da arte popular portuguesa: Mistério. Santo António a entrar para o elétrico 28. Faz todo o sentido, face à localização da Igreja de Santo António, face a localização de A Arte da Terra e face á importância que o elétrico 28 tem nesta zona de Lisboa.",
        img: "/assets/wallImgs/artesanato/030.02.jpg",
      },
      {
        name: "Santo António",
        author: "Júlia Ramalho",
        material: "Terracota",
        owner: "Coleção A Arte da Terra",
        info:
          "Peça de um grande nome do artesanato nacional, Julia Ramalho, inspirada na obra de sua avó Rosa Ramalho.",
        img: "/assets/wallImgs/artesanato/030.03.jpg",
      },
      {
        name: "Santo António",
        author: "Ana Sobral",
        material: "Terracota",
        owner: "Coleção A Arte da Terra",
        info:
          "Peça de Ana Sobral, conceituada escultora, cujo primeiro Santo António resultou de um desafio de A Arte da Terra.",
        img: "/assets/wallImgs/artesanato/030.04.jpg",
      },
      {
        name: "Santo António",
        author: "Sérgio Amaral",
        material: "Cerâmica",
        owner: "Coleção A Arte da Terra",
        info:
          "Peça de Sérgio Amaral, um dos grandes ceramistas portugueses, que tem nos matarrachos, um dos seus ex-libris.",
        img: "/assets/wallImgs/artesanato/030.05.jpg",
      },
      {
        name: "Santo António",
        author: "João Gomes",
        owner: "Coleção A Arte da Terra",
        info:
          "Peça de João Gomes. Esta peça simboliza o 1º Santo António deste ceramista, que não fazia figurativo, datado de 2004, e que viria a ser o ponto de partida para idênticos desafios a artistas portugueses, cujo 1ºs Santo Antónios foram apresentados em exposições em A Arte da Terra.",
        img: "/assets/wallImgs/artesanato/030.06.jpg",
      },
    ],
  },
};

export default wallData;
