import React from "react";

const TimelineElements = ({
  timelineVida,
  activeItem,
  prevItems,
  nextItems,
}) => {
  return (
    <div className="itemsWrapper">
      <div className="vidaTrackWrapper">
        <div className="bgHeading">
          <h2>{timelineVida ? "Vida" : "Iconografia"}</h2>
        </div>
        <div className="prevItemsWrapper">
          {prevItems
            ? prevItems.map((val, i) => (
                <div key={i} className="prevItem">
                  <div
                    className={`itemCard prev ${
                      val && val.mainEvent ? "life" : "other"
                    }`}
                  >
                    {(val && val.mainEvent) !== undefined ? (
                      <div
                        className="cardImage"
                        style={{ backgroundImage: `url(${val.mainEvent.img})` }}
                      ></div>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
        <div
          className={`activeItemWrapper ${
            activeItem.mainEvent ? "life" : "other"
          }`}
        >
          {activeItem.mainEvent ? (
            <div className="itemCard active other">
              <div
                className="cardImage"
                style={
                  activeItem.mainEvent
                    ? { backgroundImage: `url(${activeItem.mainEvent.img})` }
                    : null
                }
              ></div>
              <div className="cardContent">
                <div className="content">
                  {activeItem.mainEvent !== undefined ? (
                    <p>{activeItem.mainEvent.content}</p>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="nextItemsWrapper">
          {nextItems
            ? nextItems.map((val, i) => (
                <div key={i} className="nextItem">
                  <div
                    className={`itemCard next ${
                      val && val.mainEvent ? "life" : "other"
                    }`}
                  >
                    {(val && val.mainEvent) !== undefined ? (
                      <div
                        className="cardImage"
                        style={{ backgroundImage: `url(${val.mainEvent.img})` }}
                      ></div>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="otherTrackWrapper">
        <div className="bgHeading">
          <h2>Outros Eventos</h2>
        </div>
        <div className="prevItemsWrapper">
          {prevItems
            ? prevItems.map((val, i) => (
                <div key={i} className="prevItem">
                  <div
                    className={`itemCard prev ${
                      val && val.otherEvent ? "life" : "other"
                    }`}
                  >
                    {(val && val.otherEvent) !== undefined ? (
                      <div
                        className="cardImage"
                        style={{
                          backgroundImage: `url(${val.otherEvent.img})`,
                        }}
                      ></div>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
        <div
          className={`activeItemWrapper ${
            activeItem.otherEvent ? "life" : "other"
          }`}
        >
          {activeItem.otherEvent ? (
            <div className="itemCard active other">
              <div
                className="cardImage"
                style={
                  activeItem.otherEvent
                    ? { backgroundImage: `url(${activeItem.otherEvent.img})` }
                    : null
                }
              ></div>
              <div className="cardContent">
                <div className="content">
                  {activeItem.otherEvent !== undefined ? (
                    <p>{activeItem.otherEvent.content}</p>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="nextItemsWrapper">
          {nextItems
            ? nextItems.map((val, i) => (
                <div key={i} className="nextItem">
                  <div
                    className={`itemCard next ${
                      val && val.otherEvent ? "life" : "other"
                    }`}
                  >
                    {(val && val.otherEvent) !== undefined ? (
                      <div
                        className="cardImage"
                        style={{
                          backgroundImage: `url(${val.otherEvent.img})`,
                        }}
                      ></div>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default TimelineElements;
