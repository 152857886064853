import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom'
import './App.css';

import Navbar from './Containers/Navbar';
import Main from './Components/Main';

function App() {
  return (
    <Router>
      <Route
        render={({ location }) => {
          const { key } = location;
          return (
            <div key={key} className="appWrapper">
              <Navbar />
              <Main location={location} />
            </div>
          );
        }}
      />
    </Router>
  );
}

export default App;
