import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import Draggable from "react-draggable";
import Masonry from "react-masonry-css";
import { Filter } from "react-feather";
import "./wall.css";
import wallData from "../data/wall";
import WallElement from "../Components/WallElement";

const breakpointWallColumns = {
  default: 12,
  1100: 8,
  700: 4,
  500: 4,
};

let categories = [];
for (let [key, value] of Object.entries(wallData)) {
  categories.push({
    name: value.name,
    count: value.items.length,
  });
}

const DraggableWall = () => {
  const [activeCategory, setActiveCategory] = React.useState();
  const [activeItem, setActiveItem] = React.useState();
  const [isOpenPopup, setIsOpenPopup] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const [dragInfoOpen, setDragInfoOpen] = React.useState(true);
  const [isCatNavOpen, setIsCatNavOpen] = React.useState(false);

  let data;
  switch (activeCategory) {
    case "Artesanato":
      data = wallData.artesanato.items;
      break;
    case "Artes Decorativas":
      data = wallData.decorativas.items;
      break;
    case "Pagelas e Registos":
      data = wallData.pagelas_registos.items;
      break;
    default:
      data = wallData.sacra.items;
      break;
  }

  const onDrag = (e) => {
    setDragging(true);
  };

  const onStop = (...args) => {
    if (!dragging) {
      handleClick(...args);
    }
    setDragging(false);
  };

  const chooseCategory = (e) => {
    if (isCatNavOpen) {
      setIsCatNavOpen(!isCatNavOpen);
    }
    if (activeCategory !== e.target.innerHTML) {
      setActiveCategory(e.target.innerHTML);
      setIsCatNavOpen(false);
    } else {
      setIsCatNavOpen(false);
    }
  };

  const handleClick = (e) => {
    if (e.target.getAttribute("data-id")) {
      setIsOpenPopup(!isOpenPopup);
      setActiveItem(e.target.getAttribute("data-id"));
    }
  };

  const popupClose = (e) => {
    setIsOpenPopup(!isOpenPopup);
    setActiveItem(undefined);
  };

  const handleFilterClick = () => {
    setIsCatNavOpen(!isCatNavOpen);
  };

  return (
    <div id="dragWall" className="pageOutterWrapper">
      <div className="wallCatNavWrapper">
        <div className={isCatNavOpen ? "wallCatNav" : "wallCatNav hidden"}>
          {categories.map((val, i) => {
            return (
              <div
                key={i}
                className={
                  activeCategory && val.name !== activeCategory
                    ? "wallCatBtn hidden"
                    : "wallCatBtn"
                }
              >
                <a onClick={chooseCategory}>{val.name}</a>
                <span>{val.count}</span>
              </div>
            );
          })}
        </div>
        {activeCategory ? (
          <Filter
            className={isCatNavOpen ? "catNavIcon hidden" : "catNavIcon"}
            onClick={handleFilterClick}
          />
        ) : null}
      </div>
      {activeCategory ? (
        <>
          {dragInfoOpen ? (
            <div className="dragInfo">
              <img src="/assets/wallImgs/explore.gif" alt="Draggable Icon" />
              <p>Arrasta para explorar.</p>
              <div className="addedInfo">
                <Filter className="dragInfoFilterIcon" />
                <p>Utiliza este ícone para filtrar a categoria a consultar.</p>
              </div>
              <button onClick={() => setDragInfoOpen(false)}>
                Ir para Exposição Virtual
              </button>
            </div>
          ) : null}
          <Draggable onDrag={onDrag} onStop={onStop}>
            <Masonry
              breakpointCols={breakpointWallColumns}
              className="wallWrapper"
              columnClassName="wallWrapperColumn"
            >
              {data
                ? data.map((val, i) => (
                    <WallElement key={i} idx={i} data={val} />
                  ))
                : null}
            </Masonry>
          </Draggable>
          <div id="WallPopUp" className={isOpenPopup ? " open" : null}>
            {activeItem ? (
              <div className="popupWrapper">
                <div className="popupHalf img">
                  <img src={data[activeItem].img} alt={data[activeItem].name} />
                </div>
                <div className="popupHalf content">
                  <h2>{data[activeItem].name}</h2>
                  {data[activeItem].name ? (
                    <p>{data[activeItem].author}</p>
                  ) : null}
                  {data[activeItem].date ? (
                    <p>{data[activeItem].date}</p>
                  ) : null}
                  {data[activeItem].material ? (
                    <p>{data[activeItem].material}</p>
                  ) : null}
                  {data[activeItem].dimension ? (
                    <p>{data[activeItem].dimension}</p>
                  ) : null}
                  {data[activeItem].owner ? (
                    <p>{data[activeItem].owner}</p>
                  ) : null}
                  {data[activeItem].auction ? (
                    <p>{data[activeItem].auction}</p>
                  ) : null}
                  {data[activeItem].info ? (
                    <p>{data[activeItem].info}</p>
                  ) : null}
                </div>
              </div>
            ) : (
              "no item"
            )}
            <a className="popupClose" onClick={popupClose}>
              X
            </a>
          </div>
        </>
      ) : (
        <div className="wallIntro">
          <img src="/assets/wallImgs/wallIntroImg.png" alt="" />
          <button
            className="wallIntroBtn"
            onClick={() => setActiveCategory("Arte Sacra")}
          >
            Ver Exposição Virtual
          </button>
          <p>
            Pedir a um colecionador que escolha algumas das suas peças para
            participar numa exposição é exigir uma tarefa (quase) impossível.
          </p>
          <p>
            Mas foi este o desafio que lançámos a alguns colecionadores
            antonianos, para que participassem numa exposição online, e a
            resposta foi entusiasmante. Independentemente do valor de cada uma
            das peças, muitas vezes o mais importante é a história associada à
            sua incorporação na coleção, a memória da sua descoberta, os afetos
            de quem a ofereceu. Isso permite entrar no mundo do colecionador
            que, durante anos, paciente e discretamente, vai construindo a sua
            coleção, como se estivesse a montar um puzzle que não acaba nunca.
          </p>
          <p>
            E se os colecionadores são persistentes na sua tarefa, os
            colecionadores de Santo António são obstinados na procura de objetos
            com a imagem deste Santo que se distingue pela presença iconográfica
            nos mais diversos suportes e materiais. Peças de arte sacra erudita
            ou de cariz mais popular, do objeto raro ao múltiplo, das pagelas às
            orações devocionais, na figuração do artesanato onde a imagem de
            Santo António é presença obrigatória no trabalho do artesão de norte
            a sul do país. Por isso, esta exposição é também uma extraordinária
            viagem pelo artesanato português, que surpreende pela originalidade
            e pela criatividade.
          </p>
          <p>
            Ao colecionador antoniano importa a multiplicidade da imagem de
            Santo António, que com a mínima variante vai enriquecendo a coleção.
            Mais do que o gosto, o que define a coleção é a presença da imagem
            deste Santo que está literalmente em todo o lado, em todos os
            suportes e em qualquer material, que espelha uma devoção e
            popularidade incomuns.
          </p>
          <p>
            Alargámos este desfio a antiquários e leiloeiros, cuja atividade,
            embora com características diferentes, reflete muito do gosto do
            colecionismo e das famílias, pois são atividades profissionais que
            conhecem como ninguém as peças que se conservam de geração em
            geração. E também aqui a presença de Santo António surpreende, quer
            pela quantidade, quer sobretudo pela qualidade das peças que vão
            surgindo, e que exigem que os colecionadores mantenham uma atenção
            redobrada ao mercado de arte.
          </p>
          <p>
            Um agradecimento aos colecionadores particulares que se associaram a
            esta exposição, e ao esforço que fizeram na seleção exigida.
            Tentámos não repetir autores que, pela sua importância, são presença
            obrigatória em muitas das coleções.
          </p>
          <p>
            Agradecemos ainda aos leiloeiros e antiquários que
            despretensiosamente se associaram a esta exposição. São empresas que
            muitas vezes atuam com uma genuína atitude de serviço público, pela
            forma como apoiam e divulgam a cultura portuguesa, disponibilizando
            informações e iconografia sobre peças nacionais ou de encomenda
            portuguesa, para estudantes e investigadores.
          </p>
          <p>
            Gostaríamos que esta fosse a primeira de muitas exposições com esta
            temática, aproximando colecionadores, artesãos, leiloeiros,
            antiquários e apreciadores de Santo António, dando a conhecer uma
            riqueza extraordinária da nossa história e da nossa cultura.
          </p>
          <p className="fichaTecnica">
            <strong>Ficha Técnica</strong>
            <p>Coordenação | Pedro Teotónio Pereira</p>
            <p>Produção | Leonor Padinha Ribeiro</p>
            <p>Design | Leonor Wagner Alvim</p>
            <p>Desenvolvimento Tecnológico | The Orange</p>
          </p>
          <p>Agradecimentos</p>
          <p>
            Alexandre Correia | Anita Russo | Augusto Moutinho Borges | Délio
            Lopes | Diácono António Machado | Filipe Martins | Jaime Manuel da
            Silva | João Durão | Jorge Rocha | Coronel Manuel Ribeiro de Faria |
            Maria Adelina Amorim | Maria Antónia Braga | Mário Coelho | Miguel
            Rui Infante | Natália Correia Guedes | Paulina Mata | Paulo Martins
            | Pedro Ferreira | Rita Gonçalves | Rui Fernandes | Rui Patrício
            Guerreiro | Zita
          </p>
          <p>Antiguidades São Roque | Arte da Terra | Cabral Moncada Leilões</p>
          <button
            className="wallIntroBtn"
            onClick={() => setActiveCategory("Arte Sacra")}
          >
            Ver Exposição Virtual
          </button>
        </div>
      )}
    </div>
  );
};

export default withRouter(DraggableWall);
