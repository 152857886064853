import React from "react";
import { withRouter, useLocation } from "react-router-dom";

import timelineData from "../data/timeline";
import TimelineElements from "../Components/TimelineElements";
import TimelineNav from "../Components/TimelineNav";

const Timeline = () => {
  const [timelineVida, setTimelineVida] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(Number());
  const [previousItem, setPreviousItem] = React.useState();
  const [prevItems, setPrevItems] = React.useState();
  const [nextItems, setNextItems] = React.useState();

  const pathname = useLocation().pathname;
  let data = [];

  if (pathname === "/timeline-vida") {
    data = timelineData.vida;
  } else {
    data = timelineData.culto;
  }

  const filterData = () => {
    if (activeItem !== previousItem) {
      setPrevItems([data[activeItem - 2], data[activeItem - 1]]);
      setNextItems([data[activeItem + 1], data[activeItem + 2]]);
    }
    setPreviousItem(activeItem);
  };

  const increment = () => {
    setPreviousItem(activeItem);
    if (activeItem < data.length - 1) setActiveItem(activeItem + 1);
  };
  const decrement = () => {
    setPreviousItem(activeItem);
    if (activeItem > 0) setActiveItem(activeItem - 1);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 39) {
      increment();
    } else if (e.keyCode === 37) {
      decrement();
    }
  };

  React.useEffect(() => {
    // Set active timeline
    if (pathname === "/timeline-vida") {
      setTimelineVida(true);
    }
    // Event Listeners for keyboard press
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  });

  React.useEffect(() => {
    filterData();
  });

  return (
    <div
      id={timelineVida ? "tVida" : "tCulto"}
      className="timelineOuterWrapper"
    >
      <div className="bgFull"></div>
      <div className="bgFullOverlay"></div>
      <div className="mainTimelineBtnWrapper">
        <button className="mainTimelineBtn prev" onClick={decrement}></button>
        <button className="mainTimelineBtn next" onClick={increment}></button>
      </div>
      <div className="timelineWrapper">
        <TimelineNav
          activeItem={data[activeItem]}
          prevItems={prevItems}
          nextItems={nextItems}
        />
        <TimelineElements
          timelineVida={timelineVida}
          activeItem={data[activeItem]}
          prevItems={prevItems}
          nextItems={nextItems}
        />
      </div>
    </div>
  );
};

export default withRouter(Timeline);
