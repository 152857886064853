import React from "react";
import { Phone, Facebook } from "react-feather";

const NavbarDrawer = ({ isOpen, activeBtn }) => {
  const [open, setOpen] = React.useState(false);
  const [activeContacts, setActiveContacts] = React.useState();

  const handleContacts = (e) => {
    switch (true) {
      case open:
        setOpen(!open);
        break;
      default:
        setActiveContacts(e.target.id);
        setOpen(!open);
        break;
    }
  };
  return (
    <div className={`navbarDrawer ${isOpen ? "menuOpen" : ""}`}>
      {activeBtn === "" && (
        <div id="mainBtn" className="drawerWrapper">
          <div className="content">
            <a className="main" href="/">
              <h2>Home</h2>
            </a>
            <div className="timelineNavLinks">
              <h2>Cronologia</h2>
              <div className="menuBtnWrapper">
                <a className="primaryBtn main" href="/timeline-vida">
                  Vida
                </a>
                <a className="primaryBtn main" href="/timeline-culto">
                  Culto e Iconografia
                </a>
              </div>
            </div>
            <a className="main" href="/memory-game">
              <h2>Jogo da memória</h2>
            </a>
            <a className="main" href="/exposicao">
              <h2>Exposição Virtual</h2>
            </a>
            <a className="main" href="/exposicao-tronos">
              <h2>Tronos Santo António</h2>
            </a>
          </div>
        </div>
      )}
      {activeBtn === "contacts" && (
        <div className="drawerWrapper">
          <div className="contactsEntryImg">
            <img src="/assets/imgs/msa_image.png" alt="" />
          </div>
          <div className="content">
            <h2 className="title">Museu de Lisboa – Santo António</h2>
            <div className="adressWrapper">
              <p>Largo de Santo António da Sé, 22</p>
              <p>1100-499 Lisboa</p>
            </div>
            <p>
              <span>
                <Phone />
              </span>
              + 351 218 860 447
            </p>
            <p>
              <span>
                <Facebook />
              </span>
              <a
                className="primaryBtn"
                href="https://www.facebook.com/museudelisboaEGEAC/"
                target="_blank"
                rel="noopener noreferrer"
              >
                /museudelisboaEGEAC
              </a>
            </p>
            <a
              className="primaryBtn map"
              href="https://www.google.com/maps/place/Museum+of+Saint+Anthony/@38.7101455,-9.1339279,15z/data=!4m5!3m4!1s0x0:0x2733e49a94ac622e!8m2!3d38.7101455!4d-9.1339279"
              target="_blank"
              rel="noopener noreferrer"
            >
              ver mapa
            </a>
          </div>
          <div className="socialWrapper"></div>
        </div>
      )}
    </div>
  );
};

export default NavbarDrawer;
