import React from "react";

const Home = () => {
  return (
    <div className="pageOutterWrapper">
      <div className="bannerWrapper">
        <div className="bannerBg"></div>
      </div>
      <div className="pageContentWrapper">
        <h1>Museu de Lisboa - Santo António</h1>
        <p className="textTitle">O Núcleo</p>
        <p>
          Localizado na zona histórica da cidade, junto à Igreja de Santo
          António, próximo da Sé, este núcleo do Museu de Lisboa dá a conhecer a
          figura do Santo, enfatizando a sua relação com Lisboa, cidade onde
          nasceu e viveu até aos 20 anos.
        </p>
        <p>
          Inaugurado em 18 de Julho de 2014, o Museu de Lisboa – Santo António é
          resultante da transformação do anterior Museu Antoniano, aberto ao
          público em Junho de 1962, num novo espaço expositivo, ampliado e
          totalmente reformulado, onde são conjugadas duas tipologias de
          edifícios – o espaço original, que era parte integrante da Igreja de
          Santo António, e o novo espaço, inserido num típico edifício de
          rendimento pombalino.
        </p>
        <p>
          O Museu de Lisboa - Santo António compreende área de exposição de
          longa duração e centro de documentação.
        </p>
        <hr className="textSpacer medium" />
        <p className="textFeatured">Como chegar?</p>
        <p>Metro: Terreiro do Paço e Baixa-Chiado</p>
        <p>Autocarro: 714, 732, 736, 737, 760</p>
        <p>Elétrico: 12 e 28</p>
        <p>Parque: Chão do Loureiro, Portas do Sol e Campo das Cebolas</p>
        <hr className="textSpacer medium" />
        <a
          className="primaryBtn map"
          href="https://www.google.com/maps/place/Museum+of+Saint+Anthony/@38.7101455,-9.1339279,15z/data=!4m5!3m4!1s0x0:0x2733e49a94ac622e!8m2!3d38.7101455!4d-9.1339279"
          target="_blank"
        >
          Direções Google Maps
        </a>
        <hr className="textSpacer medium" />
        <a
          className="primaryBtn map"
          href="/assets/folheto_sto_antonio_PT.pdf"
          target="_blank"
        >
          Folheto (pdf)
        </a>
      </div>
    </div>
  );
};

export default Home;
