import React from "react"
import { withRouter, useLocation } from "react-router-dom"
import Draggable from "react-draggable"
import Masonry from "react-masonry-css"
import { Filter } from "react-feather"
import "./wall.css"
// specific for tronos
import "./tronos.css"
import tronosData from "../data/tronos"
import WallElement from "../Components/WallElement"

const breakpointWallColumns = {
  default: 12,
  1100: 3,
  700: 2,
  500: 1
}

let filterData = []
tronosData.participants.items.forEach((element) => {
  if (element.prize === "Vencedor") {
    filterData.push(element)
  }
})
tronosData.participants.items.forEach((element) => {
  if (element.prize === "Menção Honrosa") {
    filterData.push(element)
  }
})

let categories = []

for (let [key, value] of Object.entries(tronosData)) {
  categories.push(
    {
      name: "Participantes",
      count: value.items.length
    },
    {
      name: "Vencedores",
      count: filterData.length
    }
  )
}
console.log(categories)

// console.log(filterData);

const DraggableTronos = () => {
  const [activeCategory, setActiveCategory] = React.useState()
  const [activeItem, setActiveItem] = React.useState()
  const [isOpenPopup, setIsOpenPopup] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [dragInfoOpen, setDragInfoOpen] = React.useState(true)
  const [isCatNavOpen, setIsCatNavOpen] = React.useState(false)

  let data
  switch (activeCategory) {
    case "Vencedores":
      data = filterData
      break
    case "Participantes":
      data = tronosData.participants.items
      break
    default:
      data = tronosData.participants.items
      break
  }

  const onDrag = (e) => {
    setDragging(true)
  }

  const onStop = (...args) => {
    if (!dragging) {
      handleClick(...args)
    }
    setDragging(false)
  }

  const chooseCategory = (e) => {
    if (activeCategory !== e.target.innerHTML) {
      setActiveCategory(e.target.innerHTML)
      setIsCatNavOpen(false)
    } else {
      setIsCatNavOpen(false)
    }
  }

  const handleClick = (e) => {
    if (e.target.getAttribute("data-id")) {
      setIsOpenPopup(!isOpenPopup)
      setActiveItem(e.target.getAttribute("data-id"))
    }
  }

  const popupClose = (e) => {
    setIsOpenPopup(!isOpenPopup)
    setActiveItem(undefined)
  }

  const handleFilterClick = () => {
    setIsCatNavOpen(!isCatNavOpen)
  }

  return (
    <div
      id="dragWall"
      className={
        activeCategory
          ? `tronosWall pageOutterWrapper ${activeCategory}`
          : "tronosWall pageOutterWrapper"
      }
    >
      <div className="wallCatNavWrapper">
        <div className={isCatNavOpen ? "wallCatNav" : "wallCatNav hidden"}>
          {categories.map((val, i) => {
            return (
              <div
                key={i}
                className={
                  activeCategory && val.name !== activeCategory
                    ? "wallCatBtn hidden"
                    : "wallCatBtn"
                }
              >
                <a onClick={chooseCategory}>{val.name}</a>
                <span>{val.count}</span>
              </div>
            )
          })}
        </div>
        {activeCategory ? (
          <Filter
            className={isCatNavOpen ? "catNavIcon hidden" : "catNavIcon"}
            onClick={handleFilterClick}
          />
        ) : null}
      </div>
      {activeCategory ? (
        <>
          {dragInfoOpen ? (
            <div className="dragInfo">
              <img src="/assets/wallImgs/explore.gif" alt="Draggable Icon" />
              <p>Arrasta para explorar.</p>
              <div className="addedInfo">
                <Filter className="dragInfoFilterIcon" />
                <p>Utiliza este ícone para filtrar a categoria a consultar.</p>
              </div>
              <button onClick={() => setDragInfoOpen(false)}>
                Ver Esposição Virtual
              </button>
            </div>
          ) : null}
          <Draggable onDrag={onDrag} onStop={onStop}>
            <Masonry
              breakpointCols={breakpointWallColumns}
              className="wallWrapper"
              columnClassName="wallWrapperColumn"
            >
              {data
                ? data.map((val, i) => (
                    <WallElement key={i} idx={i} data={val} />
                  ))
                : null}
            </Masonry>
          </Draggable>
          <div id="WallPopUp" className={isOpenPopup ? " open" : null}>
            {activeItem ? (
              <div className="popupWrapper">
                <div className="popupHalf img">
                  <img src={data[activeItem].img} alt={data[activeItem].name} />
                </div>
                <div className="popupHalf content">
                  <h2>{data[activeItem].title}</h2>
                  <p>{data[activeItem].author}</p>
                  <p>{data[activeItem].location}</p>
                  <p>{data[activeItem].info}</p>
                </div>
              </div>
            ) : (
              "no item"
            )}
            <a className="popupClose" onClick={popupClose}>
              X
            </a>
          </div>
        </>
      ) : (
        <div className="wallIntro">
          <div className="bannerWrapper">
            <img src="/assets/tronosImgs/cabecalho_expo_tronos.png" alt="" />
          </div>
          <div className="contentWrapper">
            <button
              className="wallIntroBtn"
              onClick={() => setActiveCategory("Participantes")}
            >
              Ver Exposição Virtual
            </button>
            <p>
              Este ano o Santo António foi diferente, sem as Marchas, os
              Arraiais, os Casamentos ou a Procissão de Santo António.
            </p>
            <p>
              Mas apesar disso, fez-se a festa e não faltaram os tradicionais
              Tronos de Santo António, que em ano especial se tornou Concurso
              alargado a Portugal inteiro.
            </p>
            <p>
              Concorreram mais de 160 tronos, de norte a sul do país (ilhas
              incluídas), com propostas originais e bem criativas, patente nesta
              exposição online que reúne todos os tronos participantes.
            </p>
            <p>
              Coube aos membros do júri, composto pela fadista Kátia Guerreiro,
              pelo estilista Dino Alves e pelo coordenador do Museu de Lisboa -
              Santo António Pedro Teotónio Pereira, a seleção dos vencedores,
              valorizando a criatividade, a utilização de materiais reciclados e
              a originalidade das propostas.
            </p>
            <p>
              Para além da designação dos três vencedores, o júri atribuiu mais
              5 menções honrosas, destacando a qualidade dos Tronos
              participantes na edição de 2020.
            </p>
            <p>
              Parabéns aos vencedores e a todos os participantes, pois como diz
              a velha máxima, o importante é participar e todos são vencedores,
              porque afinal o que importa é homenagear o Santo António.
            </p>
            <button
              className="wallIntroBtn"
              onClick={() => setActiveCategory("Participantes")}
            >
              Ver Exposição Virtual
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default withRouter(DraggableTronos)
