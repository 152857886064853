import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import "./stopWatch.css";

const StopWatch = ({ minutes, seconds }) => {
  return (
    <div className="stopWatch">
      <h6>
        {minutes}
        <span>m</span>
      </h6>
      <h6>
        {seconds}
        <span>s</span>
      </h6>
    </div>
  );
};

export default withRouter(StopWatch);
