const timelineData = {
  vida: [
    {
      year: "1147",
      otherEvent: {
        content:
          "Reconquista cristã de Santarém, Sintra, Lisboa, Almada e Palmela. Fundação do Mosteiro de São Vicente de Fora, em Lisboa",
        img: "/assets/timlineImgs/vida/001.jpg",
      },
    },
    {
      year: "1179",
      otherEvent: {
        content:
          "O Papa Alexandre III confirma o título de Rei de Portugal a D. Afonso Henriques com a bula Manifestis Probatum. Foral de Lisboa",
        img: "/assets/timlineImgs/vida/002.jpg",
      },
    },
    {
      year: "1181",
      otherEvent: {
        content: "1181/1182: Nasce S. Francisco de Assis",
        img: "/assets/timlineImgs/vida/003.jpg",
      },
    },
    {
      year: "1185",
      otherEvent: {
        content:
          "Morte de D. Afonso Henriques, sepultado em Santa Cruz de Coimbra. Subida ao trono de D. Sancho I",
        img: "/assets/timlineImgs/vida/004.jpg",
      },
    },
    {
      year: "1191",
      mainEvent: {
        content:
          "Segundo a tradição, Fernando Martins de Bulhões nasceu a 15 de agosto de 1195 em Lisboa. A exumação realizada em 1981 corrige para 1191 a sua data de nascimento. Baptizado na Sé com uma semana de vida",
        img: "/assets/timlineImgs/vida/005.jpg",
      },
    },
    {
      year: "c. 1200",
      mainEvent: {
        content:
          'Primeiros estudos e Artes Liberais na Escola Catedralícia anexa à Sé de Lisboa (por 1198/1209), onde foi "menino do Coro"',
        img: "/assets/timlineImgs/vida/006_.jpg",
      },
    },
    {
      year: "1209",
      otherEvent: {
        content:
          "Primeiros discípulos de Francisco. Primeira Cruzada contra os Albigenses",
        img: "/assets/timlineImgs/vida/007.jpg",
      },
    },
    {
      year: "1209/1211",
      mainEvent: {
        content:
          "1209/2011: Ingressa nos Cónegos Regrantes de Santo Agostinho, no Mosteiro de São Vicente de Fora, em Lisboa",
        img: "/assets/timlineImgs/vida/008.jpg",
      },
      otherEvent: {
        content:
          "16 abril: Aprovação oral por Inocêncio III do modo de vida de Francisco e Companheiros",
        img: "/assets/timlineImgs/vida/009.jpg",
      },
    },
    {
      year: "1211",
      mainEvent: {
        content:
          "1211/1212: Pede transferência para o Mosteiro de Santa Cruz de Coimbra, onde prossegue os estudos",
        img: "/assets/timlineImgs/vida/010.jpg",
      },
      otherEvent: {
        content:
          "Morte de D. Sancho I e subida ao trono de D. Afonso II. Cortes em Coimbra",
        img: "/assets/timlineImgs/vida/011.jpg",
      },
    },
    {
      year: "1215",
      otherEvent: {
        content:
          "IV Concílio de Latrão: aprovação da Ordem dos Frades Menores. Fundação dos Dominicanos em Toulouse",
        img: "/assets/timlineImgs/vida/012.jpg",
      },
    },
    {
      year: "1216",
      otherEvent: {
        content:
          "1216/1217: Estabelecimento dos franciscanos em Portugal (Guimarães, Coimbra, Alenquer e Lisboa)",
        img: "/assets/timlineImgs/vida/013.jpg",
      },
    },
    {
      year: "1218",
      mainEvent: {
        content:
          "1218/1219: Ordenação sacerdotal como Cónego Regrante de Santo Agostinho",
        img: "/assets/timlineImgs/vida/014.jpg",
      },
    },
    {
      year: "1219",
      mainEvent: {
        content:
          "Conhece em Coimbra os cinco franciscanos que partem para Marrocos",
        img: "/assets/timlineImgs/vida/015_.jpg",
      },
      otherEvent: {
        content:
          "Partem para Marrocos cinco franciscanos (Berardo, Otto, Pedro, Adjunto e Acúrcio), conhecidos depois por Santos Mártires de Marrocos",
        img: "/assets/timlineImgs/vida/016.jpg",
      },
    },
    {
      year: "1220",
      mainEvent: {
        content:
          "Primavera de 1220: Ingressa na Ordem dos Frades Menores de São Francisco e recolhe-se no Convento de Santo Antão dos Olivais, em Coimbra. Toma o nome de António. No Outono dirige-se para Marrocos em missão apostólica",
        img: "/assets/timlineImgs/vida/017.jpg",
      },
      otherEvent: {
        content:
          "16 de janeiro: Martirizados os 5 franciscanos em Marrocos. Abril/maio: Chegam a Coimbra os restos mortais dos Santos Mártires de Marrocos, colocados na Igreja de Santa Cruz",
        img: "/assets/timlineImgs/vida/018.jpg",
      },
    },
    {
      year: "1221",
      mainEvent: {
        content:
          "Gravemente doente, regressa a Portugal em março mas uma tempestade atira o barco para as costas da Sicília. Assiste ao Capítulo Geral em Assis (Capítulo das Esteiras a 30 de maio 1221), onde conhece S. Francisco. Retira-se para o ermitério de Monte Paolo (Emília-Romanha)",
        img: "/assets/timlineImgs/vida/019.jpg",
      },
      otherEvent: {
        content: "Morte de S. Domingos",
        img: "/assets/timlineImgs/vida/020.jpg",
      },
    },
    {
      year: "1222",
      mainEvent: {
        content:
          "24 de setembro: deslocação a Forli para assistir a ordenações sacerdotais, onde improvisa sermão e se revela exímio pregador",
        img: "/assets/timlineImgs/vida/021.jpg",
      },
    },
    {
      year: "1223",
      mainEvent: {
        content:
          "Permanência missionária em Rimini. S. Francisco indica-o como o primeiro professor da Ordem, ou “Leitor” de Teologia, em Bolonha",
        img: "/assets/timlineImgs/vida/022.jpg",
      },
      otherEvent: {
        content:
          "29 de novembro: O Papa Honório III aprova por bula a Regra Franciscana",
        img: "/assets/timlineImgs/vida/023.jpg",
      },
    },
    {
      year: "1224",
      mainEvent: {
        content:
          "Viaja para França onde prega contra a heresia dos Albigenses. Lecciona Teologia em Montpellier e Toulouse",
        img: "/assets/timlineImgs/vida/024.jpg",
      },
    },
    {
      year: "1225",
      mainEvent: {
        content:
          "Pregação em Toulouse. É nomeado guardião do Convento de Puy-en-Velay ",
        img: "/assets/timlineImgs/vida/025.jpg",
      },
    },
    {
      year: "1226",
      mainEvent: {
        content:
          "Capítulo Provincial da Ordem de Arles, onde é eleito Custódio da Província de Limoges. Funda o Convento de Brive",
        img: "/assets/timlineImgs/vida/026.jpg",
      },
      otherEvent: {
        content: "3 de outubro: Morte de S. Francisco de Assis, em Assis",
        img: "/assets/timlineImgs/vida/027.png",
      },
    },
    {
      year: "1227",
      mainEvent: {
        content:
          "Passagem por Marselha. Nomeado provincial da Emília Romagna. Principia a redacção definitiva do seu Opus Evangeliorum ou Sermones Dominicales",
        img: "/assets/timlineImgs/vida/028.jpg",
      },
    },
    {
      year: "1228",
      mainEvent: {
        content:
          "Pregação em Pádua. Assiste à Canonização de S. Francisco de Assis. Prega em Roma perante o Papa Gregório IX, que o qualifica como Arca do Testamento.",
        img: "/assets/timlineImgs/vida/029.jpg",
      },
      otherEvent: {
        content: "Canonização de S. Francisco de Assis",
        img: "/assets/timlineImgs/vida/030.jpg",
      },
    },
    {
      year: "1230",
      mainEvent: {
        content:
          "25 de Maio: Assiste ao Capítulo Geral de Assis. Deixa o cargo de Provincial. Integra a delegação que em Roma obterá do Papa a bula Quo elongati, relativa à interpretação da Regra e do testamento de São Francisco. Ensina em Pádua e começa a preparar o texto dos Sermones Festivi, deixados inconclusos.",
        img: "/assets/timlineImgs/vida/031.jpg",
      },
      otherEvent: {
        content:
          "25 de maio: Trasladação dos restos mortais de S. Francisco da Igreja de S. Jorge para a Basílica de Assis.",
        img: "/assets/timlineImgs/vida/032.gif",
      },
    },
    {
      year: "1231",
      mainEvent: {
        content:
          "Em maio retira-se para o ermitério de Camposampiero. A 13 de Junho morre em Arcela, a caminho de Pádua. A 17 de Junho, terça-feira, os restos mortais são trasladados para a Igreja de Santa Maria do Convento dos Frades Menores em Pádua, realizando-se os primeiros milagres",
        img: "/assets/timlineImgs/vida/033.jpg",
      },
      otherEvent: {
        content:
          '13 de junho: Apesar da tentativa de manter a notícia da morte de Santo António em segredo, esta é anunciada pelas crianças de Pádua que correm pelas ruas a gritar "Morreu o Santo! Morreu Santo António!"',
        img: "/assets/timlineImgs/vida/034.jpg",
      },
    },
    {
      year: "1232",
      mainEvent: {
        content:
          "Em 30 de maio é canonizado por Gregório IX (bula Cum dicat Dominus) na Catedral de Espoleto, num dos processos de canonização mais rápidos da Igreja (início de julho de 1231 a 28 de maio de 1232)",
        img: "/assets/timlineImgs/vida/035_.jpg",
      },
      otherEvent: {
        content:
          "Segundo a tradição, na hora da sua canonização em Espoleto, os sinos de todas as Igrejas de Lisboa repicaram espontaneamente",
        img: "/assets/timlineImgs/vida/036.jpg",
      },
    },
  ],
  culto: [
    {
      year: "1232",
      otherEvent: {
        content:
          "Edição da primeira biografia de Santo António, Vita Prima ou Assidua (Beati Antonii Vita Prima), de um franciscano não identificado",
      },
    },
    {
      year: "1233",
      otherEvent: {
        content: "Fundação do Convento de S. Francisco no Porto",
      },
    },
    {
      year: "1234",
      otherEvent: {
        content: "Canonização de S. Domingos",
      },
    },
    {
      year: "1249",
      otherEvent: {
        content:
          "O dominicano Frei Paio de Coimbra refere Santo António nos seus sermões",
      },
    },
    {
      year: "1250",
      mainEvent: {
        content:
          'Séc. XIII: Primeira representação conhecida de Santo António, de cerca de 1243, atribuída ao "Mestre de S. Francisco"',
        img: "/assets/timlineImgs/other/C01.jpg",
      },
      otherEvent: {
        content:
          "Meados do séc. XIII: O Senado de Lisboa adquire a casa natal de Santo António (tradicionalmente chamada casa do bem-aventurado Santo Antoninho) e constrói uma capela. No mesmo edifício estabeleceu-se a Casa Consistorial da Cidade (Senado de Lisboa) ",
      },
    },
    {
      year: "1263",
      otherEvent: {
        content:
          "8 de abril: Trasladação dos restos mortais de Santo António para a nova basílica, construída em Pádua em sua honra. Em Pádua é chamado II Santo, o Santo por excelência",
      },
    },
    {
      year: "1275",
      otherEvent: {
        content: "Há no Ocidente 1100 conventos franciscanos",
      },
    },
    {
      year: "1300",
      mainEvent: {
        content:
          "Séc. XIV: Algumas representações de Santo António, com o hábito franciscano, por vezes com um livro, símbolo da fé e da sabedoria, ou seja, a Sagrada Escritura. Em Itália e França surgem nalgumas pinturas com uma chama e um coração, símbolos não adotados na iconografia portuguesa",
        img: "/assets/timlineImgs/other/C02.jpg",
      },
    },
    {
      year: "1310",
      otherEvent: {
        content: "14 de junho: Mudou-se o túmulo para outro lugar da Basílica",
      },
    },
    {
      year: "1326",
      otherEvent: {
        content: "1ª referência ao Senado de Lisboa na Casa de Santo António",
      },
    },
    {
      year: "1350",
      otherEvent: {
        content:
          "15 de fevereiro: O Cardeal Guy de Boulogne manda colocar o queixo de Santo António em relicário. Inicia-se a distribuição de relíquias do Santo",
      },
    },
    {
      year: "1384",
      otherEvent: {
        content: "Emancipação da Ordem Franciscana em Portugal",
      },
    },
    {
      year: "1400",
      mainEvent: {
        content:
          "Séc. XV: Generalização da imagem estilizada de Santo António como um jovem, desembaraçado e esbelto",
        img: "/assets/timlineImgs/other/C03.jpg",
      },
    },
    {
      year: "1428",
      otherEvent: {
        content:
          'O infante D. Pedro II traz de Pádua a relíquia de Santo António – constituída por parte do "crânio ainda com o cirilo"',
      },
    },
    {
      year: "1433",
      otherEvent: {
        content:
          "A capela de Santo António sai da jurisdição ordinária do arcebispado e demais autoridades eclesiásticas da Sé de Lisboa, pela bula Sedes Apostolica licet de 24 de janeiro de 1433, concedida à cidade de Lisboa pelo Papa Eugénio IV, a pedido da mesma Câmara ",
      },
    },
    {
      year: "1440",
      otherEvent: {
        content:
          "Igreja e Hospital de Santo António dos Portugueses no Campo de Marte em Roma",
      },
    },
    {
      year: "1450",
      mainEvent: {
        content:
          "O lírio ou açucena surge associado ao Santo, símbolo da pureza (após a canonização de São Bernardino), juntamente com a cruz, remetendo para o Cristocentrismo franciscano",
        img: "/assets/timlineImgs/other/C04.jpg",
      },
      otherEvent: {
        content:
          "Canonização de S. Bernardino de Siena, seis anos depois da sua morte",
      },
    },
    {
      year: "1470",
      otherEvent: {
        content:
          "D. Afonso V, antes de partir para a conquista das praças de África, dirige as suas preces a Santo António e, em cumprimento de voto formulado, ordena a construção do Convento do Varatojo (Torres Vedras), colocando-o sob a invocação do Santo",
      },
    },
    {
      year: "1471",
      otherEvent: {
        content:
          'A Infanta D. Isabel, duquesa de Borgonha, institui um encargo pio in perpetuum, na "capella do bemaventurado Santo Antoninho", por alma do seu irmão D. Fernando, o Infante Santo',
      },
    },
    {
      year: "c. 1496",
      otherEvent: {
        content:
          "No seguimento do desejo de D. João II, D. Manuel manda demolir a capela de Santo António e constrói de raiz uma nova igreja",
      },
    },
    {
      year: "1500",
      mainEvent: {
        content:
          "Final do séc. XVI: A arte barroca generaliza a imagem do Menino Jesus associada a Santo António. Torna-se o Santo nacional dos portugueses, sendo atribuído o seu nome às igrejas portuguesas no estrangeiro",
        img: "/assets/timlineImgs/other/C05.jpg",
      },
      otherEvent: {
        content:
          "Fundação de Conventos de Santo António em Portugal, Brasil, S. Tomé, Moçambique, Macau e Índia",
      },
    },
    {
      year: "1509",
      otherEvent: {
        content:
          "Auto da Índia de Gil Vicente, com referência a Santo António milagreiro",
      },
    },
    {
      year: "1531",
      otherEvent: {
        content:
          "13 de junho: Apresentação do Auto de Santo António, de Afonso Álvares, primeiro auto totalmente dedicado ao Santo",
      },
    },
    {
      year: "1570",
      otherEvent: {
        content:
          "É lançada a primeira pedra do convento de Santo António dos Capuchos em Lisboa",
      },
    },
    {
      year: "1595",
      otherEvent: {
        content:
          "Santo António de Arguim é declarado padroeiro da Bahia por D. Filipe II e recebe soldo de praça de soldado",
      },
    },
    {
      year: "1599",
      otherEvent: {
        content:
          "Instituição da Procissão do Ferrolho, com saída da Igreja de Santo António para a Igreja de Nossa Senhora da Penha de França, em Lisboa",
      },
    },
    {
      year: "1606",
      otherEvent: {
        content: "Fundação do Hospital de Santo António, em Madrid",
      },
    },
    {
      year: "1609",
      otherEvent: {
        content:
          "Margarida de Áustria, mulher de D. Filipe II, oferece relíquia à Sé de Lisboa",
      },
    },
    {
      year: "1650",
      mainEvent: {
        content:
          "Séc. XVII: Generalização da representação de Santo António em azulejos, tanto em paredes interiores ou átrios de igrejas, como em registos nas fachadas das casas",
        img: "/assets/timlineImgs/other/C06.jpg",
      },
    },
    {
      year: "1665",
      otherEvent: {
        content:
          "Santo António é alistado no exército português por iniciativa de D. Afonso VI, pouco antes da Batalha de Montes Claros.",
      },
    },
    {
      year: "1668",
      otherEvent: {
        content:
          "Santo António assenta praça como soldado no 2.º regimento de Infantaria de Lagos, por decisão de D. Pedro II, onde mais tarde será promovido a capitão e depois a major",
      },
    },
    {
      year: "1685",
      otherEvent: {
        content:
          "Por iniciativa do Governador da Capitania de Pernambuco, João de Souto Maior, Santo António assenta praça nas milícias luso-brasileiras, por ocasião das lutas contra o Quilombo dos Palmares ",
      },
    },
    {
      year: "1694",
      otherEvent: {
        content:
          "Padre António Vieira dedica nove sermões a Santo António, o mais conhecido dos quais a 13 de junho, o Sermão aos peixes",
      },
    },
    {
      year: "1717",
      otherEvent: {
        content:
          "Início da construção do Palácio e Convento de Mafra, em cumprimento de voto formulado por D. João V",
      },
    },
    {
      year: "1728",
      otherEvent: {
        content:
          "Remodelação da Real Casa de Santo António por ação de D. João V",
      },
    },
    {
      year: "1741",
      otherEvent: {
        content:
          "Por indicação de Paulo António de Carvalho e Mendonça, irmão do Marquês de Pombal e Presidente do Senado de Lisboa, o Senado deixa as casas da igreja de Santo António",
      },
    },
    {
      year: "1755",
      otherEvent: {
        content:
          "A igreja de Santo António é destruída pelo terramoto, salvando-se apenas o altar-mor e a imagem de Santo António. O culto continua a realizar-se numa estrutura provisória",
      },
    },
    {
      year: "1757",
      otherEvent: {
        content:
          "São abertos os alicerces da nova igreja de Santo António, pagos com as esmolas recolhidas no continente e no ultramar",
      },
    },
    {
      year: "1787",
      otherEvent: {
        content: "15 de maio: Bênção da nova igreja de Santo António",
      },
    },
    {
      year: "1800",
      mainEvent: {
        content:
          "Séc. XIX: Generalização da recolha de esmolas para as “Almas do Purgatório” invocando Santo António. Generalização da representação de Santo António com um alforge distribuindo pão",
        img: "/assets/timlineImgs/other/C07.jpg",
      },
    },
    {
      year: "1810",
      otherEvent: {
        content:
          "Santo António acompanha o Regimento de Infantaria nº 19 de Cascais nas campanhas da guerra peninsular. Em 1814 é elevado ao cargo de Tenente-Coronel, por ordem de D. João VI",
      },
    },
    {
      year: "1812",
      otherEvent: {
        content: "Conclusão das obras da igreja de Santo António",
      },
    },
    {
      year: "1832",
      otherEvent: {
        content: "Deixa de se realizar a Procissão do Ferrolho",
      },
    },
    {
      year: "1834",
      otherEvent: {
        content: "Deixa de se realizar a Procissão de Santo António",
      },
    },
    {
      year: "1895",
      otherEvent: {
        content:
          '7.º Centenário do nascimento de Santo António. O papa Leão XIII designa-o "o Santo de todo o mundo". Tentativa para retomar a realização da procissão de Santo António, que foi alvo de ataque na Rua do Ouro',
      },
    },
    {
      year: "1911",
      otherEvent: {
        content: "6 de julho: Encerramento da igreja de Santo António ao culto",
      },
    },
    {
      year: "1918",
      otherEvent: {
        content:
          "Proposta de criação do Museu Antoniano na Igreja, pelo Vereador Lino Neto",
      },
    },
    {
      year: "1925",
      mainEvent: {
        content:
          "Séc. XX: Importantes estudos em Portugal sobre a vida, obra e pensamento filosófico, teológico e místico de Santo António, da autoria de Fernando Félix Lopes, Francisco da Gama Caeiro e Henrique Pinto Rema",
        img: "/assets/timlineImgs/other/C08.jpg",
      },
    },
    {
      year: "1931",
      otherEvent: {
        content:
          "14 de março: Reabertura da Igreja ao culto. A igreja de Santo António é declarada Monumento Nacional. Comemoração do 7.º centenário da morte de Santo António",
      },
    },
    {
      year: "1934",
      otherEvent: {
        content:
          "Pio XI declara-o segundo padroeiro de Portugal, a par de S. Jorge, sendo o orago principal Nossa Senhora da Conceição. Festas da Cidade de Lisboa: Marchas e Arraiais de Santo António",
      },
    },
    {
      year: "1938",
      otherEvent: {
        content: "Oferta de relíquia à igreja de Santo António",
      },
    },
    {
      year: "1946",
      otherEvent: {
        content:
          "16 de janeiro: É atribuído a Santo António o título “Doctor Evangelicus” (Doutor da Igreja), pela carta apostólica Exulta, Lusitania felix, de Pio XII",
      },
    },
    {
      year: "1950",
      otherEvent: {
        content: "Noivas de Santo António em Lisboa",
      },
    },
    {
      year: "1951",
      otherEvent: {
        content:
          "É doada à Câmara Municipal de Lisboa (CML) a primeira coleção antoniana por José Carregal da Silva Passos",
      },
    },
    {
      year: "1952",
      otherEvent: {
        content:
          "É retomada a procissão de Santo António, apenas em redor da Igreja",
      },
    },
    {
      year: "1953",
      otherEvent: {
        content:
          "O dia 13 de junho é instituído como feriado municipal em Lisboa",
      },
    },
    {
      year: "1958",
      otherEvent: {
        content:
          "É doada à CML a segunda coleção antoniana, por José Pinto de Aguiar",
      },
    },
    {
      year: "1962",
      otherEvent: {
        content: "13 de junho: Inauguração do Museu Antoniano",
      },
    },
    {
      year: "1968",
      otherEvent: {
        content: "Oferta de uma relíquia de Santo António à Sé de Lisboa",
      },
    },
    {
      year: "1972",
      otherEvent: {
        content:
          "4 de outubro: Inauguração em Lisboa da estátua de Santo António no bairro de Alvalade (escultor António Duarte e arquiteto Antero Ferreira)",
      },
    },
    {
      year: "1981",
      otherEvent: {
        content:
          "Comemoração do 750.º aniversário da morte de Santo António. 6 de janeiro a 15 de fevereiro: segundo reconhecimento dos restos mortais de Santo António, verificando-se que as cartilagens do aparelho fonador continuavam incorruptas",
      },
    },
    {
      year: "1982",
      otherEvent: {
        content:
          "30 de maio: Comemorações dos 750 anos da canonização de Santo António. Inauguração da estátua de Santo António no largo junto à igreja e ao Museu (escultor Domingos Soares Branco), por ocasião da visita do papa João Paulo II. É retomada a Procissão de Santo António, com o percurso actual pelo Bairro de Alfama",
      },
    },
    {
      year: "1995",
      otherEvent: {
        content:
          "Comemorações dos Oitocentos anos do nascimento de Santo António.",
      },
    },
    {
      year: "2012",
      otherEvent: {
        content:
          "Lançamento do projecto de requalificação e ampliação do Museu de Santo António.",
      },
    },
    {
      year: "2014",
      otherEvent: {
        content: "Abertura do Museu de Santo António.",
      },
    },
  ],
};

export default timelineData;
