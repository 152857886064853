import React from "react";
import "./msalogos.css";

const MSAlogoH = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.76 41.56">
      <title>Logo Museu de Santo António</title>
      <g id="ea84783c-d7e5-43cf-9119-96a20044df52" data-name="Logos">
        <g>
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M50.16,8.1V3.62h0L49.07,8.1H47.49L46.41,3.62h0V8.1H44.85V.26h2.21l1.2,4.88h0L49.48.26h2.21V8.1Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M57.9,6a2.24,2.24,0,0,1-.19.93,2.67,2.67,0,0,1-.51.76,2.31,2.31,0,0,1-.76.51,2.24,2.24,0,0,1-.93.19,2.17,2.17,0,0,1-.92-.19,2.21,2.21,0,0,1-.76-.51,2.31,2.31,0,0,1-.51-.76A2.41,2.41,0,0,1,53.14,6V.25h1.53V6a.83.83,0,0,0,.25.59.86.86,0,0,0,1.19,0A.82.82,0,0,0,56.36,6V.25H57.9Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M64.26,5.79a2.67,2.67,0,0,1-.06.56,2.49,2.49,0,0,1-.87,1.48,2.56,2.56,0,0,1-1.64.52,2.24,2.24,0,0,1-.93-.19A2.31,2.31,0,0,1,60,7.65a2.67,2.67,0,0,1-.51-.76A2.24,2.24,0,0,1,59.3,6V5.24h1.54V6a.78.78,0,0,0,.25.59.85.85,0,0,0,.6.24.92.92,0,0,0,.73-.26,1,1,0,0,0,.25-.74.79.79,0,0,0-.08-.4.71.71,0,0,0-.21-.27A1.37,1.37,0,0,0,62.06,5a2.22,2.22,0,0,0-.39-.1,4.5,4.5,0,0,1-1-.3A2.48,2.48,0,0,1,60,4.1a2,2,0,0,1-.49-.71,2.7,2.7,0,0,1-.17-1,2.24,2.24,0,0,1,.19-.93A2.48,2.48,0,0,1,60,.71a2.34,2.34,0,0,1,.76-.52A2.24,2.24,0,0,1,61.7,0a2.2,2.2,0,0,1,.92.19,2.31,2.31,0,0,1,.76.51,2.67,2.67,0,0,1,.51.76,2.41,2.41,0,0,1,.18.93v.46H62.54V2.39a.86.86,0,0,0-.25-.6.76.76,0,0,0-.59-.25.88.88,0,0,0-.6.23.68.68,0,0,0-.25.58v0a1.27,1.27,0,0,0,.06.43.6.6,0,0,0,.17.28.69.69,0,0,0,.28.16,2.13,2.13,0,0,0,.42.11,5,5,0,0,1,.93.28,3.06,3.06,0,0,1,.8.49,2.26,2.26,0,0,1,.55.71,2.09,2.09,0,0,1,.21,1Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M65.58,8.1V.27h3.87V1.81H67.21V3.42h2.24V5H67.21v1.6h2.24V8.1Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M75.62,6a2.24,2.24,0,0,1-.19.93,2.37,2.37,0,0,1-1.27,1.27,2.35,2.35,0,0,1-1.85,0A2.37,2.37,0,0,1,71,6.89,2.41,2.41,0,0,1,70.86,6V.25H72.4V6a.82.82,0,0,0,.84.83A.82.82,0,0,0,74.08,6V.25h1.54Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M49.61,16.87a2.41,2.41,0,0,1-.7,1.68,2.51,2.51,0,0,1-.76.52,2.41,2.41,0,0,1-.93.18H44.85V11.42h2.39a2.4,2.4,0,0,1,.92.19,2.34,2.34,0,0,1,.75.52,2.27,2.27,0,0,1,.51.75,2.24,2.24,0,0,1,.19.93Zm-1.54,0V13.81a.85.85,0,0,0-.24-.6.82.82,0,0,0-.59-.25h-.85v4.75h.83a.85.85,0,0,0,.61-.24A.83.83,0,0,0,48.07,16.87Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M51,19.25V11.42h3.88V13H52.65v1.62h2.24v1.53H52.65v1.6h2.24v1.54Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M59.4,19.25V11.42H61v6.29h2.24v1.54Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M64.34,19.25V11.42h1.54v7.83Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M72.25,16.94a2.76,2.76,0,0,1-.06.57A2.5,2.5,0,0,1,71.32,19a2.56,2.56,0,0,1-1.64.52,2.41,2.41,0,0,1-.93-.18A2.51,2.51,0,0,1,68,18.8a2.36,2.36,0,0,1-.51-.76,2.17,2.17,0,0,1-.19-.92V16.4h1.54v.73a.8.8,0,0,0,.25.6.85.85,0,0,0,.6.24,1,1,0,0,0,.73-.26,1,1,0,0,0,.25-.75,1,1,0,0,0-.08-.4.61.61,0,0,0-.21-.26,1.38,1.38,0,0,0-.32-.17l-.39-.1a5.15,5.15,0,0,1-1-.29,3,3,0,0,1-.74-.48,2.14,2.14,0,0,1-.49-.72,2.66,2.66,0,0,1-.17-1,2.24,2.24,0,0,1,.19-.93,2.67,2.67,0,0,1,.51-.76,2.51,2.51,0,0,1,.76-.52,2.35,2.35,0,0,1,1.85,0,2.67,2.67,0,0,1,.76.51,2.45,2.45,0,0,1,.5.76,2.24,2.24,0,0,1,.19.93V14H70.52v-.46a.85.85,0,0,0-.24-.6.82.82,0,0,0-.59-.25.93.93,0,0,0-.61.23.71.71,0,0,0-.24.59v0a1.31,1.31,0,0,0,.06.43.72.72,0,0,0,.16.27.82.82,0,0,0,.29.17,3.26,3.26,0,0,0,.42.1,4.36,4.36,0,0,1,.93.29,2.79,2.79,0,0,1,.8.48,2.38,2.38,0,0,1,.55.71,2.12,2.12,0,0,1,.21,1Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M78.49,16.91a2.36,2.36,0,0,1-.18.92,2.23,2.23,0,0,1-.51.74,2.29,2.29,0,0,1-.75.5,2.34,2.34,0,0,1-.91.18H73.57V11.42h2.48a2.32,2.32,0,0,1,.91.19,2.29,2.29,0,0,1,.75.5,2.49,2.49,0,0,1,.51.75,2.34,2.34,0,0,1,.18.91,1.49,1.49,0,0,1-.07.47,2,2,0,0,1-.2.42,1.55,1.55,0,0,1-.31.36,2,2,0,0,1-.39.26,1.72,1.72,0,0,1,.78.65A1.75,1.75,0,0,1,78.49,16.91Zm-1.62-3.14a.82.82,0,0,0-.81-.81H75.2v1.62h.86a.81.81,0,0,0,.57-.23A.75.75,0,0,0,76.87,13.77ZM77,16.92a.81.81,0,0,0-.23-.57.78.78,0,0,0-.57-.24h-1v1.6h1a.8.8,0,0,0,.57-.22A.75.75,0,0,0,77,16.92Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M84.62,17.12a2.17,2.17,0,0,1-.19.92,2.21,2.21,0,0,1-.51.76,2.51,2.51,0,0,1-.76.52,2.47,2.47,0,0,1-1.85,0,2.51,2.51,0,0,1-.76-.52A2.21,2.21,0,0,1,80,18a2.32,2.32,0,0,1-.18-.92V13.54a2.24,2.24,0,0,1,.19-.93,2.31,2.31,0,0,1,.51-.76,2.67,2.67,0,0,1,.76-.51,2.35,2.35,0,0,1,1.85,0,2.67,2.67,0,0,1,.76.51,2.45,2.45,0,0,1,.5.76,2.24,2.24,0,0,1,.19.93Zm-1.54,0V13.54a.85.85,0,0,0-.24-.6.84.84,0,0,0-1.44.6v3.58a.81.81,0,0,0,.24.6.82.82,0,0,0,.6.25.84.84,0,0,0,.59-.24A.8.8,0,0,0,83.08,17.13Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M89,19.25l-.27-1.38H87.42l-.26,1.38H85.57l1.73-7.83h1.58l1.74,7.83Zm-.93-4.87h0l-.37,1.93h.76Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M49.81,28.09a2.76,2.76,0,0,1-.06.57,2.47,2.47,0,0,1-.87,1.47,2.56,2.56,0,0,1-1.64.53,2.24,2.24,0,0,1-.93-.19,2.67,2.67,0,0,1-.76-.51A2.31,2.31,0,0,1,45,29.2a2.24,2.24,0,0,1-.19-.93v-.72h1.54v.73a.79.79,0,0,0,.25.6.83.83,0,0,0,.6.24,1,1,0,0,0,.73-.26,1,1,0,0,0,.25-.74,1,1,0,0,0-.08-.41.67.67,0,0,0-.21-.26,1.2,1.2,0,0,0-.32-.16l-.4-.11a4.58,4.58,0,0,1-1-.29,3,3,0,0,1-.74-.48A2.1,2.1,0,0,1,45,25.7a2.72,2.72,0,0,1-.17-1,2.2,2.2,0,0,1,.19-.92,2.35,2.35,0,0,1,.51-.77,2.51,2.51,0,0,1,.76-.52,2.47,2.47,0,0,1,1.85,0,2.51,2.51,0,0,1,.76.52,2.34,2.34,0,0,1,.5.76,2.17,2.17,0,0,1,.19.92v.46H48.08v-.46a.81.81,0,0,0-.24-.59.79.79,0,0,0-.59-.26.91.91,0,0,0-.61.23.72.72,0,0,0-.24.59v0a1.63,1.63,0,0,0,.05.43.73.73,0,0,0,.17.27.82.82,0,0,0,.29.17,2.16,2.16,0,0,0,.42.1,5,5,0,0,1,.93.29,2.79,2.79,0,0,1,.8.48,2.3,2.3,0,0,1,.55.72,2,2,0,0,1,.21.95Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M54.11,30.41,53.84,29H52.49l-.26,1.38H50.64l1.73-7.83H54l1.73,7.83Zm-.93-4.88h0l-.37,1.94h.76Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M60.32,30.41l-1.94-4.89h0v4.89H56.82V22.57h2l1.6,4h0v-4H62v7.84Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M66.1,24.11v6.3H64.56v-6.3H63.05V22.57h4.56v1.54Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M73.42,28.27a2.41,2.41,0,0,1-.18.93,2.51,2.51,0,0,1-.52.76,2.54,2.54,0,0,1-.76.51,2.17,2.17,0,0,1-.92.19,2.24,2.24,0,0,1-.93-.19,2.67,2.67,0,0,1-.76-.51,2.45,2.45,0,0,1-.5-.76,2.24,2.24,0,0,1-.19-.93V24.69a2.33,2.33,0,0,1,.19-.92,2.54,2.54,0,0,1,.51-.76,2.51,2.51,0,0,1,.76-.52,2.41,2.41,0,0,1,.93-.18,2.32,2.32,0,0,1,.92.18,2.39,2.39,0,0,1,.76.52,2.54,2.54,0,0,1,.51.76,2.32,2.32,0,0,1,.18.92Zm-1.53,0V24.69a.81.81,0,0,0-.24-.59.79.79,0,0,0-.6-.26.82.82,0,0,0-.6.25.8.8,0,0,0-.25.6v3.58a.81.81,0,0,0,.24.6.79.79,0,0,0,.6.25.81.81,0,0,0,.59-.24A.79.79,0,0,0,71.89,28.28Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M47.75,41.56l-.27-1.38H46.12l-.26,1.38H44.27L46,33.73h1.58l1.74,7.83Zm-.93-4.88h0l-.36,1.94h.75Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M54,41.56,52,36.67h0v4.89H50.46V33.72h2l1.6,4h0v-4h1.54v7.84Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M59.73,35.26v6.3H58.19v-6.3h-1.5V33.72h4.55v1.54Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M67.06,39.42a2.24,2.24,0,0,1-.19.93,2.37,2.37,0,0,1-1.27,1.27,2.35,2.35,0,0,1-1.85,0,2.21,2.21,0,0,1-.76-.51,2.31,2.31,0,0,1-.51-.76,2.41,2.41,0,0,1-.18-.93V35.85a2.24,2.24,0,0,1,.19-.93,2.31,2.31,0,0,1,.51-.76,2.41,2.41,0,0,1,1.68-.7,2.24,2.24,0,0,1,.93.19,2.17,2.17,0,0,1,.75.51,2.31,2.31,0,0,1,.51.76,2.24,2.24,0,0,1,.19.93Zm-1.54-3.57a.85.85,0,0,0-.24-.6.79.79,0,0,0-.6-.25.82.82,0,0,0-.84.85v3.57a.85.85,0,0,0,.24.61.81.81,0,0,0,.59.24.85.85,0,0,0,.6-.24.78.78,0,0,0,.25-.59Zm-.35-2.66H64.09l.2-1.44h1.38Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M72,41.56,70,36.67h0v4.89H68.48V33.72h2l1.6,4h0v-4h1.53v7.84Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M75.09,41.56V33.73h1.54v7.83Z"
          />
          <path
            className="a29a30e0-434b-4aeb-8fdf-bb4dbddfa696"
            d="M82.83,39.42a2.24,2.24,0,0,1-.19.93,2.67,2.67,0,0,1-.51.76,2.31,2.31,0,0,1-.76.51,2.35,2.35,0,0,1-1.85,0,2.21,2.21,0,0,1-.76-.51,2.31,2.31,0,0,1-.51-.76,2.41,2.41,0,0,1-.18-.93V35.85a2.41,2.41,0,0,1,.18-.93,2.51,2.51,0,0,1,.52-.76,2.41,2.41,0,0,1,1.68-.7,2.24,2.24,0,0,1,.93.19,2.17,2.17,0,0,1,.75.51,2.48,2.48,0,0,1,.51.76,2.24,2.24,0,0,1,.19.93Zm-1.54,0V35.85a.85.85,0,0,0-.24-.6.79.79,0,0,0-.6-.25.82.82,0,0,0-.84.85v3.57a.85.85,0,0,0,.24.61.79.79,0,0,0,.59.24A.85.85,0,0,0,81,40,.78.78,0,0,0,81.29,39.44Z"
          />
        </g>
        <g>
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M3.2,28.74H16V6.06a3.2,3.2,0,0,1,3.19-3V.26H0V3a3.2,3.2,0,0,1,3.2,3.2Z"
          />
          <path
            className="ea5ab9d2-b24a-4075-a404-93a8735ef2c3"
            d="M19.21,41.56H32V28.74H29.29A10.08,10.08,0,0,1,19.21,38.82Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default MSAlogoH;
