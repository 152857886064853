import React from "react";
import "./msalogos.css";

const MSAlogoV = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.03 73.57">
      <title>Logo Museu Santo António</title>
      <g id="ffb1915d-1e57-4d79-90fc-791b284c89b2" data-name="Logos">
        <g>
          <g>
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M3.2,28.47H16V5.79a3.2,3.2,0,0,1,3.19-3V0H0V2.75A3.2,3.2,0,0,1,3.2,6Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M19.21,41.29H32V28.47H29.29A10.08,10.08,0,0,1,19.21,38.55Z"
            />
          </g>
          <g>
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M6.52,52.62v-2.8h0l-.67,2.8h-1l-.68-2.8h0v2.8h-1V47.71H4.58l.75,3.06h0l.76-3.06H7.48v4.91Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M11.28,51.87a1.56,1.56,0,0,1-.32.47,1.44,1.44,0,0,1-.48.32,1.4,1.4,0,0,1-.58.12,1.47,1.47,0,0,1-.58-.12,1.51,1.51,0,0,1-.47-.32,1.4,1.4,0,0,1-.32-.47,1.66,1.66,0,0,1-.11-.58V47.71h1v3.58a.53.53,0,0,0,.52.53.49.49,0,0,0,.38-.16.52.52,0,0,0,.16-.37V47.71h1v3.58A1.47,1.47,0,0,1,11.28,51.87Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M15.41,51.17a1.72,1.72,0,0,1-.58,1.28,1.61,1.61,0,0,1-1,.33,1.43,1.43,0,0,1-.58-.12,1.4,1.4,0,0,1-.47-.32,1.27,1.27,0,0,1-.32-.47,1.47,1.47,0,0,1-.12-.58v-.46h1v.46a.51.51,0,0,0,.16.38.51.51,0,0,0,.37.15.59.59,0,0,0,.46-.17.61.61,0,0,0,.16-.46.58.58,0,0,0-.05-.25.39.39,0,0,0-.14-.17.75.75,0,0,0-.2-.1l-.24-.07a2.63,2.63,0,0,1-.6-.18,1.66,1.66,0,0,1-.46-.3,1.21,1.21,0,0,1-.31-.45,1.64,1.64,0,0,1-.11-.62,1.43,1.43,0,0,1,.12-.58,1.47,1.47,0,0,1,.8-.8,1.4,1.4,0,0,1,.58-.12,1.43,1.43,0,0,1,.58.12,1.4,1.4,0,0,1,.47.32,1.56,1.56,0,0,1,.32.47,1.46,1.46,0,0,1,.11.58v.29h-1V49a.53.53,0,0,0-.15-.37.51.51,0,0,0-.37-.16.54.54,0,0,0-.38.15.42.42,0,0,0-.15.37h0a1,1,0,0,0,0,.27.5.5,0,0,0,.11.17.4.4,0,0,0,.17.1l.26.07a4,4,0,0,1,.59.17,1.77,1.77,0,0,1,.49.31,1.36,1.36,0,0,1,.35.44A1.32,1.32,0,0,1,15.41,51.17Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M16.26,52.62v-4.9h2.43v1h-1.4v1h1.4v1h-1.4v1h1.4v1Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M22.46,51.87a1.27,1.27,0,0,1-.32.47,1.4,1.4,0,0,1-.47.32,1.43,1.43,0,0,1-.58.12,1.4,1.4,0,0,1-.58-.12,1.55,1.55,0,0,1-.48-.32,1.38,1.38,0,0,1-.31-.47,1.47,1.47,0,0,1-.12-.58V47.71h1v3.58a.51.51,0,0,0,.16.37.47.47,0,0,0,.37.16.51.51,0,0,0,.38-.16.51.51,0,0,0,.15-.37V47.71h1v3.58A1.47,1.47,0,0,1,22.46,51.87Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M6.18,58.11a1.4,1.4,0,0,1-.12.58,1.55,1.55,0,0,1-.32.48,1.61,1.61,0,0,1-.48.32,1.39,1.39,0,0,1-.57.12H3.2V54.7H4.69a1.4,1.4,0,0,1,.58.12,1.5,1.5,0,0,1,.79.8,1.4,1.4,0,0,1,.12.58Zm-1-1.91a.51.51,0,0,0-.53-.53H4.16v3h.53a.51.51,0,0,0,.37-.15.51.51,0,0,0,.16-.38Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M7.09,59.61V54.7H9.51v1H8.11v1h1.4v1H8.11v1h1.4v1Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M12.34,59.61V54.7h1v3.94h1.4v1Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M15.46,59.61V54.7h1v4.91Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M20.44,58.16a1.45,1.45,0,0,1,0,.35,1.5,1.5,0,0,1-.54.92,1.61,1.61,0,0,1-1,.33,1.53,1.53,0,0,1-1.06-.44,1.56,1.56,0,0,1-.32-.47,1.43,1.43,0,0,1-.11-.58v-.45h1v.45a.49.49,0,0,0,.16.38.51.51,0,0,0,.37.15.59.59,0,0,0,.46-.17.64.64,0,0,0,.16-.46.62.62,0,0,0-.05-.25.47.47,0,0,0-.14-.17.75.75,0,0,0-.2-.1l-.24-.06a3.25,3.25,0,0,1-.6-.19,1.89,1.89,0,0,1-.47-.3,1.23,1.23,0,0,1-.3-.44,1.73,1.73,0,0,1-.11-.63,1.43,1.43,0,0,1,.12-.58,1.47,1.47,0,0,1,.8-.8,1.4,1.4,0,0,1,.58-.12,1.43,1.43,0,0,1,.58.12,1.7,1.7,0,0,1,.47.32,1.75,1.75,0,0,1,.32.48,1.58,1.58,0,0,1,.11.58v.29h-1V56a.54.54,0,0,0-.15-.38.5.5,0,0,0-.37-.15.53.53,0,0,0-.38.14.43.43,0,0,0-.15.37h0a1,1,0,0,0,0,.27.42.42,0,0,0,.11.17.4.4,0,0,0,.17.1l.26.07a3.09,3.09,0,0,1,.59.18,1.74,1.74,0,0,1,.49.3,1.4,1.4,0,0,1,.35.45A1.26,1.26,0,0,1,20.44,58.16Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M24.38,58.14a1.35,1.35,0,0,1-.12.57,1.48,1.48,0,0,1-.31.47,1.82,1.82,0,0,1-.47.31,1.39,1.39,0,0,1-.57.12H21.29V54.7h1.56a1.53,1.53,0,0,1,1,.43,1.7,1.7,0,0,1,.32.47,1.39,1.39,0,0,1,.12.57.93.93,0,0,1,0,.29.84.84,0,0,1-.13.27A1,1,0,0,1,24,57a1.38,1.38,0,0,1-.25.17,1.08,1.08,0,0,1,.49.4A1.12,1.12,0,0,1,24.38,58.14Zm-1-2a.49.49,0,0,0-.15-.36.52.52,0,0,0-.36-.14h-.53v1h.53a.49.49,0,0,0,.36-.15A.46.46,0,0,0,23.36,56.17Zm.08,2a.45.45,0,0,0-.15-.35.49.49,0,0,0-.36-.15h-.61v1h.6a.57.57,0,0,0,.37-.14A.46.46,0,0,0,23.44,58.14Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M28.24,58.27a1.38,1.38,0,0,1-.44,1,1.27,1.27,0,0,1-.47.32,1.46,1.46,0,0,1-1.16,0,1.52,1.52,0,0,1-.79-.79,1.43,1.43,0,0,1-.12-.58V56a1.4,1.4,0,0,1,.12-.58,1.47,1.47,0,0,1,.8-.8,1.4,1.4,0,0,1,.58-.12,1.43,1.43,0,0,1,.58.12,1.7,1.7,0,0,1,.47.32,1.75,1.75,0,0,1,.32.48,1.4,1.4,0,0,1,.11.58Zm-1-2.24a.54.54,0,0,0-.15-.38.5.5,0,0,0-.37-.15.51.51,0,0,0-.53.53v2.24a.5.5,0,0,0,.15.37.47.47,0,0,0,.37.16.51.51,0,0,0,.37-.15.49.49,0,0,0,.16-.38Z"
            />
            <path
              className="fd42d10c-eb2e-4e3d-ad24-40f1d3b3f688"
              d="M32,59.61H31l-.17-.87H30l-.16.87h-1L30,54.7h1Zm-1.34-1.85-.23-1.21h0l-.22,1.21Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M6.3,65.14a1.39,1.39,0,0,1,0,.35,1.51,1.51,0,0,1-.54.93,1.64,1.64,0,0,1-1,.32,1.43,1.43,0,0,1-.58-.11,1.56,1.56,0,0,1-.47-.32,1.53,1.53,0,0,1-.44-1.06V64.8h1v.46a.47.47,0,0,0,.16.37.51.51,0,0,0,.37.15.59.59,0,0,0,.46-.16.64.64,0,0,0,.16-.47.62.62,0,0,0,0-.25.36.36,0,0,0-.14-.16.75.75,0,0,0-.2-.11l-.24-.06a2.64,2.64,0,0,1-.6-.19,1.31,1.31,0,0,1-.46-.3,1.25,1.25,0,0,1-.31-.44A1.67,1.67,0,0,1,3.2,63a1.4,1.4,0,0,1,.12-.58A1.61,1.61,0,0,1,3.64,62a1.58,1.58,0,0,1,.48-.33,1.4,1.4,0,0,1,.58-.11,1.38,1.38,0,0,1,1,.44,1.56,1.56,0,0,1,.32.47,1.4,1.4,0,0,1,.12.58v.29h-1V63a.51.51,0,0,0-.15-.37.47.47,0,0,0-.37-.16.57.57,0,0,0-.38.14.43.43,0,0,0-.15.37h0a1,1,0,0,0,0,.27.42.42,0,0,0,.11.17.4.4,0,0,0,.18.11,1.31,1.31,0,0,0,.25.06,2.88,2.88,0,0,1,.59.18,2.07,2.07,0,0,1,.5.3,1.67,1.67,0,0,1,.34.45A1.26,1.26,0,0,1,6.3,65.14Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M10,66.59H9l-.17-.87H8l-.16.87h-1l1.09-4.9h1ZM8.67,64.75l-.23-1.22h0L8.2,64.75Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M12.94,66.59l-1.21-3.06h0v3.06h-1V61.68H12l1,2.51h0V61.68h1v4.91Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M16.58,62.64v4h-1v-4h-.94v-1h2.85v1Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M21.2,65.25a1.4,1.4,0,0,1-.12.58,1.75,1.75,0,0,1-.32.48,1.61,1.61,0,0,1-.48.32,1.4,1.4,0,0,1-.58.11,1.46,1.46,0,0,1-.58-.11,1.5,1.5,0,0,1-.79-.8,1.4,1.4,0,0,1-.11-.58V63a1.58,1.58,0,0,1,.11-.58,1.67,1.67,0,0,1,.8-.8,1.58,1.58,0,0,1,1.16,0,1.43,1.43,0,0,1,.47.33,1.48,1.48,0,0,1,.44,1Zm-1-2.24a.51.51,0,0,0-.15-.37.47.47,0,0,0-.37-.16.51.51,0,0,0-.53.53v2.24a.52.52,0,0,0,.15.38.51.51,0,0,0,.37.15.54.54,0,0,0,.38-.15.5.5,0,0,0,.15-.37Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M6,73.57H5l-.17-.86H4l-.17.86h-1l1.08-4.9h1ZM4.66,71.73l-.23-1.21h0l-.23,1.21Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M8.93,73.57,7.71,70.51h0v3.06h-1V68.66H8l1,2.51H9V68.66h1v4.91Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M12.57,69.62v3.95h-1V69.62h-.95v-1h2.85v1Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M17.18,72.23a1.4,1.4,0,0,1-.11.58,1.47,1.47,0,0,1-.8.8,1.4,1.4,0,0,1-.58.12,1.43,1.43,0,0,1-.58-.12,1.56,1.56,0,0,1-.47-.32,1.53,1.53,0,0,1-.44-1.06V70a1.43,1.43,0,0,1,.12-.58,1.56,1.56,0,0,1,.32-.47,1.53,1.53,0,0,1,1.06-.44,1.43,1.43,0,0,1,.58.12,1.27,1.27,0,0,1,.47.32,1.56,1.56,0,0,1,.32.47,1.43,1.43,0,0,1,.11.58Zm-1-2.24a.51.51,0,0,0-.15-.37.47.47,0,0,0-.37-.16.49.49,0,0,0-.38.16.5.5,0,0,0-.15.37v2.24a.52.52,0,0,0,.15.38.5.5,0,0,0,.37.15.51.51,0,0,0,.37-.15.47.47,0,0,0,.16-.37ZM16,68.33h-.68l.13-.9h.86Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M20.3,73.57l-1.22-3.06h0v3.06h-1V68.66h1.25l1,2.51h0V68.66h1v4.91Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M22.27,73.57v-4.9h1v4.9Z"
            />
            <path
              className="a920dc74-825c-4820-9369-d2ec3720323d"
              d="M27.15,72.23a1.4,1.4,0,0,1-.12.58,1.75,1.75,0,0,1-.32.48,1.61,1.61,0,0,1-.48.32,1.4,1.4,0,0,1-.58.12,1.47,1.47,0,0,1-.58-.12,1.7,1.7,0,0,1-.47-.32,1.55,1.55,0,0,1-.32-.48,1.58,1.58,0,0,1-.11-.58V70a1.43,1.43,0,0,1,.12-.58,1.52,1.52,0,0,1,.79-.79,1.43,1.43,0,0,1,.58-.12,1.4,1.4,0,0,1,.58.12,1.27,1.27,0,0,1,.47.32,1.4,1.4,0,0,1,.32.47,1.43,1.43,0,0,1,.12.58Zm-1-2.24a.52.52,0,0,0-.16-.37.47.47,0,0,0-.37-.16.51.51,0,0,0-.38.16.5.5,0,0,0-.15.37v2.24a.52.52,0,0,0,.15.38.51.51,0,0,0,.37.15.54.54,0,0,0,.38-.15.51.51,0,0,0,.16-.37Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MSAlogoV;
