import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  ArrowLeftCircle,
  Calendar,
  HelpCircle,
  MapPin,
  GitBranch,
} from "react-feather";

import NavbarDrawer from "../Components/NavbarDrawer";
import MSAlogoV from "../assets/logo/msa_v.js";
import MSAlogoH from "../assets/logo/msa_h.js";
import Egeac from "../assets/logo/egeac.js";
import "./navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeBtn, setActiveBtn] = React.useState();

  const handleOpen = (e) => {
    switch (true) {
      case isOpen:
        setIsOpen(!isOpen);
        break;
      default:
        setActiveBtn(e.target.id);
        setIsOpen(!isOpen);
        break;
    }
  };

  return (
    <div className="navOuterWrapper">
      <div className="navbarWrapper">
        <div className="homeBtnWrapper">
          <div className="msaV_white">
            <Egeac />
            <MSAlogoV />
          </div>
          <div className="msaH_white">
            <Egeac />
            <MSAlogoH />
          </div>
        </div>
        <div className="btnsWrapper">
          {/* <Link className="iconWrapper" to="/" onClick={e => setActiveBtn(e.target.value)}>
            <div className="iconInnerWrapper">
              <div className="placeholderSVG">
                <Calendar />
              </div>
              <div className="animatedSVG">
                <Calendar />
              </div>
            </div>
          </Link> */}
          <Link
            className="iconWrapper"
            to="/timeline-culto"
            onClick={(e) => setActiveBtn(e.target.value)}
          >
            <div className="iconInnerWrapper">
              {/* <div className="placeholderSVG">
                <GitBranch />
              </div>
              <div className="animatedSVG">
                <GitBranch />
              </div> */}
            </div>
          </Link>
          <button className="mainBtnWrapper" onClick={handleOpen}>
            <span
              className={`mainBtn ${
                isOpen && activeBtn === "" ? "menuOpen" : ""
              }`}
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
          {/* <Link className="iconWrapper" to="/" onClick={e => setActiveBtn(e.target.value)}>
            <div className="iconInnerWrapper">
              <div className="placeholderSVG">
                <HelpCircle />
              </div>
              <div className="animatedSVG">
                <HelpCircle />
              </div>
            </div>
          </Link> */}
          <a
            id="contacts"
            className={`iconWrapper ${
              isOpen && activeBtn === "contacts" ? "menuOpen" : ""
            }`}
            onClick={handleOpen}
          >
            <div className="iconInnerWrapper">
              <div className="placeholderSVG">
                <MapPin />
              </div>
              <div className="animatedSVG">
                <MapPin />
              </div>
              <div className="closeSVG">
                <ArrowLeftCircle />
              </div>
            </div>
          </a>
        </div>
        <div className="navFooterWrapper"></div>
      </div>
      <NavbarDrawer isOpen={isOpen} activeBtn={activeBtn} />
    </div>
  );
};

export default withRouter(Navbar);
